import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import IToken = AES.IToken;
import gql from 'graphql-tag';
import {GraphService} from '../../network/graph.service';

@Injectable()
export class LoginService {

  constructor(private graphService: GraphService) {
  }

  public login(code: string): Promise<IToken> {
    return new Promise<IToken>((resolve, reject) => {
      this.graphService.getApollo().mutate<any>({
        mutation: login,
        context: {
          uri: this.graphService.getGraphqlUrl() + '?LoginService.login'
        },
        variables: {
          login: {
            code: code,
            redirect_url: environment.infotainmentLoginRedirectPath
          }
        }
      }).subscribe(
        response => {
          const token = response.data.login;
          this.graphService.getTokenManager().setToken(
            token.access_token,
            token.refresh_token,
            token.token_type,
            token.expires_in,
            token.expires_at
          );
          this.graphService.init();
          resolve(token);
        },
        error => {
          reject(error);
        });
    });
  }

}

const login = gql`
  mutation login($login: LoginInput!){
    login(input: $login){
      access_token
      refresh_token
      token_type
      expires_in
      expires_at
    }
  }
`;
