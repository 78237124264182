import {Component, Input, OnInit} from '@angular/core';
import {FuseConfigService} from '../../../../../@fuse/services/config.service';

@Component({
  selector: 'aesys-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

  @Input('state') state = 'big';
  @Input('visible') visible = true;

  constructor() {
  }

  ngOnInit() {
  }

}
