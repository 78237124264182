import {NgModule} from '@angular/core';
import {ContentsListComponent} from './contents-list/contents-list.component';
import {AuthGuard} from '../../../shared/guard/auth-guard';
import {RouterModule} from '@angular/router';
import {CustomerResolver} from '../../../shared/resolver/customer.resolver';
import {ContentsService} from './contents.service';
import {SharedModule} from '../../../shared/shared.module';
import {ContentDetailComponent} from './content-detail/content-detail.component';
import {FileDropModule} from 'ngx-file-drop';
import {RatioResolutionComponent} from './contents-list/ratio-resolution-dialog/ratio-resolution-dialog.component';
import {DialogAssetPreviewComponent} from './content-detail/dialog-asset-preview/dialog-asset-preview.component';
import { ContentsMultiUploadComponent } from './contents-multi-upload/contents-multi-upload.component';
import { ContentsListAddTagDialogComponent } from './contents-list/contents-list-add-tag-dialog/contents-list-add-tag-dialog.component';

const routes = [
  {
    path: 'customer/:custId/contents',
    canActivate: [AuthGuard],
    resolve: {
      customer: CustomerResolver
    },
    component: ContentsListComponent
  },
  {
    path: 'customer/:custId/contents/upload',
    canActivate: [AuthGuard],
    resolve: {
      customer: CustomerResolver
    },
    component: ContentsMultiUploadComponent
  },
  {
    path: 'customer/:custId/contents/:contentId',
    canActivate: [AuthGuard],
    resolve: {
      customer: CustomerResolver
    },
    component: ContentDetailComponent
  }
];

@NgModule({
  declarations: [
    ContentsListComponent,
    ContentDetailComponent,
    RatioResolutionComponent,
    DialogAssetPreviewComponent,
    ContentsMultiUploadComponent,
    ContentsListAddTagDialogComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    FileDropModule
  ],
  providers: [
    ContentsService
  ],
  entryComponents: [
    DialogAssetPreviewComponent,
    RatioResolutionComponent,
    ContentsListAddTagDialogComponent
  ],
  exports: [
    ContentsListComponent
  ]
})

export class ContentsModule {
}
