import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'translateConditional'
})
export class TranslateConditionalPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  transform(key: any, defaultValue: string): string {
    const value = this.translateService.instant(key);
    return value === key ? defaultValue : value;
  }
}
