export enum ContentTypeEnum {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  ARCHIVE = 'ARCHIVE',
  URL = 'URL',
  PICTOGRAM = 'PICTOGRAM'
}

export enum PlaylistTypeEnum {
  TFT = 'TFT',
  ROAD = 'ROAD'
}

export enum LayoutTypeEnum {
  TFT = 'TFT',
  ROAD = 'ROAD'
}

export enum ScheduleFrequencyEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  FIXED = 'FIXED'
}
