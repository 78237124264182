import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'aesys-pl-dialog-duration',
  templateUrl: './pl-dialog-duration.component.html',
  styleUrls: ['./pl-dialog-duration.component.scss']
})
export class PlDialogDurationComponent implements OnInit {

  duration = 0;
  hh: number;
  mm: number;
  ss: number;

  minSeconds = 10;
  maxHours = 20;

  constructor(
    public dialogRef: MatDialogRef<PlDialogDurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.hh = Math.floor(data.time / 3600);
    this.mm = Math.floor(data.time % 3600 / 60);
    this.ss = Math.floor(data.time % 3600 % 60);
  }

  ngOnInit() {
  }

  saveDuration() {
    this.duration = +this.hh * 3600;
    this.duration += +this.mm * 60;
    this.duration += +this.ss;
    this.data.duration = this.duration;
    this.dialogRef.close(this.data);
  }

  hourChange() {
    if (!Number.isInteger(+this.hh)) {
      this.hh = 0;
    }
    if (this.hh < 0) {
      this.hh = 0;
    }
    if (this.hh > 23) {
      this.hh = 23;
    }
  }

  minuteChange() {
    if (!Number.isInteger(+this.mm)) {
      this.mm = 0;
    }
    if (this.mm < 0) {
      this.mm = 0;
    }
    if (this.mm > 59) {
      this.mm = 59;
    }
  }

  secondChange() {
    if (!Number.isInteger(+this.ss)) {
      this.ss = 0;
    }
    if (this.ss < 0) {
      this.ss = 0;
    }
    if (this.ss > 59) {
      this.ss = 59;
    }
    if (this.hh == 0 && this.mm == 0 && this.ss < 3) {
      this.ss = 3;
    }
  }

}
