import {Component, OnInit, ViewChild} from '@angular/core';
import {TimePipe} from '../../../../../../shared/util/time.pipe';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'aesys-tl-graded',
  templateUrl: './tl-graded.component.html',
  styleUrls: ['./tl-graded.component.scss']
})
export class TlGradedComponent implements OnInit {

  HOUR = 60 * 60 * 1000;
  DAY = 24 * this.HOUR;

  @ViewChild('gradedView', {static: false}) gradedView;
  list: [string, number][] = [];
  width = 0;

  dateStart = 0;
  dateEnd = 0;

  constructor(
    private datePipe: DatePipe,
  ) {
  }

  ngOnInit() {
  }

  getEstimatedWidth(text: string): number {
    if (text.length <= 5) {
      return 27;
    } else {
      return 36;
    }
  }

  refresh(dateStart: number, dateEnd: number) {

    this.list = [];
    this.dateStart = dateStart;
    this.dateEnd = dateEnd;
    this.width = this.gradedView.nativeElement.clientWidth;

    const day = Math.round((this.dateEnd - this.dateStart) / this.DAY);

    let step = 0;
    let stepCount = 0;
    let stepValue = 0;
    let stepModule = 1;
    let format = '';

    if (day < 3) {
      stepCount = (day * 24);
      step = this.width / (day * 24);
      stepValue = this.HOUR;
      if (day > 1) {
        stepModule = 2;
        format = 'dd H:mm';
      } else {
        stepModule = 1;
        format = 'H:mm';
      }
    } else if (day <= 30) {
      stepCount = day + 1;
      step = this.width / day;
      stepValue = this.DAY;
      format = 'dd/MM';
      if (day > 10) {
        stepModule = 2;
      } else {
        stepModule = 1;
      }
    } else if (day <= 180) {
      stepCount = Math.floor(day / 7);
      step = (this.width / day) * 7;
      stepValue = this.DAY * 7;
      format = 'dd/MM';
      if (day > 90) {
        stepModule = 2;
      } else {
        stepModule = 1;
      }
    } else if (day <= 360) {
      stepCount = Math.floor(day / 28);
      step = (this.width / day) * 28;
      stepValue = this.DAY * 28;
      format = 'dd/MM';
    } else {
      stepCount = Math.floor(day / 56);
      step = (this.width / day) * 56;
      stepValue = this.DAY * 56;
      format = 'dd/MM';
    }

    this.list = [];
    for (let i = 0; i < stepCount; i++) { // dd/MM - hh:mm a
      this.list.push([i % stepModule === 0 ? this.datePipe.transform((dateStart + (stepValue * i)), format, '+0') : '', step]);
    }
  }
}
