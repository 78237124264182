import {Injectable} from '@angular/core';
import {GraphService} from '../../network/graph.service';
import ITimeline = AES.ITimeline;
import gql from 'graphql-tag';
import {FormError} from '../../../util/form/form-error.model';
import IChannel = AES.IChannel;
import IPlaylist = AES.IPlaylist;
import ICondition = AES.ICondition;
import IProgramming = AES.IProgramming;
import {ConditionValue} from '../../network/data/condition-value.model';
import SortDirection = AES.SortDirection;

@Injectable()
export class TimelinesService {

  constructor(public graph: GraphService) {
  }

  public getChannels(): Promise<IChannel[]> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getChannels,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.getChannels'
        },
        variables: {
          slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getChannels);
        },
        error => {
          reject();
        });
    });
  }

  public getChannel(channelSlug: string): Promise<IChannel> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getChannel,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.getChannel'
        },
        variables: {
          cus: this.graph.getCurrentCustomer(),
          chs: channelSlug
        }
      }).subscribe(
        response => {
          resolve(response.data.getChannel);
        },
        error => {
          reject();
        });
    });
  }

  public getTimelines(): Promise<ITimeline[]> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getTimelines,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.getTimelines'
        },
        variables: {
          cs: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getTimelines);
        });
    });
  }

  public getTimeline(id: string): Promise<ITimeline> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getTimeline,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.getTimeline'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ts: id
        }
      }).subscribe(
        response => {
          resolve(response.data.getTimeline);
        },
        error => {
          reject();
        });
    });
  }

  public getPlaylists(): Promise<IPlaylist[]> {
    return new Promise<IPlaylist[]>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getPlaylists,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.getPlaylists'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
        }
      }).subscribe(
        response => {
          resolve(response.data.getPlaylists);
        },
        error => {
          reject();
        });
    });
  }

  public createTimeline(name: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: createTimeline,
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.createTimeline'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          cti: {
            name: name
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.createTimeline.slug);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public updateTimeline(slug: string, name: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updateTimeline,
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.updateTimeline'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ts: slug,
          uti: {
            name: name
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updateTimeline.slug);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public deleteTimeline(tSlug: string): Promise<Boolean> {
    return new Promise<Boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deleteTimeline,
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.deleteTimeline'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ts: tSlug,
          force: false
        }
      }).subscribe(
        response => {
          resolve(response.data.deleteTimeline);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public updatePlanningPosition(planningSlug: string, targetSlug: string, direction: SortDirection): Promise<IChannel> {
    return new Promise<IChannel>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updatePlanningPosition,
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.updatePlanningPosition'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ps: planningSlug,
          in: {},
          sort: {
            target: targetSlug,
            direction: direction
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updatePlanning.channel);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public saveProgramming(ts: string, ps: string, prs: string, priority: number, schedules: any): Promise<IProgramming[]> {
    const data = {
      timeline_slug: ts,
      playlist_slug: ps,
      priority: priority,
      schedules: schedules
    };

    if (prs === null) {
      return new Promise<IProgramming[]>((resolve, reject) => {
        this.graph.getApollo().mutate<any>({
          mutation: createProgramming,
          context: {
            uri: this.graph.getGraphqlUrl() + '?TimelinesService.saveProgramming.createProgramming'
          },
          variables: {
            cs: this.graph.getCurrentCustomer(),
            cpi: data
          }
        }).subscribe(
          response => {
            resolve(response.data.createProgramming.timeline.programmings);
          },
          error => {
            const formError = new FormError(error, 'input');
            reject(formError);
          });
      });
    } else {
      return new Promise<IProgramming[]>((resolve, reject) => {
        this.graph.getApollo().mutate<any>({
          mutation: updateProgramming,
          context: {
            uri: this.graph.getGraphqlUrl() + '?TimelinesService.saveProgramming.updateProgramming'
          },
          variables: {
            cs: this.graph.getCurrentCustomer(),
            ps: prs,
            upi: data
          }
        }).subscribe(
          response => {
            resolve(response.data.updateProgramming.timeline.programmings);
          },
          error => {
            const formError = new FormError(error, 'input');
            reject(formError);
          });
      });
    }
  }

  public deleteProgramming(pSlug: string): Promise<Boolean> {
    return new Promise<Boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deleteProgramming,
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.deleteProgramming'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ps: pSlug
        }
      }).subscribe(
        response => {
          resolve(response.data.deleteProgramming);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public updatePlanningCondition(ps: string, conditionsValue: ConditionValue[][]) {
    const conditions = [];
    for (const set of conditionsValue) {
      const cSet = [];
      for (const condition of set) {
        const conditionSet = {};
        conditionSet['key'] = condition.key;
        conditionSet['value'] = condition.value;
        cSet.push(conditionSet);
      }
      conditions.push(cSet);
    }
    return new Promise<IChannel>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updatePlanningCondition,
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.updatePlanningCondition'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ps: ps,
          upi: {
            conditions: JSON.stringify(conditions)
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updatePlanning);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public getConditions(): Promise<ICondition[]> {
    return new Promise<ICondition[]>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getConditions,
        context: {
          uri: this.graph.getGraphqlUrl() + '?TimelinesService.getConditions'
        },
        variables: {
          cs: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          const conditions: ICondition[] = [];
          for (const c of response.data.getConditions) {
            if (!c.is_action) {
              conditions.push(c);
            }
          }
          resolve(conditions);
        });
    });
  }
}

// FRAGMENT

const scheduleFr = gql`
  fragment scheduleFr on Schedule {
    slug
    from_date_at
    to_date_at
    frequency
    monthly_day_of_month
    weekly_day_of_week
    yearly_day
    yearly_month
    fixed_from_time_at
    fixed_to_time_at
    timeslices{
      slug
      from_time_at
      to_time_at
    }
    conditions
  }
`;

const programmingFr = gql`
  fragment programmingFr on Programming {
    slug
    priority
    playlist{
      slug
      color
      name
    }
    schedules{
      ...scheduleFr
    }
  }
  ${scheduleFr}
`;

const channelFr = gql`
  fragment channelFr on Channel {
    slug
    plannings{
      slug
      order
      timeline{
        slug
        name
        programmings{
          slug
        }
      }
      conditions
    }
  }
`;

// QUERY

const getChannels = gql`
  query getChannels($slug: String!){
    getChannels(customer_slug: $slug){
      slug
    }
  }
`;

const getChannel = gql`
  query getChannel($cus: String!, $chs: String!) {
    getChannel(customer_slug: $cus, channel_slug: $chs){
      ...channelFr
    }
  }
  ${channelFr}
`;

const getTimelines = gql`
  query getTimelines($cs: String!){
    getTimelines(customer_slug: $cs){
      slug
      name
      programmings{
        slug
      }
      updated_at
    }
  }
`;

const getTimeline = gql`
  query getTimeline($cs: String!, $ts: String!){
    getTimeline(customer_slug: $cs, timeline_slug: $ts){
      slug
      name
      programmings{
        ...programmingFr
      }
    }
  }
  ${programmingFr}
`;

const createTimeline = gql`
  mutation ($cs: String!, $cti: CreateTimelineInput!){
    createTimeline(customer_slug: $cs, input: $cti){
      slug
    }
  }
`;

const updateTimeline = gql`
  mutation ($cs: String!,$ts: String!, $uti: UpdateTimelineInput!){
    updateTimeline(customer_slug: $cs, timeline_slug: $ts,input: $uti){
      slug
    }
  }
`;

const updatePlanningPosition = gql`
  mutation ($cs: String!, $ps: String!, $in: UpdatePlanningInput!, $sort: SortInput){
    updatePlanning(customer_slug: $cs, planning_slug: $ps, input: $in, sort: $sort){
      slug
      channel{
        ...channelFr
      }
    }
  }
  ${channelFr}
`;

const deleteTimeline = gql`
  mutation ($cs: String!, $ts: String!, $force: Boolean){
    deleteTimeline(customer_slug: $cs, timeline_slug: $ts, force: $force)
  }
`;

const getPlaylists = gql`
  query getPlaylists($cs: String!){
    getPlaylists(customer_slug: $cs, draft: false){
      slug
      name
    }
  }
`;

const getConditions = gql`
  query getConditions($cs: String!){
    getConditions(customer_slug: $cs){
      key
      values
      exclusive
      is_action
    }
  }
`;

const updatePlanningCondition = gql`
  mutation updatePlanning($cs: String!, $ps: String!, $upi: UpdatePlanningInput!){
    updatePlanning(customer_slug: $cs, planning_slug: $ps, input: $upi){
      slug
      order
      timeline{
        slug
        name
      }
      conditions
    }
  }
`;

const createProgramming = gql`
  mutation createProgramming($cs: String!, $cpi: CreateProgrammingInput!){
    createProgramming(customer_slug: $cs, input: $cpi){
      slug
      timeline{
        programmings{
          ...programmingFr
        }
      }
    }
  }
  ${programmingFr}
`;

const deleteProgramming = gql`
  mutation deleteProgramming($cs: String!, $ps: String!){
    deleteProgramming(customer_slug: $cs, programming_slug: $ps)
  }
`;

const updateProgramming = gql`
  mutation updateProgramming($cs: String!,$ps: String!, $upi: UpdateProgrammingInput!){
    updateProgramming(customer_slug: $cs, programming_slug: $ps,input: $upi){
      slug
      timeline{
        programmings{
          ...programmingFr
        }
      }
    }
  }
  ${programmingFr}
`;
