import {Component, ComponentFactoryResolver, Inject, OnInit, QueryList, ViewChildren, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PlaylistsService} from '../../../playlists.service';
import ILayout = AES.ILayout;
import * as _ from 'lodash';
import {FuseTranslationLoaderService} from '../../../../../../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {locale as english} from '../../i18n/en';
import {LayoutPreviewTftComponent} from '../../../../layout-manager/layout-preview-tft/layout-preview-tft.component';
import {LayoutPreviewRoadComponent} from '../../../../layout-manager/layout-preview-road/layout-preview-road.component';
import PlaylistType = AES.PlaylistType;
import LayoutType = AES.LayoutType;
import {PlaylistTypeEnum} from '../../../../../network/interface/graph-enum.model';

@Component({
  selector: 'aesys-pl-layout-dialog',
  templateUrl: './pl-layout-dialog.component.html',
  styleUrls: ['./pl-layout-dialog.component.scss']
})
export class PlLayoutDialogComponent implements OnInit {

  @ViewChildren('layoutInsert', {read: ViewContainerRef}) layoutInsert: QueryList<ViewContainerRef>;
  @ViewChildren('roadLayoutInsert', {read: ViewContainerRef}) roadLayoutInsert: QueryList<ViewContainerRef>;

  type = PlaylistTypeEnum;
  lSurface: number[] = [];
  private layouts: ILayout[] = [];
  private roadLayout: ILayout[] = [];
  public playlistType: PlaylistType;

  constructor(
    private plService: PlaylistsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private translateService: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<PlLayoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.fuseTranslationLoader.loadTranslations(english);
    this.playlistType = data.playlistType;
  }

  ngOnInit() {
    this.plService.getLayouts()
      .then(layouts => {
        this.layouts = layouts;
        for (const l of this.layouts) {
          if (l.type === LayoutType.TFT) {
            if (this.lSurface.indexOf(l.surfaces.length) === -1) {
              this.lSurface.push(l.surfaces.length);
            }
          } else {
            this.roadLayout.push(l);
          }
        }
        // trovare una soluzione migliore
        setTimeout(() => {
          this.generateLayout();
        }, 50);
      })
      .catch(error => {
        console.error(error);
        this.dialogRef.close();
      });
  }

  generateLayout() {
    switch (this.playlistType) {
      case PlaylistType.ROAD:
        for (const l of this.roadLayout) {
          const plLayout = this.componentFactoryResolver.resolveComponentFactory(LayoutPreviewRoadComponent);
          const cRef = this.roadLayoutInsert.toArray()[0].createComponent(plLayout);
          const dComponent = <LayoutPreviewRoadComponent>cRef.instance;
          dComponent.layout = l;
          dComponent.showName = true;
          dComponent.selected.subscribe(layout => {
            this.select(layout);
          });
        }
        break;
      case PlaylistType.TFT:
        for (let i = 0; i < this.lSurface.length; i++) {
          const ls = _(this.layouts).filter(l => l.surfaces.length === this.lSurface[i]).value();
          for (const l of ls) {
            if (l.type === LayoutType.TFT) {
              const plLayout = this.componentFactoryResolver.resolveComponentFactory(LayoutPreviewTftComponent);
              const cRef = this.layoutInsert.toArray()[i].createComponent(plLayout);
              const dComponent = <LayoutPreviewTftComponent>cRef.instance;
              dComponent.layout = l;
              dComponent.selected.subscribe(layout => {
                this.select(layout);
              });
            }
          }
        }
        break;
    }
  }

  select(layout: ILayout) {
    this.data.lSlug = layout.slug;
    this.dialogRef.close(this.data);
  }

}
