import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {GraphService} from '../../../network/graph.service';
import {DashboardService} from '../dashboard.service';
import ICustomer = AES.ICustomer;
import {MatDialog, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import IChannel = AES.IChannel;
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import * as _ from 'lodash';
import {interval} from 'rxjs';
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import {DatePipe} from '@angular/common';
import {LayoutManagerListComponent} from '../../layout-manager/layout-manager-list/layout-manager-list.component';
import RoleName = AES.RoleName;

@Component({
    selector: 'aesys-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

    INTERVAL = 5000;
    firstTime = true;
    customer: ICustomer = null;
    channels: IChannel[] = null;
    loader: any = null;
    showLayoutManager = false;
    // CHART
    spaceChartColors = [
        {
            name: 'DASHBOARD.USED_QUOTA',
            value: '#1a2679'
        },
        {
            name: 'DASHBOARD.FREE_QUOTA',
            value: '#a6b1d6'
        }
    ];
    spaceChartData = [];
    private navigationSubscription;
    private listObserver = null;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private graphService: GraphService,
                private translateService: TranslateService,
                private dashboardService: DashboardService,
                private datePipe: DatePipe,
                public dialog: MatDialog,
                public snackBar: MatSnackBar) {
    }

    ngOnInit(): void {
        this.initUI();
        this.navigationSubscription = this.router.events.subscribe((e: any) => {
            // If it is a NavigationEnd event re-initalise the component
            if (e instanceof NavigationEnd) {

                this.stopObserver();
                this.firstTime = true;
                this.customer = null;
                this.channels = null;
                this.showLayoutManager = false;
                this.initUI();
            }
        });
    }

    initUI() {
        this.dashboardService.getRoles()
            .then(me => {
                for (const role of me.roles) {
                    if (role.name === RoleName.ADMIN || role.name === RoleName.CUSTOMER_ADMIN) {
                        this.showLayoutManager = true;
                    }
                }
            });
        this.initCustomer();
        this.initChannels();
    }

    ngOnDestroy(): void {
        this.stopObserver();
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    }

    public initCustomer() {
        this.dashboardService.getCustomerDetail()
            .then(customer => {
                this.customer = customer;
                this.spaceChartData = [
                    {
                        name: 'DASHBOARD.USED_QUOTA',
                        value: this.customer.used_quota
                    },
                    {
                        name: 'DASHBOARD.FREE_QUOTA',
                        value: this.customer.free_quota
                    }
                ];
            })
            .catch(err => {
                this.customer = null;
            });
    }

    public initChannels() {
        setTimeout(() => {
            if (this.firstTime) {
                this.firstTime = false;
                this.loader = LoadingComponent.showLoading(this.dialog);
            }

            this.dashboardService.getChannels()
                .then(channels => {
                    LoadingComponent.hideLoading(this.loader);
                    this.channels = _.clone(channels);
                    this.startObserver();
                    this.dashboardService.channelUpdated.emit();
                })
                .catch(error => {
                    LoadingComponent.hideLoading(this.loader);
                    this.loader = null;
                    this.snackBar.open(this.translateService.instant('DASHBOARD.LIST_ERROR'), '', {
                        duration: 1000
                    });
                });
        }, 1);
    }

    public publish(channel: IChannel) {
        const dialogRef = this.dialog.open(GenericDialogComponent, {
            width: '250px',
            data: {
                message: this.translateService.instant('DASHBOARD.DIALOG_PUBLISH_MESSAGE'),
                positiveButton: this.translateService.instant('DASHBOARD.DIALOG_PUBLISH_CONFIRM'),
                negativeButton: this.translateService.instant('DASHBOARD.DIALOG_UNDO')
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loader = LoadingComponent.showLoading(this.dialog);
                this.dashboardService.publishChannel(channel)
                    .then(updatedChannel => {
                        LoadingComponent.hideLoading(this.loader);
                        const index = this.channels.indexOf(channel);
                        this.channels.splice(index, 1, updatedChannel);
                        this.snackBar.open(this.translateService.instant('DASHBOARD.PUBLISH_SUCCESS'), '', {
                            duration: 1000
                        });
                        this.startObserver();
                    })
                    .catch(error => {
                        LoadingComponent.hideLoading(this.loader);
                        this.snackBar.open(this.translateService.instant('DASHBOARD.PUBLISH_ERROR'), '', {
                            duration: 1000
                        });
                    });
            }
        });
    }

    public publishConfigurations() {
        this.loader = LoadingComponent.showLoading(this.dialog);
        this.dashboardService.publishConfigurations()
            .then(res => {
                LoadingComponent.hideLoading(this.loader);
                if (res) {
                    this.snackBar.open(this.translateService.instant('DASHBOARD.PUBLISH_SUCCESS'), '', {
                        duration: 1000
                    });
                    this.initCustomer();
                } else {
                    this.snackBar.open(this.translateService.instant('DASHBOARD.PUBLISH_ERROR'), '', {
                        duration: 1000
                    });
                }
            })
            .catch(err => {
                LoadingComponent.hideLoading(this.loader);
                this.snackBar.open(this.translateService.instant('DASHBOARD.PUBLISH_ERROR'), '', {
                    duration: 1000
                });
            });
    }

    public allReady(channel: IChannel): boolean {
        if (channel == null) {
            return true;
        } else {
            return channel.latest_pubblication !== null ? channel.latest_pubblication.is_ready : true;
        }
    }

    public copyLink() {
        this.snackBar.open(this.translateService.instant('DASHBOARD.LINK_COPIED'), '', {
            duration: 1000
        });
    }

    public getLastPublication(channel: IChannel): string {
        if (channel.latest_pubblication !== null) {
            if (channel.latest_pubblication.published_at !== null) {
                return this.datePipe.transform(channel.latest_pubblication.published_at * 1000, 'dd/MM/yyyy HH:mm');
            } else {
                return this.translateService.instant('DASHBOARD.CHANNEL_PUBLISHING');
            }
        } else {
            return '';
        }
    }

    public screenTemplateEditor() {
        this.dialog.open(LayoutManagerListComponent, {
            width: '800px',
            maxHeight: '600px'
        });
    }

    public formatContentsDate(timestamp: number): string {
        if (timestamp != null) {
            return this.datePipe.transform(timestamp * 1000, 'dd/MM/yyyy HH:mm');
        } else {
            return '-';
        }
    }

    private stopObserver() {
        if (this.listObserver !== null) {
            this.listObserver.unsubscribe();
            this.listObserver = null;
        }
    }

    private startObserver() {
        let update = false;
        for (const c of this.channels) {
            if (c.latest_pubblication !== null && !c.latest_pubblication.is_ready) {
                update = true;
            }
        }
        if (update) {

            this.listObserver = interval(this.INTERVAL).subscribe((x) => {
                this.initChannels();
            });
        } else {
            this.stopObserver();
        }
    }

}
