import {ApplicationRef, ChangeDetectorRef, Component, HostListener, Input, OnInit} from '@angular/core';
import {UploadService} from '../upload.service';
import IContent = AES.IContent;
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'aesys-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  visible = false;
  panelOpenState = false;
  length = 0;
  uploading: [File, IContent, Subscription][] = [];
  errorQueue: [File, IContent, Subscription][] = [];
  queue: [File, IContent][] = [];

  constructor(
    private uploadService: UploadService,
    private appRef: ApplicationRef,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.uploadService.queueChanged
      .subscribe(() => {
        this.length = this.uploadService.uploading.length + this.uploadService.queue.length;
        this.visible = this.uploadService.uploading.length > 0 || this.uploadService.errorQueue.length > 0;
        this.uploading = this.uploadService.uploading;
        this.errorQueue = this.uploadService.errorQueue;
        this.queue = this.uploadService.queue;
        this.appRef.tick();
      });
  }

  stopUpload(item: [File, IContent, Subscription]) {
    this.uploadService.removeUpload(item);
  }

  deleteFromQueue(item: [File, IContent]) {
    this.uploadService.removeFromQueue(item);
  }

  deleteFromErrorQueue(item: [File, IContent, Subscription]) {
    this.uploadService.removeFromErrorQueue(item);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.visible) {
      $event.returnValue = true;
    }
  }

}
