export const environment = {
  production: false,
  hmr: false,
  aesysBasePath: 'https://identityserver-test.aesys.com',
  infotainmentEngineBasePath: 'https://aesys-infotainment-test-02.appspot.com',
  infotainmentFrontPath: 'https://creo-test.aesys.com',
  infotainmentITPlayerPath: 'http://player.creo-test.aesys.com',
  infotainmentLoginRedirectPath: 'https://creo-test.aesys.com/auth/callback',
  graphql: 'https://aesys-infotainment-test-02.appspot.com/graphql'
};
