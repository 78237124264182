import {AfterViewInit, Component, Inject, OnInit, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {GenericDialogComponent} from '../../../../../shared/generic-dialog/generic-dialog.component';
import * as _ from 'lodash';
import {TimelinesService} from '../../timelines.service';
import {LoadingComponent} from '../../../../../shared/loading/loading.component';
import ICondition = AES.ICondition;
import {FormControl} from '@angular/forms';
import {ConditionValue} from '../../../../network/data/condition-value.model';

@Component({
  selector: 'aesys-tl-dialog-planning',
  templateUrl: './tl-dialog-planning.component.html',
  styleUrls: ['./tl-dialog-planning.component.scss']
})
export class TlDialogPlanningComponent implements AfterViewInit {

  // view
  @ViewChildren('conditionForms') conditionForms;
  loader: any = null;

  name = '';
  conditionSets: [number, ConditionValue[]][] = [];
  conditions: ICondition[] = null;

  private planningSlug: string = null;

  constructor(
    private tlService: TimelinesService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    }, 1);
  }

  public init() {
    this.name = this.data.planning.timeline.name;
    this.planningSlug = this.data.planning.slug;
    this.loader = LoadingComponent.showLoading(this.dialog);
    this.tlService.getConditions()
      .then(conditions => {
        LoadingComponent.hideLoading(this.loader);
        const date = new Date();
        this.conditions = conditions;
        const cs = JSON.parse(this.data.planning.conditions);
        if (cs) {
          for (let i = 0; i < cs.length; i++) {
            this.conditionSets.push([(date.getTime() + i), _.clone(cs[i])]);
          }
        }
      })
      .catch(error => {
        console.log(error);
        LoadingComponent.hideLoading(this.loader);
        this.dialogRef.close();
      });
  }

  public addConditionsSet() {
    const date = new Date();
    this.conditionSets.push([date.getTime(), null]);
  }

  public delete(ts: number) {
    for (let i = 0; i < this.conditionSets.length; i++) {
      if (this.conditionSets[i][0] === ts) {
        this.conditionSets.splice(i, 1);
      }
    }
  }

  /**
   * Elimina tutte le conditionset impostate
   */
  public deleteAll() {
    this.conditionSets = [];
  }

  public save() {
    let valid = true;
    const conditionsets: ConditionValue[][] = [];
    for (const cs of this.conditionForms.toArray()) {
      const conditions = cs.validateAndGetData();
      if (conditions === null) {
        valid = false;
      } else {
        conditionsets.push(conditions);
      }
    }
    if (valid) {
      this.loader = LoadingComponent.showLoading(this.dialog);
      this.tlService.updatePlanningCondition(this.planningSlug, conditionsets)
        .then(planning => {
          LoadingComponent.hideLoading(this.loader);
          this.data.newPlanning = planning;
          this.snackBar.open('Planning aggiornato', '', {
            duration: 1000,
          });
          this.dialogRef.close(this.data);
        })
        .catch(error => {
          this.dialogRef.close();
          this.snackBar.open('Errore durante l\'aggiornamento', '', {
            duration: 1000,
          });
          LoadingComponent.hideLoading(this.loader);
        });
    }
  }

}

export const noWhitespaceValidator = (control: FormControl): { [key: string]: boolean } => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : {'whitespace': true};
};
