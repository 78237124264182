import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FuseConfigService} from '../../../../../@fuse/services/config.service';
import {environment} from '../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {isUndefined} from 'util';
import {GraphService} from '../../../network/graph.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {TranslateService} from '@ngx-translate/core';
import {LoginService} from '../login.service';

@Component({
  selector: 'aesys-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private fuseConfig: FuseConfigService,
    private route: ActivatedRoute,
    private graph: GraphService,
    private loginService: LoginService,
    private router: Router,
    public dialog: MatDialog,
    public errorBar: MatSnackBar,
    private translateService: TranslateService
  ) {
    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });
  }

  login() {
    window.location.href = this.getRedirectUrlLogin();
  }

  private getRedirectUrlLogin(): string {
    const url = environment.aesysBasePath + '/connect/authorize?';
    let param = 'client_id=cms&';
    param += 'redirect_uri=' + environment.infotainmentLoginRedirectPath + '&';
    param += 'scope=openid cms offline_access profile ids&';
    param += 'response_type=code id_token&';
    param += 'nonce=' + new Date().getTime();
    param += 'response_mode=fragment';
    return url + param;
  }

  private doLogin(code: string) {
    setTimeout(() => {
      this.dialog = LoadingComponent.showLoading(this.dialog);
      this.loginService.login(code).then(
        token => {
          LoadingComponent.hideLoading(this.dialog);
          const deepLink = this.graph.getDeeplinkUrl();
          if (deepLink) {
            this.graph.removeDeeplinkUrl();
            this.router.navigateByUrl(deepLink);
          } else {
            this.router.navigate(['customer']);
          }

        },
        error => {
          LoadingComponent.hideLoading(this.dialog);
          this.errorBar.open('Errore durante login', '', {
            duration: 1000
          });
          // console.log(error);
        }
      );
    }, 1);
  }

  ngOnInit(): void {
    if (!isUndefined(this.route.snapshot.params.code)) {
      // this.router.navigateByUrl('/login', {replaceUrl: true});
      window.history.replaceState({}, '', '/login');
      this.doLogin(this.route.snapshot.params.code);
    } else {
      this.login();
    }
  }
}
