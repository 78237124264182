import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import IPlaylist = AES.IPlaylist;
import {Router} from '@angular/router';
import {GraphService} from '../../../network/graph.service';
import {PlaylistsService} from '../playlists.service';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'aesys-playlist-list',
  templateUrl: './playlist-list.component.html',
  styleUrls: ['./playlist-list.component.scss']
})
export class PlaylistListComponent implements OnInit {

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  loader: any = null;
  dataSource: MatTableDataSource<IPlaylist> = null;
  displayedColumns = ['icon', 'name', 'updated_at', 'used', 'del'];

  constructor(private router: Router,
              private graphService: GraphService,
              private playlistsService: PlaylistsService,
              private translateService: TranslateService,
              public dialog: MatDialog,
              public errorBar: MatSnackBar) {
  }

  ngOnInit() {
    this.init();
  }

  init() {
    setTimeout(() => {
      if (this.loader == null) {
        this.loader = LoadingComponent.showLoading(this.dialog);
      }
      this.playlistsService.getPlaylists()
        .then(playlists => {
          LoadingComponent.hideLoading(this.loader);
          this.loader = null;
          this.dataSource = new MatTableDataSource(playlists);
          this.dataSource.sort = this.sort;
        })
        .catch(error => {
          LoadingComponent.hideLoading(this.loader);
          this.loader = null;
          this.errorBar.open(this.translateService.instant('PLAYLIST_L.ERR_GET_PLAYLISTS'), '', {
            duration: 1000
          });
        });
    }, 1);

  }

  searchPlaylist(event: any) {
    let filterValue = event.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  openPlaylist(playlist: IPlaylist) {
    this.router.navigate(['/customer/' + this.graphService.getCurrentCustomer() + '/playlists/' + playlist.slug]);
  }

  newPlaylist() {
    this.router.navigate(['/customer/' + this.graphService.getCurrentCustomer() + '/playlists/NEW']);
  }

  deletePlaylist(playlist: IPlaylist) {
    let message = '';
    if (this.getState(playlist) === 'USED') {
      message = this.translateService.instant('PLAYLIST_L.DELETE_USED_PLAYLIST');
    } else {
      message = this.translateService.instant('PLAYLIST_L.DELETE_UNUSED_PLAYLIST');
    }
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        message: message,
        positiveButton: this.translateService.instant('PLAYLIST_L.DIALOG_CONFIRM'),
        negativeButton: this.translateService.instant('PLAYLIST_L.DIALOG_UNDO')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.loader == null) {
          this.loader = LoadingComponent.showLoading(this.dialog);
        }
        this.playlistsService.deletePlaylist(playlist, false)
          .then(success => {
            this.init();
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.loader = null;
            this.errorBar.open(this.translateService.instant('PLAYLIST_L.ERR_DELETE_PLAYLIST'), '', {
              duration: 1000
            });
          });
      }
    });
  }

  getState(playlist: IPlaylist): string {
    if (playlist.programmings.length) {
      return 'USED';
    } else if (playlist.is_draft) {
      return 'DRAFT';
    } else {
      return 'UNUSED';
    }
  }

}
