import {Component, EventEmitter, Input, OnInit, Output, ViewChildren} from '@angular/core';
import ISchedule = AES.ISchedule;
import {TimelinesService} from '../../../timelines.service';
import IProgramming = AES.IProgramming;
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoadingComponent} from '../../../../../../shared/loading/loading.component';
import {GenericDialogComponent} from '../../../../../../shared/generic-dialog/generic-dialog.component';

@Component({
  selector: 'aesys-tl-dialog-programmings-tab',
  templateUrl: './tl-dialog-programmings-tab.component.html',
  styleUrls: ['./tl-dialog-programmings-tab.component.scss']
})
export class TlDialogProgrammingsTabComponent implements OnInit {

  @Output() progammingUpdated = new EventEmitter<IProgramming[]>();

  @Input() timelineSlug: string;
  @Input() playlistSlug: string;
  @Input() playlistName: string;
  @Input() programming: IProgramming;
  @Input() priority: number;

  @ViewChildren('scheduleForms') scheduleForms;
  loader: any = null;
  schedules: [number, ISchedule][] = [];
  window = window;

  constructor(
    private tlService: TimelinesService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    const date = new Date();
    if (this.programming !== null && this.programming.schedules !== null) {
      // this.schedules = _.clone(this.programming.schedules);
      for (let i = 0; i < this.programming.schedules.length; i++) {
        this.schedules.push([(date.getTime() + i), _.clone(this.programming.schedules[i])]);
      }
    }
  }

  addSchedule() {
    const date = new Date();
    this.schedules.push([date.getTime(), null]);
  }

  deleteSchedule(timestamp: number) {
    for (let i = 0; i < this.schedules.length; i++) {
      if (this.schedules[i][0] === timestamp) {
        this.schedules.splice(i, 1);
      }
    }
  }

  isPristine() {
    for (const tdsc of this.scheduleForms.toArray()) {
      if (!tdsc.isPristine()) {
        return false;
      }
    }
    return !(this.programming !== null && this.scheduleForms.toArray().length === 0);
  }

  deleteAll() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        message: this.translateService.instant('TIMELINE_DIALOG.DELETE_ALL_MESSAGE'),
        positiveButton: this.translateService.instant('TIMELINE_DIALOG.DELETE'),
        negativeButton: this.translateService.instant('TIMELINE_DIALOG.UNDO')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.programming != null && this.programming.slug != null) {
          this.loader = LoadingComponent.showLoading(this.dialog);
          this.schedules = [];
          this.tlService.deleteProgramming(this.programming.slug)
            .then(result => {
              this.tlService.getTimeline(this.timelineSlug)
                .then(timeline => {
                  LoadingComponent.hideLoading(this.loader);
                  this.progammingUpdated.emit(timeline.programmings);
                  this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.PROGRAMMING_UPDATE_SUCCESS'), '', {
                    duration: 1000,
                  });
                })
                .catch(error => {
                  LoadingComponent.hideLoading(this.loader);
                  this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.PROGRAMMING_UPDATE_ERROR'), '', {
                    duration: 1000,
                  });
                });
            })
            .catch(error => {
              LoadingComponent.hideLoading(this.loader);
              this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.PROGRAMMING_UPDATE_ERROR'), '', {
                duration: 1000,
              });
            });
        }
      }
    });
  }

  save() {
    const programmingSlug = this.programming !== null ? this.programming.slug : null;
    const schedules = [];
    for (const tdsc of this.scheduleForms.toArray()) {
      const newSchedule = tdsc.getUpdatedSchedule();
      if (newSchedule == null) {
        return;
      } else {
        schedules.push(newSchedule);
      }
    }

    if (schedules.length == 0 && this.programming != null) {
      this.loader = LoadingComponent.showLoading(this.dialog);
      this.tlService.deleteProgramming(this.programming.slug)
        .then(result => {
          this.tlService.getTimeline(this.timelineSlug)
            .then(timeline => {
              LoadingComponent.hideLoading(this.loader);
              this.progammingUpdated.emit(timeline.programmings);
              this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.PROGRAMMING_UPDATE_SUCCESS'), '', {
                duration: 1000,
              });
            })
            .catch(error => {
              LoadingComponent.hideLoading(this.loader);
              this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.PROGRAMMING_UPDATE_ERROR'), '', {
                duration: 1000,
              });
            });
        })
        .catch(error => {
          LoadingComponent.hideLoading(this.loader);
          this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.PROGRAMMING_UPDATE_ERROR'), '', {
            duration: 1000,
          });
        });
      return;
    }

    // console.log('valid: ' + schedules.length + ' - total: ' + this.scheduleForms.toArray().length);
    if (schedules.length === this.scheduleForms.length) {
      this.loader = LoadingComponent.showLoading(this.dialog);
      this.tlService.saveProgramming(this.timelineSlug, this.playlistSlug, programmingSlug, this.priority, schedules)
        .then(programmings => {
          LoadingComponent.hideLoading(this.loader);
          this.progammingUpdated.emit(programmings);
          this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.PROGRAMMING_UPDATE_SUCCESS'), '', {
            duration: 1000,
          });
        })
        .catch(error => {
          LoadingComponent.hideLoading(this.loader);
          this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.PROGRAMMING_UPDATE_ERROR'), '', {
            duration: 1000,
          });
        });
    } else {
      this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.PROGRAMMING_UPDATE_ERROR'), '', {
        duration: 1000,
      });
    }
  }

}
