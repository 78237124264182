import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import {GraphService} from '../../../network/graph.service';
import {TimelinesService} from '../timelines.service';
import {MatDialog, MatSidenav, MatSnackBar} from '@angular/material';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Validators} from '@angular/forms';
import {AesysFormControl} from '../../../../util/form/AesysFormControl';
import ITimeline = AES.ITimeline;
import IPlaylist = AES.IPlaylist;
import {TlDialogProgrammingsComponent} from './tl-dialog-programmings/tl-dialog-programmings.component';
import * as _ from 'lodash';
import * as interact from 'interactjs';
import IProgramming = AES.IProgramming;

@Component({
  selector: 'aesys-timelines-detail',
  templateUrl: './timelines-detail.component.html',
  styleUrls: ['./timelines-detail.component.scss']
})

export class TimelinesDetailComponent implements OnInit, OnDestroy {

  // view
  @ViewChildren('simulator') simulator;
  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  @ViewChildren('contentTemporary', {read: ViewContainerRef}) contentTemporary: QueryList<ViewContainerRef>;
  loader: any = null;
  SHADOW_CONTENT_WIDTH = 100;
  SHADOW_CONTENT_HEIGHT = 40;
  window = window;

  // form
  name = '';
  color = '#ffffff';
  nameFormControll = new AesysFormControl('name', this.translateService, '', [
    Validators.required
  ]);
  priorities: number[] = [];

  // search
  filterText = '';
  filterPriority = -1;

  // data
  timeline: ITimeline = null;
  playlists: IPlaylist[] = [];

  plsProgrammed: TlProgramming[] = [];
  plsList: IPlaylist[] = [];

  saveDisabled = false;

  constructor(
    private router: Router,
    private graphService: GraphService,
    private route: ActivatedRoute,
    private tmService: TimelinesService,
    private translateService: TranslateService,
    private _location: Location,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) {
  }

  ngOnInit() {
    const id = this.route.snapshot.params.timelineId;

    setTimeout(() => {
      if (id === 'NEW') {
        this.initNewTimeline();
      } else {
        this.getTimeline(id);
      }
    }, 1);

    this.initDnD();
  }

  getTimeline(id: any) {
    this.loader = LoadingComponent.showLoading(this.dialog);
    this.tmService.getTimeline(id)
      .then(timeline => {
        LoadingComponent.hideLoading(this.loader);
        this.timeline = _.clone(timeline);
        this.initTimeline();
        this.getPlaylists();
      })
      .catch(error => {
        LoadingComponent.hideLoading(this.loader);
        this.snackBar.open(this.translateService.instant('TIMELINE_D.ERR_GET_TIMELINE'), '', {
          duration: 1000
        });
        this._location.back();
      });
  }

  getPlaylists() {
    this.tmService.getPlaylists()
      .then(playlists => {
        this.playlists = playlists;
        this.filterPlaylist(this.filterText, this.filterPriority);
      })
      .catch(error => {
        LoadingComponent.hideLoading(this.loader);
        this.snackBar.open(this.translateService.instant('TIMELINE_D.ERR_GET_TIMELINE'), '', {
          duration: 1000
        });
        this._location.back();
      });
  }

  addPlaylist(pl: IPlaylist, priority: number) {
    const dialogRef = this.dialog.open(TlDialogProgrammingsComponent, {
      width: '1000px',
      maxHeight: (0.9 * window.innerHeight) + 'px',
      data: {
        priority: priority,
        timelineSlug: this.timeline.slug,
        playlistSlug: pl.slug,
        playlistName: pl.name,
        programmings: this.timeline.programmings
      }
    });
    dialogRef.componentInstance.progammingUpdated
      .subscribe(programmings => {
        this.timeline.programmings = programmings;
        this.initTimeline();
        this.filterPlaylist(this.filterText, this.filterPriority);
        dialogRef.close();
        for (const pr of this.timeline.programmings) {
          if (pr.playlist.slug === pl.slug) {
            // this.addPlaylist(pr.playlist, priority);
            break;
          }
        }
      });
  }

  filterPlaylist(filter: string, filterPriority: number) {
    this.plsList = [];
    this.plsProgrammed = [];
    for (const pl of this.playlists) {
      if (pl.name.indexOf(filter) < 0) {
        continue;
      }
      let priorities = [];
      let programming = null;
      for (const p of this.timeline.programmings) {
        if (p.playlist.slug === pl.slug) {
          programming = p;
          if (priorities.indexOf(p.priority) === -1) {
            priorities.push(p.priority);
          }
        }
      }
      if (programming) {
        priorities = _.sortBy(priorities);
        if (filterPriority === -1 || priorities.indexOf(filterPriority) > -1) {
          this.plsProgrammed.push({
            programming: programming,
            priorities: priorities
          });
        }
      } else {
        if (filterPriority === -1) {
          this.plsList.push(pl);
        }
      }
    }
  }

  initNewTimeline() {
  }

  // FORM
  saveTimeline() {
    if (this.name !== '') {
      this.loader = LoadingComponent.showLoading(this.dialog);
      if (this.timeline === null) {
        this.tmService.createTimeline(this.name)
          .then(id => {
            LoadingComponent.hideLoading(this.loader);
            this.router.navigateByUrl('/customer/' + this.tmService.graph.getCurrentCustomer() + '/timelines/' + id, {replaceUrl: true});
            this.getTimeline(id);
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.nameFormControll.setCustomError(error);
          });
      } else {
        this.tmService.updateTimeline(this.timeline.slug, this.name)
          .then(id => {
            LoadingComponent.hideLoading(this.loader);
            this.router.navigateByUrl('/customer/' + this.tmService.graph.getCurrentCustomer() + '/timelines/' + id, {replaceUrl: true});
            this.getTimeline(id);
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.nameFormControll.setCustomError(error);
          });
      }
    }
  }

  private initTimeline() {
    this.name = this.timeline.name;
    this.nameFormControll.setValue(this.name);
    this.simulator.toArray()[0].refresh(this.timeline);
    this.priorities = [];
    for (const p of this.timeline.programmings) {
      if (this.priorities.indexOf(p.priority) === -1) {
        this.priorities.push(p.priority);
      }
    }
    this.priorities = _.sortBy(this.priorities);
    this.needToSave();
  }

  private initDnD() {
    const that = this;

    interact('.priority-dropzone')
      .dropzone({
        accept: '.playlist-draggable',
        overlap: 0.001,
        ondropmove: function (event) {
          event.target.classList.add('tl-pl-over');
        },
        ondrop: function (event) {
          that.addPlaylist(that.getPlaylistFromSlug(event.relatedTarget.id), event.target.id);
          event.target.classList.remove('tl-pl-over');
        },
        ondragleave: function (event) {
          event.target.classList.remove('tl-pl-over');

        }
      });

    interact('.playlist-draggable')
      .draggable({
        inertia: true,
        restrict: {
          endOnly: true,
          elementRect: {top: 0, left: 0, bottom: 1, right: 1}
        },
        autoScroll: true,
        onstart: event => {
          // that.contentTemporary.first.insert(content.cRef.hostView);
          if (that.sidenav.mode === 'over') {
            that.sidenav.close();
          }
          const target = event.target;
          const rect = target.getBoundingClientRect();
          target.classList.add('tl-pl-move');
          target.classList.remove('pl-list');
          target.style.width = this.SHADOW_CONTENT_WIDTH + 'px';
          target.style.height = this.SHADOW_CONTENT_HEIGHT + 'px';
          target.style.leftPos = rect.x + 'px';
          target.style.topPos = rect.y + 'px';
          target.style.zIndex = '101';
          target.style.position = 'fixed';
          target.setAttribute('data-x', event.interaction.startOffset.left - (this.SHADOW_CONTENT_WIDTH / 2));
          target.setAttribute('data-y', -event.target.parentNode.parentNode.scrollTop + event.interaction.startOffset.top - (this.SHADOW_CONTENT_HEIGHT / 2));
        },
        onmove: event => {
          const target = event.target;
          const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
          target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        },
        onend: event => {
          const target = event.target;
          target.classList.remove('tl-pl-move');
          target.classList.add('pl-list');
          target.style.width = 'auto';
          target.style.height = 'auto';
          target.style.position = 'static';
          target.style.zIndex = '100';
          target.style.webkitTransform = target.style.transform = 'translate(' + 0 + 'px, ' + 0 + 'px)';
          target.setAttribute('data-x', 0);
          target.setAttribute('data-y', 0);
        }
      });
  }

  private getPlaylistFromSlug(slug: string): IPlaylist {
    for (const p of this.playlists) {
      if (p.slug === slug) {
        return p;
      }
    }
  }

  ngOnDestroy(): void {
    (interact('.priority-dropzone') as any).unset();
    (interact('.step-draggable') as any).unset();
  }

  clContains(v: any, clazz: string) {
    return v.classList.contains(clazz);
  }

  public needToSave() {
    this.saveDisabled = !(this.timeline === null || this.timeline.name != this.name);
  }

}

interface TlProgramming {
  programming: IProgramming;
  priorities: number[];
}
