import {PlManager} from '../pl-manager';
import IStep = AES.IStep;
import {PlSurfaceModel} from './pl-surface.model';

export class PlStepModel {

  step: IStep;
  id: string;
  order: number;
  cRef: any;
  surfaces: PlSurfaceModel[] = [];
  moved = false;

  constructor() {
    this.id = '_' + Math.random().toString(36).substr(2, 9);
  }

}
