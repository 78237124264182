import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseContentComponent} from 'app/main/content/content.component';
import {CustomerModule} from './customer/customer.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {LoginModule} from './login/login.module';
import {SharedModule} from '../../shared/shared.module';
import {MaintenanceModule} from './maintenance/maintenance.module';
import {ChannelsModule} from './channels/channels.module';
import {TimelinesModule} from './timelines/timelines.module';
import {PlaylistsModule} from './playlists/playlists.module';
import {ContentsModule} from './contents/contents.module';
import { SettingsModule } from './settings/settings.module';
import {LayoutManagerModule} from './layout-manager/layout-manager.module';

// Per abilitare lazy load occorre dividere anche sharedmodule
// RouterModule.forChild(routes),
// const routes = [
//   {
//     path: 'customer/:custId/contents',
//     loadChildren: './contents/contents.module#ContentsModule'
//   },
//   {
//     path: 'customer/:custId/playlists',
//     loadChildren: './playlists/playlists.module#PlaylistsModule'
//   },
//   {
//     path: 'customer/:custId/timelines',
//     loadChildren: './timelines/timelines.module#TimelinesModule'
//   }
// ];

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    CustomerModule,
    DashboardModule,
    LoginModule,
    MaintenanceModule,
    ChannelsModule,
    ContentsModule,
    PlaylistsModule,
    TimelinesModule,
    SettingsModule
  ],
  declarations: [
    FuseContentComponent
  ],
  exports: [
    FuseContentComponent
  ]
})
export class FuseContentModule {
}
