import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import ILayout = AES.ILayout;
import * as _ from 'lodash';

@Component({
  selector: 'aesys-layout-preview-road',
  templateUrl: './layout-preview-road.component.html',
  styleUrls: ['./layout-preview-road.component.scss']
})
export class LayoutPreviewRoadComponent implements AfterViewInit {

  @Output() selected = new EventEmitter<ILayout>();
  @ViewChild('layoutView', {static: false}) layoutView;

  @Input() layout: ILayout;
  @Input() styleBig = true;

  private big = 160;
  private small = 35;

  private bkgColor = [
    'rgba(197, 123, 236, 1)',
    'rgba(123, 236, 213, 1)',
    'rgba(225, 236, 123, 1)',
    'rgba(123, 148, 236, 1)',
    'rgba(236, 123, 123, 1)'
  ];

  public showName = false;

  constructor() {
  }

  ngAfterViewInit(): void {

    const newSufaceList = [];
    for (const s of this.layout.surfaces) {
      newSufaceList.push(_.clone(s));
    }
    const surfaces = _.sortBy(newSufaceList, ['order']);
    let width = 0;
    let height = 0;
    for (const s of surfaces) {
      const swidth = s.preview_x + s.width;
      const sheight = s.preview_y + s.height;
      width = swidth > width ? swidth : width;
      height = sheight > height ? sheight : height;
    }

    const factorW = (this.styleBig ? this.big : this.small) / width;
    const factorH = (this.styleBig ? this.big : this.small) / height;
    const factor = factorW > factorH ? factorH : factorW;

    for (const s of surfaces) {
      s.preview_x = s.preview_x * factor;
      s.preview_y = s.preview_y * factor;
      s.width = s.width * factor;
      s.height = s.height * factor;
    }

    let padWidth = 0;
    let padHeight = 0;
    for (const s of surfaces) {
      const swidth = s.preview_x + s.width;
      const sheight = s.preview_y + s.height;
      padWidth = swidth > padWidth ? swidth : padWidth;
      padHeight = sheight > padHeight ? sheight : padHeight;
    }

    padWidth = ((this.styleBig ? this.big : this.small) - padWidth) / 2;
    padHeight = ((this.styleBig ? this.big : this.small) - padHeight) / 2;

    let i = 0;
    for (const s of surfaces) {
      const surface = document.createElement('div');
      surface.style.position = 'absolute';
      surface.style.width = s.width + 'px';
      surface.style.minWidth = s.width + 'px';
      surface.style.height = s.height + 'px';
      surface.style.minHeight = s.height + 'px';
      surface.style.left = padWidth + s.preview_x + 'px';
      surface.style.top = padHeight + s.preview_y + 'px';
      surface.style.backgroundColor = this.bkgColor[i];
      i++;
      this.layoutView.nativeElement.appendChild(surface);
    }
  }

}
