import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return this.convert(value);
  }

  private format(num: number): string {
    if (num < 10) {
      return '0' + num;
    } else {
      return '' + num;
    }
  }

  private convert(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor(time % 3600 / 60);
    const seconds = Math.floor(time % 3600 % 60);
    return (hours > 0 ? this.format(hours) + ':' : '') + this.format(minutes) + ':' + this.format(seconds);
  }

}
