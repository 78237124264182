import {EventEmitter, Injectable, Output} from '@angular/core';
import ICustomer = AES.ICustomer;
import gql from 'graphql-tag';
import {GraphService} from '../../network/graph.service';
import IChannel = AES.IChannel;
import * as _ from 'lodash';
import IPublication = AES.IPublication;
import IMe = AES.IMe;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  @Output()
  public channelUpdated: EventEmitter<void> = new EventEmitter<void>();

  constructor(private graph: GraphService) { }

  public getCustomerDetail(): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getCustomer,
        context: {
          uri: this.graph.getGraphqlUrl() + '?DashboardService.getCustomerDetail'
        },
        fetchPolicy: 'network-only',
        variables: {
          customer_slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getCustomer);
        });
    });
  }

  public getRoles(): Promise<IMe> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getRoles,
        context: {
          uri: this.graph.getGraphqlUrl() + '?DashboardService.getRoles'
        },
        fetchPolicy: 'network-only'
      }).subscribe(
        response => {
          resolve(response.data.getMe);
        },
        error => {
          reject(error);
        });
    });
  }

  public getChannels(): Promise<IChannel[]> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getChannels,
        context: {
          uri: this.graph.getGraphqlUrl() + '?DashboardService.getChannels'
        },
        fetchPolicy: 'network-only',
        variables: {
          slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getChannels);
        },
        error => {
          reject(error);
        });
    });
  }

  public publishChannel(channel: IChannel) {
    return new Promise<IChannel>((resolve, reject) => {
      this.graph.getApollo().mutate<IPublication>({
        mutation: publishChannel,
        context: {
          uri: this.graph.getGraphqlUrl() + '?DashboardService.publishChannel'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          cpi: {
            channel_slug: channel.slug
          }
        }
      }).subscribe(
        response => {
          const channel: IChannel = _.clone(response.data.createPublication.channel);
          channel.latest_pubblication = response.data.createPublication;
          resolve(channel);
        },
        error => {
          reject(error);
        });
    });
  }

  publishConfigurations() {
    return new Promise<boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<boolean>({
        mutation: publishConfigurations,
        context: {
          uri: this.graph.getGraphqlUrl() + '?DashboardService.publishConfigurations'
        },
        variables: {
          cs: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.publishConfigurations);
        },
        error => {
          reject(error);
        });
    });
  }
}

const getCustomer = gql`
  query getCustomer($customer_slug: String!){
    getCustomer(customer_slug: $customer_slug){
      slug
      name
      quota
      used_quota
      free_quota
      is_configurations_outdate
      last_published_configurations
    }
  }
`;

const getRoles = gql`
  query {
    getMe{
      roles{
        name
      }
    }
  }
`;

const getChannels = gql`
  query getChannels($slug: String!){
    getChannels(customer_slug: $slug){
      slug
      name
      is_outdate
      link
      latest_pubblication{
        slug
        is_ready
        published_at
      }
    }
  }
`;

const publishChannel = gql`
  mutation publishChannel($cs: String!, $cpi: CreatePublicationInput!){
    createPublication(customer_slug: $cs, input: $cpi, async: true){
      slug
      channel{
        slug
        name
        is_outdate
      }
      slug
      is_ready
      published_at

    }
  }
`;

const publishConfigurations = gql`
  mutation publishChannel($cs: String!){
    publishConfigurations(customer_slug: $cs)
  }
`;
