export class StepIoModel {
  key: string;
  value: string;

  static parse(ioString: string): StepIoModel[] {
    const ios: StepIoModel[] = [];
    if (ioString) {
      const items = JSON.parse(ioString);
      for (const item of items) {
        ios.push({
          key: item.key,
          value: item.value
        });
      }
    }
    return ios;
  }

  static stringify(ios: StepIoModel[]): string {
    if (ios.length === 0) {
      return null;
    } else {
      const tmp = [];
      for (const io of ios) {
        if (io.key) {
          tmp.push({
            key: io.key,
            value: io.value
          });
        }
      }
      return JSON.stringify(tmp);
    }
  }
}
