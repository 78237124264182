import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSelect, MatSnackBar} from '@angular/material';
import {GenericDialogComponent} from '../../../../../../shared/generic-dialog/generic-dialog.component';
import {TimelinesService} from '../../../timelines.service';
import {LoadingComponent} from '../../../../../../shared/loading/loading.component';
import ICondition = AES.ICondition;
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConditionValue} from '../../../../../network/data/condition-value.model';
import * as _ from 'lodash';

@Component({
  selector: 'aesys-tl-s-condition',
  templateUrl: './tl-s-condition.component.html',
  styleUrls: ['./tl-s-condition.component.scss']
})
export class TlSConditionComponent implements AfterViewInit {

  conditions: ICondition[] = null;
  loader: any = null;

  // form
  conditionsForm: FormGroup;
  keyFieldValidator = Validators.compose([Validators.required]);
  valueFieldValidator = Validators.compose([Validators.required]);

  selectedConditions: ConditionValue[] = [];
  initialFilter: ConditionValue[] = [];

  constructor(
    private tlService: TimelinesService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.conditionsForm = this.formBuilder.group({
      condition: []
    });
    this.initialFilter = data.filterConditions;
    this.addConditionValidator();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    }, 1);
  }

  public init() {

    this.loader = LoadingComponent.showLoading(this.dialog);
    this.tlService.getConditions()
      .then(conditions => {
        LoadingComponent.hideLoading(this.loader);
        this.conditions = conditions;
        this.initData();
      })
      .catch(error => {
        LoadingComponent.hideLoading(this.loader);
        this.dialogRef.close();
      });
  }

  private initData() {

    if (this.initialFilter.length > 0) {
      this.selectedConditions = [];
      for (const fc of this.initialFilter) {
        const cond = _.clone(fc);
        this.selectedConditions.push(cond);
        let conditionAdd = false;
        for (const condition of this.conditions) {
          if (condition.key === fc.key) {
            conditionAdd = true;
          }
        }
        if (!conditionAdd) {
          this.conditions.push({
            __typename: 'Condition',
            key: fc.key,
            values: [],
            exclusive: false,
            is_action: false
          });
        }
        this.conditionKeyChange(cond, fc.key);
        for (const condition of this.conditions) {
          if (condition.key === fc.key) {
            let valueAdd = false;
            for (const values of condition.values) {
              if (values === fc.value) {
                valueAdd = true;
              }
            }
            if (!valueAdd) {
              fc.showOptions = [...condition.values, fc.value];
            }
          }
        }
        this.conditionValueChange(cond, fc.value);
        this.addConditionValidator();
      }
    } else {
      this.selectedConditions = [];
    }
  }

  public addCondition() {
    this.selectedConditions.push({
      key: null,
      value: null,
      options: [],
      showOptions: [],
      showWarning: false,
      exclusive: false
    });
    this.addConditionValidator();
  }

  public deleteCondition(position: number) {
    this.selectedConditions.splice(position, 1);
    setTimeout(() => {
      this.addConditionValidator();
    });
  }

  public deleteAll() {
    this.selectedConditions = [];
    this.addConditionValidator();
  }

  private addConditionValidator() {
    this.conditionsForm.removeControl('condition');
    const conditionsGroup: FormGroup = new FormGroup({});
    for (let i = 0; i < this.selectedConditions.length; i++) {
      const controlKey: FormControl = new FormControl(this.selectedConditions[i].key, this.keyFieldValidator);
      conditionsGroup.addControl('conditionKey' + i, controlKey);
      const controlValue: FormControl = new FormControl(this.selectedConditions[i].value, this.valueFieldValidator);
      conditionsGroup.addControl('conditionValue' + i, controlValue);
    }
    this.conditionsForm.addControl('condition', conditionsGroup);
  }

  public applyFilter() {
    if (this.conditionsForm.valid || this.selectedConditions.length === 0) {
      this.data.filter = this.selectedConditions;
      this.dialogRef.close(this.data);
    } else {
      this.validateAllFields(this.conditionsForm);
    }
  }

  private validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  isOptionDisabled(key: string): boolean {
    for (const fc of this.selectedConditions) {
      if (fc.key == key) {
        return true;
      }
    }
    return false;
  }

  conditionValueChange(condition: ConditionValue, value: any) {
    condition.value = value;
    let warning = true;
    for (const opt of condition.options) {
      if (opt === value) {
        warning = false;
      }
    }
    if (condition.exclusive) {
      condition.showWarning = warning;
    }
  }

  addCustomConditionValue(conditionValueSelect: MatSelect, formName: string, condition: ConditionValue, value: string): void {
    if (value !== '') {
      condition.value = value;
      let setted = false;
      for (const options of conditionValueSelect.options.toArray()) {
        if (options.value === condition.value) {
          setted = true;
          this.conditionsForm.get('condition').get(formName).setValue(options);
          conditionValueSelect.value = value;
        }
        if (!setted) {
          condition.showOptions = [...condition.options, value];
          this.conditionsForm.get('condition').get(formName).setValue(value);
          conditionValueSelect.value = value;
        }
        conditionValueSelect.close();
        this.conditionValueChange(condition, value);
      }
    }
  }

  conditionKeyChange(condition: ConditionValue, value: string) {
    condition.key = value;
    condition.value = null;
    condition.options = [];
    condition.showWarning = false;
    condition.exclusive = false;
    for (const c of this.conditions) {
      if (c.key === condition.key) {
        condition.options = c.values;
        condition.exclusive = c.exclusive;
      }
    }
    condition.showOptions = condition.options;
  }

  addCustomConditionKey(conditionKeySelect: MatSelect, formName: string, condition: ConditionValue, key: string) {
    if (key !== '' && !this.isOptionDisabled(key)) {
      condition.key = key;
      let setted = false;
      for (const options of conditionKeySelect.options.toArray()) {
        if (options.value === condition.key) {
          setted = true;
          this.conditionsForm.get('condition').get(formName).setValue(options);
          conditionKeySelect.value = key;
        }
      }
      if (!setted) {
        this.conditions.push({
          __typename: 'Condition',
          key: condition.key,
          values: [],
          exclusive: false,
          is_action: false
        });
        this.conditionsForm.get('condition').get(formName).setValue(key);
        conditionKeySelect.value = key;
      }
      conditionKeySelect.close();
      this.conditionKeyChange(condition, key);
    }
  }

}
