import {Injectable} from '@angular/core';

@Injectable()
export class NotificationPollingService {
  INACTIVITY_TRIGGER_THRESHOLD = 3; // Cycle at default polling speed required for trigger inactivity procedure
  POLLING_DEFAULT_INTERVAL = 15000; // Min value for polling frequency (ms)
  INTERVAL_INCREMENT_STEP = 5000; // Each 'step up' decrease polling frequency of this amount of time (ms)
  INTERVAL_MAX = 120000; // Max interval value

  private pollingIntervalTime: number;
  private inactivityCounter: number;

  constructor() {
    this.pollingIntervalTime = this.POLLING_DEFAULT_INTERVAL;
    this.inactivityCounter = 0;
  }

  getPollingInterval(): number {
    return this.pollingIntervalTime;
  }

  getInactivityCounter(): number {
   return this.inactivityCounter;
  }

  increasePollingInterval() {
    this.pollingIntervalTime += this.INTERVAL_INCREMENT_STEP;
  }

  increaseInactivityCounter(): void {
    this.inactivityCounter++;
  }

  resetPolling() {
    this.pollingIntervalTime = this.POLLING_DEFAULT_INTERVAL;
    this.inactivityCounter = 0;
  }
}
