import {AfterViewInit, Component, ElementRef, HostBinding, Inject, OnDestroy, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';

import {FuseConfigService} from '@fuse/services/config.service';
import {isUndefined} from 'util';
import {FuseSidebarComponent} from '../../../../@fuse/components/sidebar/sidebar.component';
import {Subscription} from 'rxjs';

let sidebar;

@Component({
  selector: 'fuse-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FuseMainComponent implements OnDestroy, AfterViewInit {

  @ViewChild('bar', {static: false}) private bar: FuseSidebarComponent;
  @HostBinding('attr.fuse-layout-mode') layoutMode;
  onConfigChanged: Subscription;
  fuseSettings: any;
  sidebarVisible = false;
  logoState = 'big';

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    private fuseConfig: FuseConfigService,
    private platform: Platform,
    @Inject(DOCUMENT) private document: any
  ) {
    this.onConfigChanged =
      this.fuseConfig.onConfigChanged
        .subscribe(
          (newSettings) => {
            this.fuseSettings = newSettings;
            this.layoutMode = this.fuseSettings.layout.mode;
            this.sidebarVisible = this.fuseSettings.layout.navigation !== 'none';
          }
        );

    if (this.platform.ANDROID || this.platform.IOS) {
      this.document.body.className += ' is-mobile';
    }
  }

  ngAfterViewInit(): void {
    sidebar = this.bar;
    this.sidebarChangeState(this.bar.folded, this.bar.unfolded);
  }

  ngOnDestroy() {
    this.onConfigChanged.unsubscribe();
  }

  addClass(className: string) {
    this._renderer.addClass(this._elementRef.nativeElement, className);
  }

  removeClass(className: string) {
    this._renderer.removeClass(this._elementRef.nativeElement, className);
  }

  closeSidebarOnCLick(){
    this.bar.unfolded = false;
    this.bar.sidebarState.emit();
  }

  sidebarChangeState(folded: boolean, unfolded: boolean) {
    if (this.sidebarVisible) {
      if (folded) {
        if (unfolded) {
          this.logoState = 'small';
        } else {
          this.logoState = 'big';
        }
      } else {
        this.logoState = 'small';
      }
    }
  }
}
