import {AfterViewInit, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import ILayout = AES.ILayout;
import * as _ from 'lodash';

@Component({
  selector: 'aesys-layout-preview-tft',
  templateUrl: './layout-preview-tft.component.html',
  styleUrls: ['./layout-preview-tft.component.scss']
})
export class LayoutPreviewTftComponent implements AfterViewInit {

  @Output() selected = new EventEmitter<ILayout>();
  @ViewChild('layoutView', {static: false}) layoutView;

  @Input() layout: ILayout;
  @Input() styleBig = true;

  private bkgColor = [
    'rgba(197, 123, 236, 1)',
    'rgba(123, 236, 213, 1)',
    'rgba(225, 236, 123, 1)',
    'rgba(123, 148, 236, 1)',
    'rgba(236, 123, 123, 1)'
  ];

  constructor() {
  }

  ngAfterViewInit(): void {
    const surfaces = _.sortBy(this.layout.surfaces, ['order']);

    let i = 0;
    for (const s of surfaces) {
      const surface = document.createElement('div');
      surface.style.position = 'absolute';
      surface.style.width = s.width + '%';
      surface.style.minWidth = s.width + '%';
      surface.style.height = s.height + '%';
      surface.style.minHeight = s.height + '%';
      surface.style.left = s.x + '%';
      surface.style.top = s.y + '%';
      surface.style.backgroundColor = this.bkgColor[i];
      i++;
      this.layoutView.nativeElement.appendChild(surface);
    }
  }

}
