import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {NewCustomerDialogComponent} from '../../../customer/customer/customer-dialog/customer-dialog.component';
import IAsset = AES.IAsset;
import IContent = AES.IContent;
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import ContentType = AES.ContentType;
import {ContentTypeEnum} from '../../../../network/interface/graph-enum.model';


@Component({
  selector: 'aesys-dialog-asset-preview',
  templateUrl: './dialog-asset-preview.component.html',
  styleUrls: ['./dialog-asset-preview.component.scss']
})
export class DialogAssetPreviewComponent implements OnInit, AfterViewInit {

  content: IContent = null;
  asset: IAsset = null;
  contentType = ContentTypeEnum;
  sanitizedUrl: SafeUrl = null;

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<NewCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.asset = data.asset;
    this.content = data.content;
    if (this.content.type === ContentType.ARCHIVE) {
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.asset.expanded_path + 'index.html');
    }
    if (this.content.type === ContentType.URL) {
      this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

}
