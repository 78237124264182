import {Injectable} from '@angular/core';
import {GraphService} from '../../network/graph.service';
import gql from 'graphql-tag';
import ILayout = AES.ILayout;
import {IoModel} from '../../network/data/io.model';
import ICondition = AES.ICondition;

@Injectable()
export class LayoutManagerService {

  constructor(public graph: GraphService) {
  }

  public getLayouts(): Promise<ILayout[]> {
    return new Promise<ILayout[]>((resolve, reject) => {
      this.graph.getApollo().query<ILayout[]>({
        query: getLayouts,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?LayoutManagerService.getLayouts'
        },
        variables: {
          slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve((response.data as any).getLayouts);
        });
    });
  }

  public getLayout(slug: string): Promise<ILayout> {
    return new Promise<ILayout>((resolve, reject) => {
      this.graph.getApollo().query<ILayout>({
        query: getLayout,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?LayoutManagerService.getLayout'
        },
        variables: {
          slug: this.graph.getCurrentCustomer(),
          layout_slug: slug
        }
      }).subscribe(
        response => {
          resolve((response.data as any).getLayout);
        });
    });
  }

  public saveUpdateLayout(layout: ILayout, ios: IoModel[]): Promise<AES.ILayout[]> {
    const l = {};
    l['name'] = layout.name;
    l['type'] = layout.type;
    l['io'] = IoModel.stringify(ios);
    l['surfaces'] = [];
    for (const surface of layout.surfaces) {
      const s = {};
      if (surface.slug) {
        s['slug'] = surface.slug;
      }
      s['height'] = surface.height;
      s['width'] = surface.width;
      s['x'] = surface.x;
      s['y'] = surface.y;
      s['preview_x'] = surface.preview_x;
      s['preview_y'] = surface.preview_y;
      l['surfaces'].push(s);
    }

    if (layout.slug) {
      return new Promise<any>((resolve, reject) => {
        this.graph.getApollo().mutate<any>({
          mutation: updateLayout,
          context: {
            uri: this.graph.getGraphqlUrl() + '?LayoutManagerService.updateLayout'
          },
          variables: {
            slug: this.graph.getCurrentCustomer(),
            layout_slug: layout.slug,
            input: l
          }
        }).subscribe(
          response => {
            resolve(response.data.updateLayout);
          });
      });
    } else {
      return new Promise<any>((resolve, reject) => {
        this.graph.getApollo().mutate<any>({
          mutation: createLayout,
          context: {
            uri: this.graph.getGraphqlUrl() + '?LayoutManagerService.createLayout'
          },
          variables: {
            slug: this.graph.getCurrentCustomer(),
            input: l
          }
        }).subscribe(
          response => {
            resolve(response.data.createLayout);
          });
      });
    }
  }

  deleteLayout(slug: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deleteLayout,
        context: {
          uri: this.graph.getGraphqlUrl() + '?LayoutManagerService.deleteLayout'
        },
        variables: {
          slug: this.graph.getCurrentCustomer(),
          layout_slug: slug,
        }
      }).subscribe(
        response => {
          resolve(response.data.deleteLayout);
        });
    });
  }

  public getIO(): Promise<ICondition[]> {
    return new Promise<ICondition[]>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getConditions,
        context: {
          uri: this.graph.getGraphqlUrl() + '?LayoutManagerService.getConditions'
        },
        variables: {
          cs: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          const conditions: ICondition[] = [];
          for (const c of response.data.getConditions) {
            if (c.is_action) {
              conditions.push(c);
            }
          }
          // conditions.push({
          //   key: 'test1',
          //   values: [
          //     'A', 'C'
          //   ],
          //   is_action: true,
          //   __typename: 'Condition',
          //   exclusive: false
          // });
          resolve(conditions);
        });
    });
  }

}

const layoutFragment = gql`
  fragment layoutFragment on Layout{
    slug
    type
    name
    io
    is_used
    surfaces{
      slug
      height
      width
      x
      y
      preview_x
      preview_y
    }
  }
`;

const getLayout = gql`
  query getLayout($slug: String!, $layout_slug: String!){
    getLayout(customer_slug: $slug, layout_slug: $layout_slug){
      ...layoutFragment
    }
  }
  ${layoutFragment}
`;

const getLayouts = gql`
  query getLayouts($slug: String!){
    getLayouts(customer_slug: $slug){
      slug
      type
      name
      io
      is_used
      surfaces{
        slug
      }
    }
  }
`;

const createLayout = gql`
  mutation createLayout($slug: String!, $input: CreateLayoutInput!){
    createLayout(customer_slug: $slug, input: $input){
      ...layoutFragment
    }
  }
  ${layoutFragment}
`;

const updateLayout = gql`
  mutation updateLayout($slug: String!, $layout_slug: String!, $input: UpdateLayoutInput!){
    updateLayout(customer_slug: $slug, layout_slug: $layout_slug, input: $input){
      ...layoutFragment
    }
  }
  ${layoutFragment}
`;

const deleteLayout = gql`
  mutation deleteLayout($slug: String!, $layout_slug: String!){
    deleteLayout(customer_slug: $slug, layout_slug: $layout_slug)
  }
`;

const getConditions = gql`
  query getConditions($cs: String!){
    getConditions(customer_slug: $cs){
      key
      values
      exclusive
      is_action
    }
  }
`;
