import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';

import {NavigationStart, Router} from '@angular/router';
import {isUndefined} from 'util';

@Component({
  selector: 'aesys-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private fuseNavigationService: FuseNavigationService,
    private fuseSplashScreen: FuseSplashScreenService,
    private router: Router
  ) {

    this.translate.addLangs(['it', 'en', 'fr']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.initQuery();
  }

  private initQuery() {
    this.router.events.subscribe(s => {
      if (s instanceof NavigationStart) {
        const params = new URLSearchParams(s.url.split('#')[1]);
        const code = params.get('code');
        if (code !== null && !isUndefined(code) && code !== '') {
          this.router.navigate(['login/' + code]);
        }
      }
    });
  }
}
