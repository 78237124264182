import {Pipe, PipeTransform} from '@angular/core';
import {StepIoModel} from '../../../../../network/data/step-io.model';

@Pipe({name: 'hasoutput'})
export class HasOutputPipe implements PipeTransform {
    transform(outputs: StepIoModel[], key: string) {
        let ret = false;
        for (const o of outputs) {
            if (o.key === key) {
                ret = true;
            }
        }
        return ret;
    }
}
