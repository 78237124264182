import ISurface = AES.ISurface;
import {ViewContainerRef} from '@angular/core';
import {PlContentModel} from './pl-content.model';

export class PlSurfaceModel {

  surface: ISurface;
  id: string;
  cRef: any;
  style: string;
  containerRef: ViewContainerRef;
  plContents: PlContentModel[] = [];

  constructor() {
    this.id = '_' + Math.random().toString(36).substr(2, 9);
  }

}
