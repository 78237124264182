import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {GraphService} from '../../../network/graph.service';
import {TranslateService} from '@ngx-translate/core';
import {ChannelsService} from '../channels.service';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import IChannel = AES.IChannel;
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import * as _ from 'lodash';
import {DatePipe} from '@angular/common';
import {interval} from 'rxjs';

@Component({
  selector: 'aesys-channels-list',
  templateUrl: './channels-list.component.html',
  styleUrls: ['./channels-list.component.scss']
})
export class ChannelsListComponent implements OnInit, OnDestroy {

  INTERVAL = 5000;
  private listObserver = null;
  firstTime = true;
  loader: any = null;
  channels: IChannel[] = [];

  constructor(private router: Router,
              private graphService: GraphService,
              private chService: ChannelsService,
              private translateService: TranslateService,
              private datePipe: DatePipe,
              public dialog: MatDialog,
              public errorBar: MatSnackBar) {
  }

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {
    this.stopObserver();
  }

  private stopObserver() {
    if (this.listObserver !== null) {
      this.listObserver.unsubscribe();
      this.listObserver = null;
    }
  }

  private startObserver() {
    let update = false;
    for (const c of this.channels) {
      if (c.latest_pubblication !== null && !c.latest_pubblication.is_ready) {
        update = true;
      }
    }
    if (update) {

      this.listObserver = interval(this.INTERVAL).subscribe((x) => {
        this.init();
      });
    } else {
      this.stopObserver();
    }
  }

  public init() {
    setTimeout(() => {
      if (this.firstTime) {
        this.firstTime = false;
        this.loader = LoadingComponent.showLoading(this.dialog);
      }

      this.chService.getChannels()
        .then(channels => {
          LoadingComponent.hideLoading(this.loader);
          this.channels = _.clone(channels);
          this.startObserver();
          this.chService.channelUpdated.emit();
        })
        .catch(error => {
          LoadingComponent.hideLoading(this.loader);
          this.loader = null;
          this.errorBar.open(this.translateService.instant('CHANNEL_L.LIST_ERROR'), '', {
            duration: 1000
          });
        });
    }, 1);
  }

  public publish(channel: IChannel) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        message: this.translateService.instant('CHANNEL_L.DIALOG_PUBLISH_MESSAGE'),
        positiveButton: this.translateService.instant('CHANNEL_L.DIALOG_PUBLISH_CONFIRM'),
        negativeButton: this.translateService.instant('CHANNEL_L.DIALOG_UNDO')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loader = LoadingComponent.showLoading(this.dialog);
        this.chService.publishChannel(channel)
          .then(updatedChannel => {
            LoadingComponent.hideLoading(this.loader);
            const index = this.channels.indexOf(channel);
            this.channels.splice(index, 1, updatedChannel);
            this.errorBar.open(this.translateService.instant('CHANNEL_L.PUBLISH_SUCCESS'), '', {
              duration: 1000
            });
            this.startObserver();
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.errorBar.open(this.translateService.instant('CHANNEL_L.PUBLISH_ERROR'), '', {
              duration: 1000
            });
          });
      }
    });
  }

  public allReady(channel: IChannel): boolean {
    return channel.latest_pubblication !== null ? channel.latest_pubblication.is_ready : true;
  }

  public copyLink() {
    this.errorBar.open(this.translateService.instant('CHANNEL_L.LINK_COIPED'), '', {
      duration: 1000
    });
  }

  public getLastPublication(channel: IChannel): string {
    if (channel.latest_pubblication !== null) {
      if (channel.latest_pubblication.published_at !== null) {
        return this.datePipe.transform(channel.latest_pubblication.published_at * 1000, 'dd/MM/yyyy HH:mm');
      } else {
        return this.translateService.instant('CHANNEL_L.CHANNEL_PUBLISHING');
      }
    } else {
      return '';
    }
  }

}
