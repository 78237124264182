import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'bkgColor'
})
export class BackgroundColorPipe implements PipeTransform {

  transform(value: string, alpha: any): string {

    const color = value.replace('#', '');
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);
    return 'rgba(' + r + ',' + g + ',' + b + ',' + alpha + ')';
  }

  private format(num: number): string {
    if (num < 10) {
      return '0' + num;
    } else {
      return '' + num;
    }
  }


}
