import {EventEmitter, Injectable, Output} from '@angular/core';
import {GraphService} from '../../network/graph.service';
import gql from 'graphql-tag';
import IChannel = AES.IChannel;
import IPublication = AES.IPublication;
import * as _ from 'lodash';

@Injectable()
export class ChannelsService {

  @Output()
  public channelUpdated: EventEmitter<void> = new EventEmitter<void>();

  constructor(public graph: GraphService) {
  }

  public getChannels(): Promise<IChannel[]> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getChannels,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ChannelsService.getChannels'
        },
        fetchPolicy: 'network-only',
        variables: {
          slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getChannels);
        },
        error => {
          reject(error);
        });
    });
  }

  public publishChannel(channel: IChannel) {
    return new Promise<IChannel>((resolve, reject) => {
      this.graph.getApollo().mutate<IPublication>({
        mutation: publishChannel,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ChannelsService.publishChannel'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          cpi: {
            channel_slug: channel.slug
          }
        }
      }).subscribe(
        response => {
          const channel: IChannel = _.clone(response.data.createPublication.channel);
          channel.latest_pubblication = response.data.createPublication;
          resolve(channel);
        },
        error => {
          reject(error);
        });
    });
  }

}

const getChannels = gql`
  query getChannels($slug: String!){
    getChannels(customer_slug: $slug){
      slug
      name
      is_outdate
      link
      latest_pubblication{
        slug
        is_ready
        published_at
      }
    }
  }
`;

const publishChannel = gql`
  mutation publishChannel($cs: String!, $cpi: CreatePublicationInput!){
    createPublication(customer_slug: $cs, input: $cpi, async: true){
      slug
      channel{
        slug
        name
        is_outdate
      }
      slug
      is_ready
      published_at

    }
  }
`;
