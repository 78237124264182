import {NgModule} from '@angular/core';
import {LayoutManagerListComponent} from './layout-manager-list/layout-manager-list.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {LayoutManagerService} from './layout-manager.service';
import {LayoutManagerDetailComponent} from './layout-manager-detail/layout-manager-detail.component';
import {LayoutPreviewTftComponent} from './layout-preview-tft/layout-preview-tft.component';
import {LayoutPreviewRoadComponent} from './layout-preview-road/layout-preview-road.component';

const routes = [];

@NgModule({
  declarations: [
    LayoutManagerListComponent,
    LayoutManagerDetailComponent,
    LayoutPreviewTftComponent,
    LayoutPreviewRoadComponent
  ],
  entryComponents: [
    LayoutManagerListComponent,
    LayoutManagerDetailComponent,
    LayoutPreviewTftComponent,
    LayoutPreviewRoadComponent,
  ],
  providers: [
    LayoutManagerService
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
  ],
  exports: [
    LayoutPreviewTftComponent,
    LayoutPreviewRoadComponent
  ]
})
export class LayoutManagerModule {
}
