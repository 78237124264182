import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {PlaylistsService} from '../../../playlists.service';
import {FuseTranslationLoaderService} from '../../../../../../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import ITransition = AES.ITransition;

@Component({
  selector: 'aesys-pl-transition-dialog',
  templateUrl: './pl-transition-dialog.component.html',
  styleUrls: ['./pl-transition-dialog.component.scss']
})
export class PlTransitionDialogComponent implements OnInit {

  transitions: ITransition[];
  transition = '';

  constructor(
    private plService: PlaylistsService,
    private translateService: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService,
    public dialogRef: MatDialogRef<PlTransitionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.transition = data.transitionSlug;
  }

  ngOnInit() {
    this.plService.getTransitions()
      .then(transitions => {
        this.transitions = transitions;
      })
      .catch(error => {
        this.dialogRef.close();
      });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.data.save = true;
    this.data.newTransitionSlug = this.transition;
    this.dialogRef.close(this.data);
  }

}
