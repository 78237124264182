import {AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ContentsService} from '../contents.service';
import {GraphService} from '../../../network/graph.service';
import {UploadService} from '../../../layout/layout-component/upload/upload.service';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatSnackBar} from '@angular/material';
import {FileSystemFileEntry, UploadEvent, UploadFile} from 'ngx-file-drop';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {FormError} from '../../../../util/form/form-error.model';
import ContentType = AES.ContentType;

@Component({
  selector: 'aesys-contents-multi-upload',
  templateUrl: './contents-multi-upload.component.html',
  styleUrls: ['./contents-multi-upload.component.scss']
})
export class ContentsMultiUploadComponent implements OnInit, AfterViewInit {

  enableOverDrop = false;
  files: ContentUploadFile[] = [];
  needRetryFiles: ContentUploadFile[] = [];
  loader: any = null;
  uploadDisabled = true;
  private contentCounter = 0;

  constructor(private route: ActivatedRoute,
              public contentService: ContentsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private graphService: GraphService,
              private uploadService: UploadService,
              private translateService: TranslateService,
              private appRef: ApplicationRef,
              private changeDetectorRefs: ChangeDetectorRef,
              public dialog: MatDialog,
              public errorBar: MatSnackBar) {
  }

  ngOnInit() {
    this.contentService.getAssetContentTypes();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.contentService.temporaryUpload !== null) {
        this.dropped(this.contentService.temporaryUpload);
        this.contentService.temporaryUpload = null;
      }
    }, 1);
  }

  public dropped(event: UploadEvent) {
    for (const file of event.files) {
      if (file.fileEntry.isFile) {
        const fileEntry = file.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.contentService.getAssetContentTypes()
            .then(assetsContentTypes => {
              let contentType: ContentType = null;
              let isValid = false;
              let syncProcess = true;
              for (const type of assetsContentTypes) {
                if (type.values.indexOf(file.type) !== -1) {
                  contentType = type.key as any;
                  isValid = true;
                }
              }

              // cablo controllo pittogramma // immagine
              const that = this;
              if (contentType === ContentType.PICTOGRAM || contentType === ContentType.IMAGE) {
                syncProcess = false;
                const img = new Image();
                const url = URL.createObjectURL(file);
                img.onload = function () {
                  // @ts-ignore
                  const imgHeight = this.height;
                  // @ts-ignore
                  const imgWidth = this.width;

                  if (imgWidth < 600 && imgHeight < 400 && (imgWidth * imgHeight) < 240000) {
                    contentType = ContentType.PICTOGRAM;
                  } else {
                    contentType = ContentType.IMAGE;
                  }
                  that.postPonedFileProcess(file, contentType, isValid);
                };
                img.src = url;
              }

              if (syncProcess) {
                this.postPonedFileProcess(file, contentType, isValid);
              }

            });
        });
      }
    }
  }

  public postPonedFileProcess(file: File, contentType: any, isValid: boolean) {
    let name = file.name.substring(0, file.name.lastIndexOf('.'));
    if (name === '') {
      name = file.name;
    }
    this.files.push({
      file: file,
      name: name,
      type: contentType,
      isValid: isValid
    });
    this.appRef.tick();
    this.checkValidFile();
  }

  public checkValidFile() {
    let valid = true;
    for (const file of this.files) {
      if (file.isValid) {
        valid = false;
      }
    }
    this.uploadDisabled = valid;
    this.appRef.tick();
  }

  public fileOver(event) {
    this.enableOverDrop = true;
  }

  public fileLeave(event) {
    this.enableOverDrop = false;
  }

  public delete(file: ContentUploadFile) {
    this.files.splice(this.files.indexOf(file), 1);
    this.checkValidFile();
  }

  public upload() {

    if (this.loader === null) {
      this.loader = LoadingComponent.showLoading(this.dialog);
    }
    this.contentCounter = 0;
    for (const file of this.files) {
      if (file.isValid) {
        this.contentCounter++;
        this.contentService.createContent(file.name, file.type, '')
          .then(content => {
            this.uploadService.addFileQueue(file.file, content);
            this.contentCounter--;
            if (this.contentCounter == 0) {
              this.checkRetry();
            }
          })
          .catch(error => {
            try {
              for (const e of (error as FormError).error) {
                if (e[1][0] === 'unique') {
                  const index = file.name.lastIndexOf('-copy');
                  let name = file.name + '-copy';
                  if (index > -1) {
                    name = file.name.substring(0, index);
                    let count: string = file.name.substring(index, file.name.length);
                    count = count.replace('-copy', '');
                    count = count.replace('-', '');
                    if (count === '') {
                      count = '1';
                    } else {
                      const c = count;
                      count = '' + ((+c) + 1);
                    }
                    name = name + '-copy-' + count;
                  }
                  file.name = name;
                  this.needRetryFiles.push(file);
                }
              }
            } catch (e) {
            }
            this.contentCounter--;
            if (this.contentCounter == 0) {
              this.checkRetry();
            }
          });
      }
    }
  }

  private checkRetry() {
    if (this.needRetryFiles.length > 0) {
      this.files = this.needRetryFiles;
      this.needRetryFiles = [];
      this.upload();
    } else {
      LoadingComponent.hideLoading(this.loader);
      this.files = [];
      this.errorBar.open(this.translateService.instant('CONTENT_UP.CONTENTS_CREATED'), '', {
        duration: 1000,
      });
    }
  }

  public advanced() {
    this.router.navigate(['/customer/' + this.graphService.getCurrentCustomer() + '/contents/NEW']);
  }

}

interface ContentUploadFile {
  file: File;
  name: string;
  type: ContentType;
  isValid: boolean;
}
