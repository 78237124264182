import {Injectable} from '@angular/core';
import {GraphService} from '../../network/graph.service';
import gql from 'graphql-tag';
import ICreateCustomerInput = AES.ICreateCustomerInput;
import ICustomer = AES.ICustomer;
import IMe = AES.IMe;

@Injectable()
export class CustomerService {

  constructor(private graph: GraphService) {

  }

  // QUERIES
  public createCustomer(cci: ICreateCustomerInput): Promise<AES.ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: createCustomer,
        context: {
          uri: this.graph.getGraphqlUrl() + '?CustomerService.createCustomer'
        },
        variables: {
          cci: {
            aesys_customer_id: cci.aesys_customer_id,
            name: cci.name,
            quota: cci.quota,
            bucket_location: cci.bucket_location
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.login);
        });
    });
  }

  // createCustomer(input: CreateCustomerInput!, async: Boolean = true): Customer
  public getCustomers(force: boolean): Promise<IMe> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getCustomers,
        context: {
          uri: this.graph.getGraphqlUrl() + '?CustomerService.getCustomers'
        },
        fetchPolicy: 'network-only'
      }).subscribe(
        response => {
          resolve(response.data.getMe);
        },
        error => {
          reject(error);
        });
    });
  }

}

const createCustomer = gql`
  mutation createCustomer($cci: CreateCustomerInput!){
    createCustomer(input: $cci, async: true){
      slug
    }
  }
`;

const getCustomers = gql`
  query {
    getMe{
      slug
      name
      email
      own_customer
      customers{
        slug
        aesys_customer_id
        name
        is_ready
        is_active
      }
      customers_visibility
      roles{
        name
      }
    }
  }
`;
