import {EventEmitter, Injectable, Output} from '@angular/core';
import {GraphService} from '../../../network/graph.service';
import {ParallelHasher} from 'ts-md5/dist/parallel_hasher';
import gql from 'graphql-tag';
import IAsset = AES.IAsset;
import IContent = AES.IContent;
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
import ModelType = AES.ModelType;

@Injectable()
export class UploadService {

  uploadStarted = new EventEmitter<string>();

  private UPLOAD_LIMIT = 2;

  public queueChanged = new Subject();
  public uploading: [File, IContent, Subscription][] = [];
  public errorQueue: [File, IContent, Subscription][] = [];
  public queue: [File, IContent][] = [];

  constructor(
    private graph: GraphService,
    private http: HttpClient,
    private translate: TranslateService,
    public snackBar: MatSnackBar) {
    this.queueChanged
      .subscribe(() => {
        if (this.uploading.length < this.UPLOAD_LIMIT && this.queue.length > 0) {
          this.uploading.push([this.queue[0][0], this.queue[0][1], null]);
          this.upload(this.uploading[this.uploading.length - 1]);
          this.queue.shift();
          this.queueChanged.next();
        }
      });
  }

  public addFileQueue(file: File, content: IContent) {
    this.queue.push([file, content]);
    this.queueChanged.next();
  }

  private upload(item: [File, IContent, Subscription]): void {
    this.calculateMD5(item[0])
      .then(md5 => {
        return new Promise<IAsset>((resolve, reject) => {
          this.graph.getApollo().mutate<IAsset>({
            mutation: createAsset,
            context: {
              uri: this.graph.getGraphqlUrl() + '?UploadService.upload'
            },
            variables: {
              cs: this.graph.getCurrentCustomer(),
              input: {
                model_type: ModelType.CONTENT,
                model_slug: item[1].slug,
                name: item[0].name,
                md5: md5,
                size: item[0].size,
                content_type: item[0].type
              }
            }
          }).subscribe(
            response => {
              resolve(response.data.createAsset);
            },
            error => {
              let message = this.translate.instant('UPLOAD.ERROR');
              if (error.graphQLErrors) {
                for (const e of error.graphQLErrors) {
                  for (const key in e.validation) {
                    for (const err of e.validation[key]) {
                      if (err === 'quota_exeed') {
                        message = this.translate.instant('UPLOAD.QUOTA_EXEED');
                      }
                    }
                  }
                }
              }

              this.snackBar.open(message, '', {
                duration: 1000
              });
              this.removeUpload(item);
            });
        });
      })
      .then(asset => {
        // return asset.signed_upload_url;
        return new Promise<string>((resolve, reject) => {
          console.log(asset.signed_upload_url);
          console.log('content type: ' + item[0].type);
          const param = new HttpParams();
          let header = new HttpHeaders();
          // header = header.append('Content-Length', '0');
          header = header.append('Content-Type', item[0].type);
          // header = header.append('Origin', '*');
          header = header.append('x-goog-resumable', 'start');
          return this.http.post(asset.signed_upload_url, param, {headers: header, observe: 'response'})
            .subscribe(success => {
                const url = (success as any).headers.get('Location') as string;
                resolve(url);
              },
              error1 => {
                console.log('post error');
                console.log(error1);
                reject();
              });
        });
      })
      .then(url => {
        let header = new HttpHeaders();
        header = header.append('Content-Length', '' + item[0].size);
        header = header.append('Content-Type', item[0].type);
        if (!this.uploadStarted.closed) {
          this.uploadStarted.emit(item[1].slug);
        }
        console.log('upload: ' + url);
        console.log(item[0]);
        const subs = this.http.put(url, item[0])
          .subscribe(
            response => {
              console.log('upload response');
              console.log(response);
              this.removeUpload(item);
            },
            err => {
              console.log('upload error');
              console.log(err);
              this.addUploadError(item);
              this.removeUpload(item);
            }
          );
        item[2] = subs;

      }).catch(
      error => {

      }
    );
  }

  private addUploadError(item: [File, IContent, Subscription]) {
    this.errorQueue.push(item);
  }

  public removeUpload(item: [File, IContent, Subscription]) {
    const index = this.uploading.indexOf(item, 0);
    if (index > -1) {
      this.uploading.splice(index, 1);
    }
    this.queueChanged.next();
  }

  public removeFromErrorQueue(item: [File, IContent, Subscription]) {
    const index = this.errorQueue.indexOf(item, 0);
    if (index > -1) {
      this.errorQueue.splice(index, 1);
    }
    this.queueChanged.next();
  }

  public removeFromQueue(item: [File, IContent]) {
    const index = this.queue.indexOf(item, 0);
    if (index > -1) {
      this.queue.splice(index, 1);
    }
    this.queueChanged.next();
  }

  private calculateMD5(file: File): Promise<string> {
    return new Promise<string>((resolve) => {
      const hasher = new ParallelHasher('/assets/md5_worker.js');
      hasher.hash(file).then(function (result) {
        resolve(result);
      });
    });
  }
}

const createAsset = gql`
    mutation createAsset($cs: String!, $input: CreateAssetInput!){
        createAsset(customer_slug: $cs, input: $input){
            slug
            signed_upload_url
            signed_upload_url_expire_at
        }
    }
`;
