import {ApplicationRef, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ContentsService} from '../contents.service';
import IContent = AES.IContent;
import {GraphService} from '../../../network/graph.service';
import {FileSystemFileEntry, UploadEvent, UploadFile} from 'ngx-file-drop';
import {UploadService} from '../../../layout/layout-component/upload/upload.service';
import {MatDialog, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import IAsset = AES.IAsset;
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {FormControl, Validators} from '@angular/forms';
import {AesysFormControl} from '../../../../util/form/AesysFormControl';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {DialogAssetPreviewComponent} from './dialog-asset-preview/dialog-asset-preview.component';
import {interval} from 'rxjs';
import ContentType = AES.ContentType;
import {ContentTypeEnum} from '../../../network/interface/graph-enum.model';

@Component({
  selector: 'aesys-content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['./content-detail.component.scss']
})
export class ContentDetailComponent implements OnInit, OnDestroy {

  INTERVAL = 5000;
  private listObserver = null;
  firstTime = true;
  enableOverDrop = false;

  loader: any;

  contentType = ContentTypeEnum;
  content: IContent = null;

  saveDisabled = false;

  // Table
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  columns = ['type', 'name', 'size', 'resolution', 'ratio', 'del', 'state', 'view'];
  dataSource: MatTableDataSource<IAsset> = null;

  // Form
  editable = false;
  name = '';
  type = '';
  url = '';
  files: UploadFile[] = [];

  nameFormControll = new AesysFormControl('name', this.translateService, '', [
    Validators.required
  ]);

  typeFormControll = new FormControl('', [
    Validators.required
  ]);

  urlFormControll = new FormControl('', [
    Validators.required,
    Validators.pattern('https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)')
  ]);

  uploadSubs: any;


  constructor(private route: ActivatedRoute,
              public contentService: ContentsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private graphService: GraphService,
              private uploadService: UploadService,
              private translateService: TranslateService,
              private appRef: ApplicationRef,
              // private fuseTranslationLoader: FuseTranslationLoaderService,
              private changeDetectorRefs: ChangeDetectorRef,
              private _location: Location,
              public dialog: MatDialog,
              public errorBar: MatSnackBar) {

    // this.fuseTranslationLoader.loadTranslations(english, deutsch, italiano);

    // Prevent drop file su zone non idonee
    document.body.addEventListener('dragover', function (e) {
      e.preventDefault();
    }, false);
    document.body.addEventListener('drop', function (e) {
      e.preventDefault();
    }, false);
  }

  ngOnInit() {
    const id = this.route.snapshot.params.contentId;
    if (id === 'NEW') {
      this.initNewContent();
    } else {
      this.getContent(id);
    }

    this.uploadSubs = this.uploadService.uploadStarted
      .subscribe(slug => {
        if (slug === this.content.slug) {
          this.getContent(this.route.snapshot.params.contentId);
        }
      });
  }

  private reinit() {
    this.getContent(this.route.snapshot.params.contentId);
  }


  ngOnDestroy(): void {
    this.uploadSubs.unsubscribe();
    this.stopObserver();
  }

  private stopObserver() {
    if (this.listObserver !== null) {
      this.listObserver.unsubscribe();
      this.listObserver = null;
    }
  }

  private startObserver() {
    let update = false;
    for (const a of this.content.assets) {
      if (!a.is_ready) {
        update = true;
      }
    }
    if (update) {
      if (this.listObserver === null) {
        this.listObserver = interval(this.INTERVAL).subscribe((x) => {
          this.reinit();
        });
      }
    } else {
      this.stopObserver();
    }
  }


  private initNewContent() {
    this.editable = true;
  }

  private getContent(id) {
    setTimeout(() => {
      if (this.firstTime) {
        this.firstTime = false;
        this.loader = LoadingComponent.showLoading(this.dialog);
      }
      this.contentService.getContent(id)
        .then(content => {
          LoadingComponent.hideLoading(this.loader);
          this.content = content;
          this.initUI();
          this.startObserver();
        })
        .catch(error => {
          LoadingComponent.hideLoading(this.loader);
          this.loader = null;
          this._location.back();
          this.errorBar.open(this.translateService.instant('CONTENT_D.ERR_GET_CONTENT'), '', {
            duration: 1000
          });
        });

    }, 1);

  }

  private initUI() {
    this.editable = false;
    this.name = this.content.name;
    this.type = this.content.type;
    this.url = this.content.external_url;
    this.nameFormControll.setValue(this.content.name);
    this.urlFormControll.setValue(this.content.external_url);
    this.typeFormControll.setValue(this.content.type);
    this.initList();
    this.needToSave();
  }

  private initList() {

    const assets = [];
    for (const asset of this.content.assets) {
      if (asset.is_uploaded) {
        assets.push(asset);
      }
    }

    this.dataSource = new MatTableDataSource(assets);
    this.changeDetectorRefs.detectChanges();
    this.dataSource.sort = this.sort;
  }

  // FORM

  saveContent() {

    if ((this.name !== '' && this.type !== '' && this.type !== this.contentType.URL)
      || (this.name !== '' && this.type !== '' && this.url !== '')) {
      if (this.type !== this.contentType.URL) {
        this.url = null;
      }
      this.loader = LoadingComponent.showLoading(this.dialog);
      if (this.content === null) {
        this.contentService.createContent(this.name, this.type, this.url)
          .then(content => {
            LoadingComponent.hideLoading(this.loader);
            this.router.navigateByUrl('/customer/' + this.graphService.getCurrentCustomer() + '/contents/' + content.slug, {replaceUrl: true});
            this.getContent(content.slug);
            this.errorBar.open(this.translateService.instant('CONTENT_D.CONTENT_UPDATED'), '', {
              duration: 1000,
            });
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.nameFormControll.setCustomError(error);
          });
      } else {
        this.contentService.updateContent(this.content.slug, this.name, this.url)
          .then(id => {
            LoadingComponent.hideLoading(this.loader);
            this.router.navigateByUrl('/customer/' + this.graphService.getCurrentCustomer() + '/contents/' + id, {replaceUrl: true});
            this.getContent(id);
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.nameFormControll.setCustomError(error);
          });
      }
    }
  }

  // UPLOAD

  public canUpload() {
    let canUpload = false;
    if (this.content !== null &&
      (this.content.type === ContentType.VIDEO ||
        this.content.type === ContentType.IMAGE ||
        (this.content.type === ContentType.PICTOGRAM && this.content.assets.length === 0) ||
        (this.content.type === ContentType.ARCHIVE && this.content.assets.length === 0)
      )
    ) {
      canUpload = true;
    }
    return canUpload;
  }

  public filePicked(event) {
    this.upload(event.srcElement.files[0]);
  }

  public dropped(event: UploadEvent) {
    this.files = event.files;
    if (this.content.type === ContentType.ARCHIVE && event.files.length > 1) {
      this.errorBar.open(this.translateService.instant('CONTENT_D.UNIQUE_ARCHIVE_MESSAGE'), '', {
        duration: 1000,
      });
      return;
    }
    for (const droppedFile of event.files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.upload(file);
        });

      } else {
        if (this.content.type === ContentType.VIDEO) {
          this.errorBar.open(this.translateService.instant('CONTENT_D.INVALID_FORMAT_VIDEO'), '', {
            duration: 2000,
          });
        } else {
          this.errorBar.open(this.translateService.instant('CONTENT_D.INVALID_FORMAT'), '', {
            duration: 1000,
          });
        }
      }
    }
  }

  private upload(file: File) {
    this.contentService.getAssetContentTypes()
      .then(assetsContentTypes => {
        let enableUpload = false;
        // Check if file type is suitable for current content type
        for (const type of assetsContentTypes) {
          if (type.key === this.content.type) {
            if (type.values.indexOf(file.type) !== -1) {
              enableUpload = true;
            }
          }
        }
        // Upload execution (if file type is right)
        if (enableUpload) {
          this.uploadService.addFileQueue(file, this.content);
        } else {
          if (this.content.type === ContentType.VIDEO) {
            this.errorBar.open(this.translateService.instant('CONTENT_D.INVALID_FORMAT_VIDEO'), '', {
              duration: 2000,
            });
          } else {
            this.errorBar.open(this.translateService.instant('CONTENT_D.INVALID_FORMAT'), '', {
              duration: 1000,
            });
          }
          this.appRef.tick();
        }

      })
      .catch(error => {
        this.errorBar.open(this.translateService.instant('CONTENT_D.ERR_GET_ASSET_CONTENT_TYPE'), '', {
          duration: 1000
        });
      });
  }

  public fileOver(event) {
    // console.log(event);
    this.enableOverDrop = true;
  }

  public fileLeave(event) {
    // console.log(event);
    this.enableOverDrop = false;
  }

  // TABLE

  public deleteAsset(asset: IAsset) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        message: this.translateService.instant('CONTENT_D.DELETE_ASSET_MESSAGE'),
        positiveButton: this.translateService.instant('CONTENT_D.DIALOG_CONFIRM'),
        negativeButton: this.translateService.instant('CONTENT_D.DIALOG_UNDO')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loader = LoadingComponent.showLoading(this.dialog);
        this.contentService.deleteAsset(asset)
          .then(success => {
            this.reinit();
          })
          .catch(error => {
            this.errorBar.open(this.translateService.instant('CONTENT_D.ERR_DELETE_ASSET'), '', {
              duration: 1000
            });
          });
      }
    });

  }

  public getStateIcon(asset: IAsset): string {
    if (asset.has_error) {
      return 'error';
    } else if (asset.is_ready) {
      return 'check';
    } else if (!asset.is_uploaded) {
      return 'cloud_upload';
    } else {
      return 'sync';
    }
  }

  public getStateTooltip(asset: IAsset): string {
    if (asset.has_error) {
      return this.translateService.instant('CONTENT_D.ASSET_TOOLTIP_ERROR');
    } else if (asset.is_ready) {
      return this.translateService.instant('CONTENT_D.ASSET_TOOLTIP_READY');
    } else if (!asset.is_uploaded) {
      return this.translateService.instant('CONTENT_D.ASSET_TOOLTIP_UPLOADING');
    } else {
      return this.translateService.instant('CONTENT_D.ASSET_TOOLTIP_PROCESSING');
    }
  }

  public view(asset: IAsset) {
    this.dialog.open(DialogAssetPreviewComponent, {
      data: {
        content: this.content,
        asset: asset
      }
    });
  }

  public viewUrl() {
    this.dialog.open(DialogAssetPreviewComponent, {
      data: {
        content: this.content,
        url: this.url
      }
    });
  }

  public needToSave() {
    this.saveDisabled = !(this.content === null || this.content.name != this.name);
  }
}
