import {Injectable} from '@angular/core';
import {GraphService} from '../../network/graph.service';
import gql from 'graphql-tag';
import IContent = AES.IContent;
import IAsset = AES.IAsset;
import {FormError} from '../../../util/form/form-error.model';
import IContentTypes = AES.IContentTypes;
import {UploadEvent} from 'ngx-file-drop';
import ContentType = AES.ContentType;

@Injectable()
export class ContentsService {

  public temporaryUpload: UploadEvent = null;

  constructor(public graph: GraphService) {
  }

  // UTILITY METHODS
  public getMatIconNameFromType(type: ContentType | any) {
    switch (type) {
      case ContentType.ARCHIVE:
        return 'newarchive';
      case ContentType.VIDEO:
        return 'local_movies';
      case ContentType.IMAGE:
        return 'photo';
      case ContentType.URL:
        return 'linknew';
      case ContentType.PICTOGRAM:
        return 'pictogram';
      default:
        return 'error';
    }
  }

  // QUERIES
  public getContents(): Promise<IContent[]> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: contents,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.getContents'
        },
        fetchPolicy: 'network-only',
        variables: {
          slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getContents);
        },
        error => {
          reject();
        });
    });
  }

  public getContent(id: String): Promise<IContent> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: content,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.getContent'
        },
        variables: {
          customer_slug: this.graph.getCurrentCustomer(),
          content_slug: id
        }
      }).subscribe(
        response => {
          resolve(response.data.getContent);
        },
        error => {
          reject();
        });
    });
  }

  public getAsset(id: String): Promise<IAsset> {
    return new Promise<IAsset>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: asset,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.getAsset'
        },
        fetchPolicy: 'network-only',
        variables: {
          customer_slug: this.graph.getCurrentCustomer(),
          asset_slug: id
        }
      }).subscribe(
        response => {
          resolve(response.data.getAsset);
        });
    });
  }

  // getAssetContentTypes

  public getAssetContentTypes(): Promise<IContentTypes[]> {
    return new Promise<IContentTypes[]>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: assetsContentTypes,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.getAssetContentTypes'
        }
      }).subscribe(
        response => {
          resolve(response.data.getAssetContentTypes);
        },
        error1 => {
          reject();
        });
    });
  }

  public createContent(name: string, type: string, url: string): Promise<IContent> {
    return new Promise<IContent>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: createContent,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.createContent'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          cci: {
            name: name,
            type: type,
            external_url: url
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.createContent);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public updateContent(slug: string, name: string, url: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updateContent,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.updateContent'
        },
        variables: {
          cus_s: this.graph.getCurrentCustomer(),
          con_s: slug,
          uci: {
            name: name,
            external_url: url
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updateContent.slug);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public deleteContent(cont: IContent, force?: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deleteContent,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.deleteContent'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          co: cont.slug,
          force: force
        }
      }).subscribe(
        response => {
          resolve(response.data.deleteAsset);
        },
        error1 => {
          reject();
        });
    });
  }

  public deleteAsset(asset: IAsset): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deleteAsset,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.deleteAsset'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          as: asset.slug
        }
      }).subscribe(
        response => {
          resolve(response.data.deleteAsset);
        },
        error1 => {
          reject();
        });
    });
  }

  public getTags(): Promise<string[]> {
    return new Promise<string[]>((resolve, reject) => {
      this.graph.getApollo().query<string[]>({
        query: getTags,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.getTags'
        },
        variables: {
          customer_slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve((response.data as any).getTags);
        },
        error => {
          reject();
        }
      );
    });
  }

  public addBalkTags(contentsSlug: string[], tags: string[]): Promise<IContent[]> {
    return new Promise<IContent[]>((resolve, reject) => {
      this.graph.getApollo().mutate<IContent[]>({
        mutation: addBalkTags,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.addBalkTags'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          contents_slug: contentsSlug,
          uci: {
            tags_add: tags
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updateContents);
        },
        error => {
          reject(error);
        });
    });
  }

  public deleteBalk(contentsSlug: string[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deleteBalk,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.deleteBalk'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          contents_slug: contentsSlug,
        }
      }).subscribe(
        response => {
          resolve(response.data.deleteContents);
        },
        error => {
          reject(error);
        });
    });
  }

  public deleteBalkTag(contentSlug: string, tags: string[]): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deleteTag,
        context: {
          uri: this.graph.getGraphqlUrl() + '?ContentsService.deleteBalkTag'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          content_slug: contentSlug,
          uci: {
            tags: tags
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updateContent);
        },
        error => {
          reject(error);
        });
    });
  }


}

const contents = gql`
  query getContents($slug: String!){
    getContents(customer_slug: $slug){
      slug
      name
      type
      updated_at
      is_used
      tags
      assets{
        size
        is_ready
        ratio_string
        resolution_class
        path
        expanded_path
      }
    }
  }
`;

const content = gql`
  query getContent($customer_slug: String!, $content_slug: String!){
    getContent(customer_slug: $customer_slug, content_slug: $content_slug){
      slug
      name
      type
      assets{
        slug
        name
        size
        height
        width
        resolution_class
        ratio_string
        has_error
        is_uploaded
        is_ready
        is_processed
        path
        expanded_path
        created_at
        updated_at
      }
      is_active
      created_at
      updated_at
      external_url
    }
  }
`;

const asset = gql`
  query getAsset($customer_slug: String!, $asset_slug: String!){
    getAsset(customer_slug: $customer_slug, asset_slug: $asset_slug){
      slug
      name
      model{
        slug
      }
    }
  }
`;

const assetsContentTypes = gql`
  query {
    getAssetContentTypes{
      key
      values
    }
  }
`;

const getTags = gql`
  query getTags ($customer_slug: String!){
    getTags(customer_slug: $customer_slug)
  }
`;

const createContent = gql`
  mutation ($cs: String!, $cci: CreateContentInput!){
    createContent(customer_slug: $cs, input: $cci){
      slug
    }
  }
`;

const updateContent = gql`
  mutation ($cus_s: String!,$con_s: String!, $uci: UpdateContentInput!){
    updateContent(customer_slug: $cus_s,content_slug:$con_s , input: $uci){
      slug
    }
  }
`;

const deleteContent = gql`
  mutation ($cs: String!, $co: String!, $force: Boolean){
    deleteContent(customer_slug: $cs, content_slug: $co, force: $force)
  }
`;


const deleteAsset = gql`
  mutation ($cs: String!, $as: String!){
    deleteAsset(customer_slug: $cs, asset_slug: $as)
  }
`;

const addBalkTags = gql`
  mutation ($cs: String!, $contents_slug: [String]!, $uci: UpdateContentsInput!){
    updateContents(customer_slug: $cs, contents_slug: $contents_slug, input: $uci){
      slug
      tags
    }
  }
`;

const deleteBalk = gql`
  mutation ($cs: String!, $contents_slug: [String]!){
    deleteContents(customer_slug: $cs, contents_slug: $contents_slug, force: false)
  }
`;

const deleteTag = gql`
  mutation ($cs: String!, $content_slug: String!, $uci: UpdateContentInput!){
    updateContent(customer_slug: $cs, content_slug: $content_slug, input: $uci){
      slug
    }
  }
`;
