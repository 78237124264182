import gql from 'graphql-tag';

export class MutationModel {

  login = gql`
    mutation login($login: LoginInput!){
      login(input: $login){
        access_token
        refresh_token
        token_type
        expires_in
        expires_at
      }
    }
  `;

  readNotification = gql`
    mutation readNotification($mrni: MarkAsReadNotificationsInput!){
      markAsReadNotifications(input: $mrni)
    }
  `;

  refreshToken = gql`
    mutation refreshToken($rti: RefreshTokenInput!){
      refreshToken(input: $rti){
        access_token
        refresh_token
        token_type
        expires_in
        expires_at
      }
    }
  `;
}
