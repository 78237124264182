import {NgModule} from '@angular/core';

import {FuseQuickPanelComponent} from 'app/main/layout/layout-component/quick-panel/quick-panel.component';
import {SharedModule} from '../../../../shared/shared.module';
import {QuickPanelService} from './quick-panel.service';

@NgModule({
  declarations: [
    FuseQuickPanelComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    FuseQuickPanelComponent
  ],
  providers: [
    QuickPanelService
  ]
})
export class FuseQuickPanelModule {
}
