import {AfterViewInit, ApplicationRef, Component, ComponentFactoryResolver, EmbeddedViewRef, HostListener, Injector, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {MatDialog, MatSelectChange, MatSidenav, MatSnackBar} from '@angular/material';
import {PlaylistsService} from '../playlists.service';
import IPlaylist = AES.IPlaylist;
import {PlStepComponent} from './pl/pl-step/pl-step.component';
import {PlContentComponent} from './pl/pl-content/pl-content.component';
import {PlContentModel} from './pl/model/pl-content.model';
import {PlStepModel} from './pl/model/pl-step.model';
import {PlSurfaceModel} from './pl/model/pl-surface.model';
import {PlManager} from './pl/pl-manager';
import * as interact from 'interactjs';
import * as _ from 'lodash';
import IContent = AES.IContent;
import {AesysFormControl} from '../../../../util/form/AesysFormControl';
import {Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import IImplementation = AES.IImplementation;
import IStep = AES.IStep;
import {PlLayoutDialogComponent} from './pl/pl-layout-dialog/pl-layout-dialog.component';
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import {isUndefined} from 'util';
import {TimePipe} from '../../../../shared/util/time.pipe';
import {PlTransitionDialogComponent} from './pl/pl-transition-dialog/pl-transition-dialog.component';
import {PlPreviewDialogComponent} from './pl-preview-dialog/pl-preview-dialog.component';
import {PlSurfaceComponent} from './pl/pl-surface/pl-surface.component';
import {environment} from '../../../../../environments/environment';
import {IoModel} from '../../../network/data/io.model';
import PlaylistType = AES.PlaylistType;
import ContentType = AES.ContentType;
import SortDirection = AES.SortDirection;
import {ContentTypeEnum, PlaylistTypeEnum} from '../../../network/interface/graph-enum.model';
import {StepIoModel} from '../../../network/data/step-io.model';

const CONTENT_PLACEHOLDER_WIDTH = 80;
const SHADOW_STEP_WIDTH = 60;
const STEP_PLACEHOLDER_WIDTH = 120;
const SEARCH_BTN_EXPANDED = 40;
const SEARCH_BTN_COLLAPSED = 25;

@Component({
  selector: 'aesys-playlist-detail',
  templateUrl: './playlist-detail.component.html',
  styleUrls: ['./playlist-detail.component.scss']
})

export class PlaylistDetailComponent implements OnDestroy, AfterViewInit, OnInit {

  // view
  @ViewChild('plCardContainer', {static: false}) plCardContainer;
  @ViewChild('steps', {static: false}) viewSteps;
  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;
  @ViewChildren('stepInsert', {read: ViewContainerRef}) stepInsert: QueryList<ViewContainerRef>;
  @ViewChildren('contentTemporary', {read: ViewContainerRef}) contentTemporary: QueryList<ViewContainerRef>;
  @ViewChildren('contentInsert', {read: ViewContainerRef}) contentInsert: QueryList<ViewContainerRef>;
  loader: any;
  viewInited = false;

  contentType = ContentTypeEnum;
  playlistType = PlaylistTypeEnum;
  contentListTypeFilter = [false, false, false, false];
  btnSearchFlex = SEARCH_BTN_EXPANDED;
  contentSearchActive = false;
  contentsSearchText = '';
  window = window;

  // road filter
  avaiableReseloutions: [number, number, string][] = [];

  // form
  needSave = true;
  name = '';
  selectedPlaylistType: PlaylistType;
  color = '#aeea00';
  nameFormControll = new AesysFormControl('name', this.translateService, '', [
    Validators.required
  ]);
  typeFormControll = new AesysFormControl('type', this.translateService, '', [
    Validators.required
  ]);
  playlistTypeDisabled = true;

  // data
  playlist: IPlaylist = null;
  currentType: PlaylistType;
  implementation: IImplementation = null;
  contents: IContent[] = [];
  filteredContents: IContent[] = [];
  plContentList: PlContentModel[] = [];

  // pl
  plManager = new PlManager();

  placeholder = document.createElement('div');
  totalTime = '0';

  constructor(
    private route: ActivatedRoute,
    private plService: PlaylistsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private translateService: TranslateService,
    private router: Router,
    private timePipe: TimePipe,
    private appRef: ApplicationRef,
    private injector: Injector,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public errorBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.initData();
  }

  // LOAD DATA

  generateRoadFilter() {
    for (const c of this.contents) {
      if (c.assets.length > 0 && c.assets[0].is_ready && this.playlist.type === PlaylistType.ROAD) {
        const asset = c.assets[0];
        let add = true;
        for (const res of this.avaiableReseloutions) {
          if (res[0] === asset.width && res[1] === asset.height) {
            add = false;
          }
        }
        if (add) {
          this.avaiableReseloutions.push([asset.width, asset.height, asset.width + ' x ' + asset.height]);
        }
      }
    }
  }

  showFilteredContent() {
    this.clearContentList();
    this.filteredContents = Object.assign([], this.contents);
    this.plContentList = [];
    for (const c of this.filteredContents) {
      this.generateContentList(c);
    }
  }

  private initNewPlaylist() {
    this.plService.getAvaiableLayoutType()
      .then(type => {
        if (type.length > 1) {
          this.playlistTypeDisabled = false;
        } else {
          this.playlistTypeDisabled = true;
          this.selectedPlaylistType = type[0];
        }
      });
  }

  private initData() {
    if (!isUndefined(this.stepInsert.first)) {
      this.stepInsert.first.clear();
    }
    if (!isUndefined(this.contentInsert.first)) {
      // OLD: this.contentInsert.first.clear();
      this.clearContentList();
    }
    const id = this.route.snapshot.params.playlistId;
    if (id === 'new' || id === 'NEW') {
      this.initNewPlaylist();
    } else {
      setTimeout(() => {
        this.getPlaylist(id);
      }, 1);
    }

    setTimeout(() => {
      this.viewInited = true;
    }, 1);
  }

  private initUI() {
    this.name = this.playlist.name;
    this.nameFormControll.setValue(this.playlist.name);
    this.typeFormControll.setValue(this.playlist.type);
    this.color = this.playlist.color;
    this.plManager.plSteps = [];
    this.stepInsert.first.clear();
    this.initImplementation();
    for (const s of this.implementation.steps) {
      this.addStepComponent(s);
    }
    if (this.plManager.plSteps.length === 0) {
      this.addStep();
    }
    this.needToSave();
  }

  private getPlaylist(id) {
    this.loader = LoadingComponent.showLoading(this.dialog);
    this.plService.getPlaylist(id)
      .then(playlist => {
        this.playlist = playlist;
        this.currentType = playlist.type;
        this.selectedPlaylistType = playlist.type;
        this.plManager.setType(this.playlist.type);
        // per ora esiste solo l'implementation base e si chiama sempre default
        for (const i of playlist.implementations) {
          if (i.ratio.name === 'default') {
            this.plService.getImplementation(i.slug)
              .then(implementation => {
                this.implementation = implementation;
                LoadingComponent.hideLoading(this.loader);
                this.initUI();
                this.plService.getContents()
                  .then(contents => {
                    const cs: IContent[] = [];
                    for (const c of contents) {
                      if ((this.playlist.type === PlaylistType.ROAD && c.type === ContentType.PICTOGRAM) || (this.playlist.type === PlaylistType.TFT && c.type !== ContentType.PICTOGRAM)) {
                        cs.push(c);
                      }
                    }
                    this.contents = cs;
                    this.generateRoadFilter();
                    this.showFilteredContent();
                  });
              })
              .catch(error => {
                console.log('error1');
                console.log(error);
                this.manageNetworkError();
              });
          }
        }
      })
      .catch(error => {
        console.log('error2');
        console.log(error);
        this.manageNetworkError();
      });
  }

  public manageNetworkError() {
    this.router.navigateByUrl('/customer/' + this.plService.graph.getCurrentCustomer() + '/playlists');
    this.errorBar.open(this.translateService.instant('PLAYLIST_D.ERR_GET_PLAYLIST'), '', {
      duration: 1000
    });
  }

  // STEP

  addStep(plStep?: PlStepModel) {
    const dialogRef = this.dialog.open(PlLayoutDialogComponent, {
      width: '800px',
      maxHeight: (0.9 * window.innerHeight) + 'px',
      disableClose: this.plManager.plSteps.length === 0,
      data: {
        playlistType: this.playlist.type
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.lSlug) {
        this.loader = LoadingComponent.showLoading(this.dialog);
        this.plService.createStep(this.implementation.slug, result.lSlug, plStep)
          .then(step => {
            this.addStepComponent(step, plStep);
            LoadingComponent.hideLoading(this.loader);
          })
          .catch(error => {
            this.initData();
            LoadingComponent.hideLoading(this.loader);
            this.errorBar.open(this.translateService.instant('PLAYLIST_D.ERR_ADD_STEP'), '', {
              duration: 1000
            });
          });
      }
    });
  }

  private addStepComponent(s: IStep, plRelatedStep?: PlStepModel) {
    const plStep = this.componentFactoryResolver.resolveComponentFactory(PlStepComponent);
    const cRef = plStep.create(this.injector);
    this.stepInsert.first.insert(cRef.hostView);

    const dComponent = <PlStepComponent>cRef.instance;
    const step: PlStepModel = new PlStepModel();
    step.order = isUndefined(plRelatedStep) ? s.order : (plRelatedStep.order + 1);
    step.cRef = cRef;
    step.step = _.clone(s);
    this.plManager.plSteps.push(step);
    dComponent.plManager = this.plManager;
    dComponent.step = step;
    dComponent.order = step.order;
    dComponent.ready.subscribe(() => {
      this.plManager.reorderStep(step);
      setTimeout(() => {
        // temporaneo
        this.updateTotalTime();
        this.updateOutputList();
      }, 50);
    });
    dComponent.add.subscribe(plStep => this.addStep(plStep));
    dComponent.changeTransition.subscribe(plStep => {
      const dialogRef = this.dialog.open(PlTransitionDialogComponent, {
        data: {
          transitionSlug: step.step.transition !== null ? step.step.transition.slug : ''
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.save) {
          this.loader = LoadingComponent.showLoading(this.dialog);
          this.plService.updateStepTransition(plStep.step.slug, result.newTransitionSlug)
            .then(newStep => {
              LoadingComponent.hideLoading(this.loader);
              step.step = newStep;
              this.updateTotalTime();
            })
            .catch(error => {
              this.initData();
              this.errorBar.open(this.translateService.instant('PLAYLIST_D.ERR_UPDATE_TRANSITION'), '', {
                duration: 1000
              });
            });
        }
      });
    });
    dComponent.deleteContent.subscribe(plContent => {
      this.deleteContent(plContent);
    });
    dComponent.updatedContent.subscribe(plContent => {
      this.plCardContainer.nativeElement.scrollTo(plContent.cRef.instance.elRef.nativeElement.offsetLeft, this.plCardContainer.nativeElement.scrollTop);
      this.updateTotalTime();
    });
    dComponent.delete.subscribe(plStep => {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          message: this.translateService.instant('PLAYLIST_D.DIALOG_DEL_STEP_MESSAGE'),
          positiveButton: this.translateService.instant('PLAYLIST_D.DIALOG_CONFIRM'),
          negativeButton: this.translateService.instant('PLAYLIST_D.DIALOG_UNDO')
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.loader = LoadingComponent.showLoading(this.dialog);
          this.plService.deleteStep(plStep.step.slug)
            .then(success => {
              if (this.plManager.plSteps.indexOf(plStep) > -1) {
                this.plManager.plSteps.splice(this.plManager.plSteps.indexOf(plStep), 1);
              }
              LoadingComponent.hideLoading(this.loader);
              plStep.cRef.destroy();
              if (this.plManager.plSteps.length == 0) {
                this.addStep();
              }
              this.updateTotalTime();
              this.updateOutputList();
            })
            .catch(error => {
              this.initData();
              this.errorBar.open(this.translateService.instant('PLAYLIST_D.ERR_DELETE_STEP'), '', {
                duration: 1000
              });
            });
        }
      });
    });
  }

  // CONTENT LIST

  private clearContentList() {
    this.contentInsert.first.clear();
  }

  private generateContentList(content: IContent) {
    const plC = this.componentFactoryResolver.resolveComponentFactory(PlContentComponent);
    const cRef = plC.create(this.injector);
    this.contentInsert.first.insert(cRef.hostView);
    // OLD: const cRef = this.contentInsert.first.createComponent(plC);
    const dComponent = <PlContentComponent>cRef.instance;
    const plContent: PlContentModel = new PlContentModel(content);
    dComponent.plManager = this.plManager;
    dComponent.content = plContent;
    plContent.cRef = cRef;
    dComponent.setWidth();
    this.plContentList.push(plContent);
  }

  // FORM

  public savePlaylist() {
    if (this.name !== '' && this.selectedPlaylistType) {
      console.log('1st condition valid');
      this.loader = LoadingComponent.showLoading(this.dialog);
      if (this.playlist === null) {
        this.plService.createPlaylist(this.name, this.color, this.selectedPlaylistType)
          .then(id => {
            LoadingComponent.hideLoading(this.loader);
            this.router.navigateByUrl('/customer/' + this.plService.graph.getCurrentCustomer() + '/playlists/' + id, {replaceUrl: true});
            this.getPlaylist(id);
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.nameFormControll.setCustomError(error);
          });
      } else {
        this.plService.updatePlaylist(this.playlist.slug, this.name, this.color, this.playlist.is_draft)
          .then(id => {
            LoadingComponent.hideLoading(this.loader);
            this.router.navigateByUrl('/customer/' + this.plService.graph.getCurrentCustomer() + '/playlists/' + id, {replaceUrl: true});
            this.getPlaylist(id);
            this.snackBar.open(this.translateService.instant('PLAYLIST_D.PLAYLIST_UPDATED'), '', {
              duration: 1000,
            });
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.nameFormControll.setCustomError(error);
          });
      }
    }
  }

  public publishPlaylist() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        message: 'Pubblicare playlist?',
        positiveButton: 'SI',
        negativeButton: 'ANNULLA'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loader = LoadingComponent.showLoading(this.dialog);
        this.plService.updatePlaylist(this.playlist.slug, this.playlist.name, this.playlist.color, false)
          .then(id => {
            LoadingComponent.hideLoading(this.loader);
            this.getPlaylist(id);
            this.snackBar.open('contenuto aggiornato', '', {
              duration: 1000,
            });
            // this.router.navigateByUrl('/customer/' + this.plService.graph.getCurrentCustomer() + '/playlists/' + id, {replaceUrl: true});
            this.router.navigateByUrl('/customer/' + this.plService.graph.getCurrentCustomer() + '/playlists');
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.nameFormControll.setCustomError(error);
          });
      }
    });
  }

  public openPreview() {
    if (this.playlist.type === PlaylistType.TFT) {
      this.dialog.open(PlPreviewDialogComponent, {
        width: '400px',
        data: {
          cs: this.plService.graph.getCurrentCustomer(),
          ps: this.playlist.slug,
        }
      });
    } else {
      let width = 0;
      let height = 0;
      for (const s of this.implementation.steps[0].layout.surfaces) {
        const swidth = s.x + s.width;
        const sheight = s.y + s.height;
        width = swidth > width ? swidth : width;
        height = sheight > height ? sheight : height;
      }

      let paddingTop = 22; // remove in firefox
      if (window.navigator.userAgent.indexOf('Firefox') !== -1) {
        paddingTop = 0;
      }
      const myWindow = window.open(environment.infotainmentITPlayerPath + '/#/player?' + 'customer=' + this.plService.graph.getCurrentCustomer() + '&playlist=' + this.playlist.slug, '_blank',
        'width=' + (width * 3) + ', height=' + ((height * 3) + paddingTop));
      myWindow.focus();
    }
  }

  // LOAD IMPLEMENTATION

  setScale(scale) {
    this.plManager.scale = scale;
  }

  private initImplementation() {

    const that = this;

    interact('.step-dropzone')
      .dropzone({
        accept: '.step-draggable',
        overlap: 0.001,
        ondropmove: function (event) {
          if (event.relatedTarget.classList.contains('pl-step')) {
            const elementPosition = event.target.getBoundingClientRect();
            const dropzonePostion = event.relatedTarget.getBoundingClientRect();
            if ((event.relatedTarget.clientWidth / 2) > (dropzonePostion.left - elementPosition.left)) {
              event.target.style.marginLeft = STEP_PLACEHOLDER_WIDTH + 'px';
            }
          }
        },
        ondrop: function (event) {
          event.target.style.marginLeft = '0px';
        },
        ondragleave: function (event) {
          event.target.style.marginLeft = '0px';
        }
      });

    interact('.step-draggable')
      .draggable({
        // @ts-ignore
        ignoreFrom: 'button',
        inertia: true,
        restrict: {
          endOnly: true,
          elementRect: {top: 0, left: 0, bottom: 1, right: 1}
        },
        autoScroll: true,
        onstart: event => {

          const step = that.plManager.getStepFromId(event.target.id);
          const target = event.target;
          const ml = event.interaction.startOffset.left - (SHADOW_STEP_WIDTH / 2);

          step.moved = true;

          // that.placeholder.style.width = target.clientWidth + 'px';

          that.placeholder.style.order = '' + step.order;

          (<PlStepComponent>step.cRef.instance).setFixedPos();

          target.classList.remove('pl-step-static');
          target.classList.add('pl-step-fixed');
          target.parentNode.parentNode.appendChild(that.placeholder);
          target.style.marginLeft = ml + 'px';
          target.style.width = SHADOW_STEP_WIDTH + 'px';
          target.style.maxWidth = SHADOW_STEP_WIDTH + 'px';
        },
        onmove: event => {
          const target = event.target;
          const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

          target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        },
        onend: event => {
          const step = that.plManager.getStepFromId(event.target.id);
          that.placeholder.remove();
          (<PlStepComponent>step.cRef.instance).setStaticPos();
          event.target.classList.remove('pl-step-fixed');
          event.target.classList.add('pl-step-static');
          event.target.style.maxWidth = 'none';
          event.target.style.width = 'auto';
          event.target.style.marginRight = '0px';
          event.target.style.marginLeft = '0px';

          // set position & order
          if (event.relatedTarget != null && event.relatedTarget.classList.contains('pl-step')) {
            const relatedStep = that.plManager.getStepFromId(event.relatedTarget.id);
            const direction = Number(event.relatedTarget.style.marginLeft.replace('px', '')) > 0 ? SortDirection.BEFORE : SortDirection.AFTER;
            step.order = Number(event.relatedTarget.style.marginLeft.replace('px', '')) > 0 ? relatedStep.order : (relatedStep.order + 1);

            that.plManager.reorderStep(step);

            that.loader = LoadingComponent.showLoading(that.dialog);
            that.plService.updateStepPosition(step.step, relatedStep.step, direction)
              .then(success => {
                LoadingComponent.hideLoading(that.loader);
              })
              .catch(error => {
                this.initData();
                this.errorBar.open(this.translateService.instant('PLAYLIST_D.ERR_UPDATE_STEP_POSITION'), '', {
                  duration: 1000
                });
              });
          }

          event.target.style.transform = 'translate(0px, 0px)';
          event.target.setAttribute('data-x', '0');
          event.target.setAttribute('data-y', '0');
        }
      });

    interact('.content-dropzone')
      .dropzone({
        accept: '.content-draggable',
        overlap: 0.0001,
        ondropmove: function (event) {
          if (event.relatedTarget.classList.contains('pl-content')) {
            const elementPosition = event.target.getBoundingClientRect();
            const dropzonePostion = event.relatedTarget.getBoundingClientRect();
            if ((event.relatedTarget.clientWidth / 2) > (dropzonePostion.left - elementPosition.left)) {
              event.target.style.marginLeft = CONTENT_PLACEHOLDER_WIDTH + 'px';
            }
          }
        },
        ondrop: function (event) {
          event.target.style.marginLeft = '0px';
        },
        ondragleave: function (event) {
          event.target.style.marginLeft = '0px';
        }
      });

    interact('.surface-dropzone')
      .dropzone({
        accept: '.content-draggable',
        overlap: 0.0001
      });

    interact('.content-draggable')
      .draggable({
        inertia: true,
        restrict: {
          endOnly: true,
          elementRect: {top: 0, left: 0, bottom: 1, right: 1}
        },
        autoScroll: true,
        onstart: event => {
          if (that.sidenav.mode === 'over') {
            that.sidenav.close();
          }
          const target = event.target;
          const content = that.getContentFromId(target.id);
          const rect = target.getBoundingClientRect();

          if (content.surfaceId === null) {
            that.generateContentList(content.content);
          }

          if (content.surfaceId === null) {
            that.contentInsert.first.detach(that.contentInsert.first.indexOf(content.cRef.hostView));
            that.contentTemporary.first.insert(content.cRef.hostView);
          }

          const ml = event.interaction.startOffset.left - (PlContentModel.SHADOW_CONTENT_WIDTH / 2);

          that.placeholder.style.width = target.style.width;
          that.placeholder.style.minWidth = target.style.width;
          that.placeholder.style.order = '' + content.order;

          content.style = 'pl-content-shadow';

          const step = that.plManager.getStepFromContentId(content.id);
          if (step !== null) {
            (<PlStepComponent>step.cRef.instance).zIndex = '99';
          }

          if (content.surfaceId !== null) {
            (<PlContentComponent>content.cRef.instance).setFixedPos(step ? step.moved : false);
            target.parentNode.parentNode.appendChild(that.placeholder);
          } else {
            (<PlContentComponent>content.cRef.instance).setFixedListPos(rect);
          }
          target.setAttribute('data-x', ml);
          target.style.transition = 'none';
          target.style.width = PlContentModel.SHADOW_CONTENT_WIDTH + 'px';

        },
        onmove: event => {
          const target = event.target;
          const x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
          const y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

          target.style.webkitTransform = target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';
          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        },
        onend: event => {
          const content = that.getContentFromId(event.target.id);
          let direction = null;
          let oldSurfaceId: string = null;

          that.placeholder.remove();
          (<PlContentComponent>content.cRef.instance).setStaticPos();

          const step = that.plManager.getStepFromContentId(content.id);
          if (step !== null) {
            (<PlStepComponent>step.cRef.instance).zIndex = '98';
          }
          event.target.style.transition = 'margin 200ms';
          event.target.style.marginRight = '0px';
          event.target.style.marginLeft = '0px';


          event.target.style.width = content.getWidth(that.plManager);

          // detach
          if (content.surfaceId !== null) {
            oldSurfaceId = content.surfaceId;
            const parent = that.plManager.getSurfaceFromId(content.surfaceId).containerRef;
            parent.detach(parent.indexOf(content.cRef.hostView));
          } else {
            that.contentTemporary.first.detach(that.contentTemporary.first.indexOf(content.cRef.hostView));
          }

          // add view
          if (event.relatedTarget != null &&
            (event.relatedTarget.classList.contains('pl-content') ||
              (event.relatedTarget.classList.contains('surface-dropzone') && event.relatedTarget.id != content.surfaceId))
          ) {

            let relatedContent: PlContentModel = null;
            let surface: PlSurfaceModel;

            if (event.relatedTarget.classList.contains('pl-content')) {
              relatedContent = that.getContentFromId(event.relatedTarget.id);
              direction = Number(event.relatedTarget.style.marginLeft.replace('px', '')) > 0 ? SortDirection.BEFORE : SortDirection.AFTER;
              const position = Number(event.relatedTarget.style.marginLeft.replace('px', '')) > 0 ? relatedContent.order : (relatedContent.order + 1);
              content.surfaceId = relatedContent.surfaceId;
              content.order = position;
              surface = that.plManager.getSurfaceFromId(content.surfaceId);
            } else if (event.relatedTarget.classList.contains('surface-dropzone') && event.relatedTarget.id != content.surfaceId) {
              content.surfaceId = event.relatedTarget.id;
              content.order = 10000;
              surface = that.plManager.getSurfaceFromId(event.relatedTarget.id);
            }

            // save
            if (oldSurfaceId === null) { // create stepItem
              surface.plContents.push(content);
              that.loader = LoadingComponent.showLoading(that.dialog);
              that.plService.createStepItem(
                that.plManager.getStepFromSurfaceId(surface.id).step.slug,
                surface.surface.slug,
                content.content,
                relatedContent !== null ? relatedContent.stepItem : null,
                direction)
                .then(stepItem => {
                  content.stepItem = stepItem;
                  (<PlSurfaceComponent>surface.cRef.instance).init(<PlContentComponent>content.cRef.instance);
                  LoadingComponent.hideLoading(that.loader);
                })
                .catch(error => {
                  this.initData();
                  this.errorBar.open(this.translateService.instant('PLAYLIST_D.ERR_CREATE_STEP_ITEM_POSITION'), '', {
                    duration: 1000
                  });

                });
            } else { // update stepItem
              if (surface.id !== oldSurfaceId) {
                const oldSurface = that.plManager.getSurfaceFromId(oldSurfaceId);
                oldSurface.plContents.splice(oldSurface.plContents.indexOf(content), 1);
                surface.plContents.push(content);
              }
              that.loader = LoadingComponent.showLoading(that.dialog);
              that.plService.updateStepItemPosition(
                content.stepItem,
                that.plManager.getStepFromSurfaceId(surface.id).step.slug,
                surface.surface.slug,
                relatedContent !== null ? relatedContent.stepItem : null,
                direction)
                .then(success => {
                  content.stepItem = success;
                  LoadingComponent.hideLoading(that.loader);
                })
                .catch(error => {
                  this.initData();
                  this.errorBar.open(this.translateService.instant('PLAYLIST_D.ERR_UPDATE_STEP_ITEM_POSITION'), '', {
                    duration: 1000
                  });
                });
            }
            const s = that.plManager.getStepFromContentId(content.id);
            if (s !== null) {
              s.step.stepitems = [];
              for (const surf of s.surfaces) {
                const su = that.plManager.getSurfaceFromId(surf.id);
                for (const si of su.plContents) {
                  s.step.stepitems.push(si.stepItem);
                }
              }
              (<PlStepComponent>s.cRef.instance).step = s;
            }

            let list = _.sortBy(surface.plContents, ['order']);
            for (let i = 0; i < list.length; i++) {
              if (list[i].id !== content.id) {
                if (list[i].order >= content.order) {
                  list[i].order = list[i].order + 1;
                }
              }
            }
            list = _.sortBy(list, ['order']);
            for (let i = 0; i < list.length; i++) {
              list[i].order = i;
              (<PlContentComponent>list[i].cRef.instance).order = i;
            }
          }
          // add
          if (content.surfaceId !== null) {
            content.style = that.plManager.getSurfaceFromId(content.surfaceId).style;
            that.plManager.getSurfaceFromId(content.surfaceId).containerRef.insert(content.cRef.hostView);
            event.target.style.transform = 'translate(0px, 0px)';
            event.target.setAttribute('data-x', '0');
            event.target.setAttribute('data-y', '0');
          }
          // remove
          else {
            that.contentInsert.first.detach(that.contentInsert.first.indexOf(content.cRef.hostView));
          }
          that.updateTotalTime();
        }
      })
      .resizable({
        edges: {left: false, right: true, bottom: false, top: false},
        inertia: true,
      })
      .on('resizemove', function (event: any) {
        const content = that.getContentFromId(event.target.id);
        if (content !== null && content.surfaceId !== null) {
          (<PlContentComponent>content.cRef.instance).resizing = true;
          if (content.content.type !== ContentType.VIDEO) {
            const duration = event.rect.width / that.plManager.scale;
            if (duration >= PlContentModel.MIN_DURATION) {
              content.duration = duration;
            }
          }
        }
      })
      .on('resizeend', function (event: any) {
        const content = that.getContentFromId(event.target.id);
        if (content !== null && content.surfaceId !== null) {
          (<PlContentComponent>content.cRef.instance).resizing = false;
          if (content.content.type !== ContentType.VIDEO) {
            that.loader = LoadingComponent.showLoading(that.dialog);
            that.plService.updateStepItemDuration(
              content.stepItem,
              content.duration
            ).then(success => {
              LoadingComponent.hideLoading(that.loader);
            }).catch(error => {
              console.log(error);
              that.initData();
              LoadingComponent.hideLoading(that.loader);
              that.errorBar.open(that.translateService.instant('PLAYLIST_D.ERR_GET_CONTENT'), '', {
                duration: 1000
              });
            });
            that.updateTotalTime();
          }
        }
      });
  }

  // UTIL

  public getContentFromId(id): PlContentModel {
    for (const step of this.plManager.plSteps) {
      for (const surface of step.surfaces) {
        for (const c of surface.plContents) {
          if (c.id == id) {
            return c;
          }
        }
      }
    }

    for (const c of this.plContentList) {
      if (c.id == id) {
        return c;
      }
    }
    return null;
  }

  public updateTotalTime() {
    let totalTime = 0;
    for (const step of this.plManager.plSteps) {
      let maxSurfaceDuration = 0;
      for (const surface of step.surfaces) {
        let surfaceTime = 0;
        for (const c of surface.plContents) {
          surfaceTime += c.duration;
        }
        if (surfaceTime > maxSurfaceDuration) {
          maxSurfaceDuration = surfaceTime;
        }
      }
      let crossTransition = 0;
      if (step.step.transition !== null) {
        crossTransition = step.step.transition.duration;
      }

      const sur = maxSurfaceDuration > crossTransition ? (maxSurfaceDuration - crossTransition) : maxSurfaceDuration;

      totalTime = totalTime + sur;
    }
    this.totalTime = '' + this.timePipe.transform(totalTime);
  }

  public getPlContainerWidth(width: number): string {
    if (this.viewInited) {
      return width + 'px';
    } else {
      return '100px';
    }

  }

  private deleteContent(content: PlContentModel) {
    this.loader = LoadingComponent.showLoading(this.dialog);
    this.plService.deleteStepItem(content.stepItem.slug)
      .then(success => {
        LoadingComponent.hideLoading(this.loader);
        const st = this.plManager.getStepFromContentId(content.id);
        const s = this.plManager.getSurfaceFromId(content.surfaceId);
        s.plContents.splice(s.plContents.indexOf(content), 1);
        s.containerRef.detach(s.containerRef.indexOf(content.cRef.hostView));
        this.updateTotalTime();
        // consolidare i dati in modo migliore
        if (st !== null) {
          st.step.stepitems = [];
          for (const surf of st.surfaces) {
            const su = this.plManager.getSurfaceFromId(surf.id);
            for (const si of su.plContents) {
              st.step.stepitems.push(si.stepItem);
            }
          }
          (<PlStepComponent>s.cRef.instance).step = st;
        }
      })
      .catch(error => {
        this.errorBar.open(this.translateService.instant('PLAYLIST_D.ERR_DELETE_STEP_ITEM'), '', {
          duration: 1000
        });
      });
  }

  /* FILTERS SECTION */

  setContentTypeFilter(filter: ContentType) {
    switch (filter) {
      case ContentType.URL:
        this.contentListTypeFilter[0] = !this.contentListTypeFilter[0];
        break;
      case ContentType.ARCHIVE:
        this.contentListTypeFilter[1] = !this.contentListTypeFilter[1];
        break;
      case ContentType.IMAGE:
        this.contentListTypeFilter[2] = !this.contentListTypeFilter[2];
        break;
      case ContentType.VIDEO:
        this.contentListTypeFilter[3] = !this.contentListTypeFilter[3];
        break;
    }
    if (!this.contentListTypeFilter[0] && !this.contentListTypeFilter[1] && !this.contentListTypeFilter[2] && !this.contentListTypeFilter[3]) {
      this.filteredContents = Object.assign([], this.contents);
    } else {
      this.filteredContents = [];
      for (const content of this.contents) {
        switch (content.type) {
          case ContentType.URL:
            if (this.contentListTypeFilter[0]) {
              this.filteredContents.push(content);
            }
            break;
          case ContentType.ARCHIVE:
            if (this.contentListTypeFilter[1]) {
              this.filteredContents.push(content);
            }
            break;
          case ContentType.IMAGE:
            if (this.contentListTypeFilter[2]) {
              this.filteredContents.push(content);
            }
            break;
          case ContentType.VIDEO:
            if (this.contentListTypeFilter[3]) {
              this.filteredContents.push(content);
            }
            break;
        }
      }
    }

    this.clearContentList();
    for (const c of this.filteredContents) {
      this.generateContentList(c);
    }
  }

  enableSearch() {
    this.contentSearchActive = !this.contentSearchActive;
    if (this.contentSearchActive) {
      this.btnSearchFlex = SEARCH_BTN_COLLAPSED;
    } else {
      this.btnSearchFlex = SEARCH_BTN_EXPANDED;
      this.contentsSearchText = '';
    }

    if (!this.contentListTypeFilter[0] || !this.contentListTypeFilter[1] || !this.contentListTypeFilter[2] || !this.contentListTypeFilter[3]) {
      this.clearContentList();
      this.filteredContents = Object.assign([], this.contents);
      this.contentListTypeFilter = [false, false, false, false];
      for (const c of this.filteredContents) {
        this.generateContentList(c);
      }
    }
  }

  searchContents(event: any) {
    let filterValue = event.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches

    this.clearContentList();
    this.filteredContents = [];
    for (const content of this.contents) {
      if (content.name.trim().toLowerCase().indexOf(filterValue) != -1) {
        this.filteredContents.push(content);
      }
    }
    for (const c of this.filteredContents) {
      this.generateContentList(c);
    }
  }

  ngOnDestroy(): void {
    (interact('.step-dropzone') as any).unset();
    (interact('.step-draggable') as any).unset();
    (interact('.content-dropzone') as any).unset();
    (interact('.surface-dropzone') as any).unset();
    (interact('.content-draggable') as any).unset();
    (interact('.content-draggable') as any).unset();
  }

  /* /FILTERS SECTION */

  public needToSave() {
    if (this.playlist != null) {
      if (this.playlist.name != this.name || this.playlist.color !== this.color) {
        this.needSave = false;
      } else {
        this.needSave = true;
      }
    } else {
      this.needSave = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  @debounce()
  onResize(event) {
    LoadingComponent.hideLoading(this.loader);
    setTimeout(() => {
      this.viewInited = false;
      this.initData();
    }, 100);

  }

  roadFilterChange(matSelect: MatSelectChange) {
    if (matSelect.value) {
      const selectedWidth = matSelect.value[0];
      const selectedHeight = matSelect.value[1];
      const cs = [];
      for (const c of this.contents) {
        for (const a of c.assets) {
          if (a.height === selectedHeight && a.width === selectedWidth) {
            cs.push(c);
          }
        }
      }
      this.filteredContents = cs;
    } else {
      this.filteredContents = _.clone(this.contents);
    }
    this.clearContentList();
    for (const c of this.filteredContents) {
      this.generateContentList(c);
    }
  }

  private updateOutputList() {

    let maxSurface = 0;
    const allIoKey: string[] = [];
    for (const step of this.plManager.plSteps) {
      if (maxSurface < step.step.layout.surfaces.length) {
        maxSurface = step.step.layout.surfaces.length;
      }
      for (const io of IoModel.parse(step.step.layout.io)) {
        if (allIoKey.indexOf(io.key) === -1) {
          allIoKey.push(io.key);
        }
      }
      for (const io of StepIoModel.parse(step.step.io)) {
        let added = false;
        for (const key of allIoKey) {
          if (key === io.key) {
            added = true;
          }
        }
        if (!added) {
          allIoKey.push(io.key);
        }
      }
    }

    for (const step of this.plManager.plSteps) {
      (step.cRef.instance as PlStepComponent).updateOutputView(maxSurface, allIoKey);
    }

  }

}

export function debounce(delay: number = 300): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    let timeout = null;
    const original = descriptor.value;
    descriptor.value = function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => original.apply(this, args), delay);
    };
    return descriptor;
  };
}
