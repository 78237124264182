import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'enumCompare'
})
export class EnumComparePipe implements PipeTransform {

  transform(value: any, arg: any): any {
    return value == arg;
  }

}
