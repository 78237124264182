import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FuseConfigService} from '../../../../../@fuse/services/config.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {interval, Observable} from 'rxjs';

@Component({
  selector: 'aesys-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss']
})
export class MaintenancePageComponent implements OnInit, OnDestroy {

  endpoint = 'http://aesys-infotainment-engine.local';
  intervalSubscription = null;

  constructor(
    private translateService: TranslateService,
    private fuseConfig: FuseConfigService,
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });
  }

  ngOnInit() {
    // url rest api:
    // http://aesys-infotainment-engine.local
    this.statusProcedure();
  }

  statusProcedure() {
    this.intervalSubscription = interval(5000).subscribe((x) => {
      this.getServerStatus()
        .subscribe(res => {
          if (res.status === 200){
            this.router.navigateByUrl('/login', {replaceUrl: true});
            this.intervalSubscription.unsubscribe();
          }
        }, err => {
          // console.error('System under maintenance');
        });
    });
  }

  getServerStatus(): Observable<any>{
    return this.httpClient.get(this.endpoint, {responseType: 'text', observe: 'response' });
  }

  ngOnDestroy(): void {
    this.intervalSubscription.unsubscribe();
  }

}
