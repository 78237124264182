import {PlStepModel} from './model/pl-step.model';
import {PlSurfaceModel} from './model/pl-surface.model';
import {PlStepComponent} from './pl-step/pl-step.component';
import {PlContentModel} from './model/pl-content.model';
import * as _ from 'lodash';
import PlaylistType = AES.PlaylistType;

export class PlManager {

  scale = 1;
  plSteps: PlStepModel[] = [];
  private plType: PlaylistType;

  constructor() {
  }

  public getStepFromId(id): PlStepModel {
    for (const s of this.plSteps) {
      if (s.id == id) {
        return s;
      }
    }
    return null;
  }

  public getStepFromContentId(id): PlStepModel {
    for (const s of this.plSteps) {
      for (const surface of s.surfaces) {
        for (const c of surface.plContents) {
          if (c.id == id) {
            return s;
          }
        }
      }
    }
    return null;
  }

  public getStepFromSurfaceId(id): PlStepModel {
    for (const s of this.plSteps) {
      for (const surface of s.surfaces) {
        if (surface.id == id) {
          return s;
        }
      }
    }
    return null;
  }

  public getSurfaceFromId(id): PlSurfaceModel {
    for (const step of this.plSteps) {
      for (const surface of step.surfaces) {
        if (surface.id == id) {
          return surface;
        }
      }
    }
    return null;
  }

  public reorderStep(step: PlStepModel) {
    this.plSteps = _.sortBy(this.plSteps, ['order']);
    for (let i = 0; i < this.plSteps.length; i++) {
      if (this.plSteps[i].id !== step.id) {
        if (this.plSteps[i].order >= step.order) {
          this.plSteps[i].order = this.plSteps[i].order + 1;
        }
      }
    }
    this.plSteps = _.sortBy(this.plSteps, ['order']);
    for (let i = 0; i < this.plSteps.length; i++) {
      this.plSteps[i].order = i;
      (<PlStepComponent>this.plSteps[i].cRef.instance).order = i;
    }
  }

  setType(type: PlaylistType) {
    this.plType = type;
  }

  getType() {
    return this.plType;
  }
}
