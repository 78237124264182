import {Injectable} from '@angular/core';
import {GraphService} from '../../network/graph.service';
import gql from 'graphql-tag';
import IPlaylist = AES.IPlaylist;
import {FormError} from '../../../util/form/form-error.model';
import IContent = AES.IContent;
import IImplementation = AES.IImplementation;
import IStep = AES.IStep;
import ILayout = AES.ILayout;
import {PlStepModel} from './playlist-detail/pl/model/pl-step.model';
import {PlContentModel} from './playlist-detail/pl/model/pl-content.model';
import IStepitem = AES.IStepitem;
import {isUndefined} from 'util';
import ITransition = AES.ITransition;
import SortDirection = AES.SortDirection;
import ContentType = AES.ContentType;
import PlaylistType = AES.PlaylistType;

@Injectable()
export class PlaylistsService {

  constructor(public graph: GraphService) {
  }

  public getPlaylists(): Promise<IPlaylist[]> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: playlists,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.getPlaylists'
        },
        variables: {
          slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getPlaylists);
        },
        error => {
          reject();
        });
    });
  }

  public getPlaylist(id: String): Promise<IPlaylist> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: playlist,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.getPlaylist'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ps: id
        }
      }).subscribe(
        response => {
          resolve(response.data.getPlaylist);
        },
        error => {
          reject();
        });
    });
  }

  public getImplementation(id: String): Promise<IImplementation> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: implementation,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.getImplementation'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          is: id
        }
      }).subscribe(
        response => {
          resolve(response.data.getImplementation);
        },
        error => {
          reject();
        });
    });
  }

  public getContents(): Promise<IContent[]> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: contents,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.getContents'
        },
        variables: {
          slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getContents);
        });
    });
  }

  public getLayouts(): Promise<ILayout[]> {
    return new Promise<ILayout[]>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: layouts,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.getLayouts'
        },
        variables: {
          slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getLayouts);
        });
    });
  }

  public getTransitions(): Promise<ITransition[]> {
    return new Promise<ITransition[]>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: transition,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.getTransitions'
        },
        variables: {
          cs: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getTransitions);
        });
    });
  }

  public createPlaylist(name: string, color: string, playlistType: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: createPlaylist,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.createPlaylist'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          cpi: {
            name: name,
            color: color,
            type: playlistType
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.createPlaylist.slug);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public updatePlaylist(id: string, name: string, color: string, draft: boolean): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updatePlaylist,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.updatePlaylist'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ps: id,
          upi: {
            name: name,
            color: color,
            is_draft: draft
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updatePlaylist.slug);
        },
        error => {
          const formError = new FormError(error, 'input');
          reject(formError);
        });
    });
  }

  public deletePlaylist(play: IPlaylist, force?: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deletePlaylist,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.deletePlaylist'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ps: play.slug,
          force: force
        }
      }).subscribe(
        response => {
          resolve(response.data.deleteAsset);
        },
        error1 => {
          reject();
        });
    });
  }

  public createStep(implementation_slug: string, layout_slug: string, plRelatedStep?: PlStepModel): Promise<IStep> {
    let sortInput = null;
    if (plRelatedStep !== null && !isUndefined(plRelatedStep)) {
      sortInput = {
        target: plRelatedStep.step.slug,
        direction: SortDirection.AFTER
      };
    }

    return new Promise<IStep>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: createStep,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.createStep'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          csi: {
            implementation_slug: implementation_slug,
            layout_slug: layout_slug
          },
          si: sortInput
        }
      }).subscribe(
        response => {
          resolve(response.data.createStep);
        },
        error => {
          console.log(error);
        });
    });
  }

  public deleteStep(step_slug: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deleteStep,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.deleteStep'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ss: step_slug
        }
      }).subscribe(
        response => {
          resolve(response.data.deleteStep);
        },
        error => {
          console.log(error);
        });
    });
  }

  public updateStepPosition(step: IStep, targetStep: IStep, direction: string): Promise<IStep> {
    return new Promise<IStep>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updateStep,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.updateStepPosition'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ss: step.slug,
          usi: {},
          si: {
            target: targetStep.slug,
            direction: direction
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updateStep);
        },
        error => {
          console.log(error);
        });
    });
  }

  public updateStepTransition(stepSlug: string, transitionSlug: string): Promise<IStep> {
    return new Promise<IStep>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updateStep,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.updateStepTransition'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ss: stepSlug,
          usi: {
            transition_slug: transitionSlug
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updateStep);
        },
        error => {
          console.log(error);
          reject(error);
        });
    });
  }

  public updateStepIO(stepSlug: string, io: string): Promise<IStep> {
    return new Promise<IStep>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updateStep,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.updateStepIO'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          ss: stepSlug,
          usi: {
            io: io
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updateStep);
        },
        error => {
          console.log(error);
          reject(error);
        });
    });
  }

  public createStepItem(step_slug: string, surface_slug: string, content: IContent, targetStepItem: IStepitem, direction: string): Promise<IStepitem> {

    let sortInput = null;
    if (targetStepItem !== null) {
      sortInput = {
        target: targetStepItem.slug,
        direction: direction
      };
    }

    return new Promise<IStepitem>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: createStepItem,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.createStepItem'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          csii: {
            step_slug: step_slug,
            surface_slug: surface_slug,
            content_slug: content.slug,
            duration: content.type === ContentType.VIDEO ? content.duration : PlContentModel.DEFAULT_DURATION
          },
          si: sortInput
        }
      }).subscribe(
        response => {
          resolve(response.data.createStepitem);
        },
        error => {
          reject(error);
          console.log(error);
        });
    });
  }

  public deleteStepItem(step_item_slug: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: deleteStepItem,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.deleteStepItem'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          sis: step_item_slug
        }
      }).subscribe(
        response => {
          resolve(response.data.createStepItem);
        },
        error => {
          reject();
        });
    });
  }

  public updateStepItemDuration(stepItem: IStepitem, duration: number): Promise<IStepitem> {
    return new Promise<IStepitem>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updateStepItem,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.updateStepItemDuration'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          sis: stepItem.slug,
          usii: {
            duration: Math.round(duration)
          }
        }
      }).subscribe(
        response => {
          resolve(response.data.updateStepitem);
        },
        error => {
          reject(error);
        });
    });
  }

  public updateStepItemPosition(stepItem: IStepitem, stepSlug: string, surfaceSlug: string, targetStepItem: IStepitem, direction: string): Promise<IStepitem> {
    let sortInput = null;
    if (targetStepItem !== null) {
      sortInput = {
        target: targetStepItem.slug,
        direction: direction
      };
    }

    return new Promise<IStepitem>((resolve, reject) => {
      this.graph.getApollo().mutate<any>({
        mutation: updateStepItem,
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.updateStepItemPosition'
        },
        variables: {
          cs: this.graph.getCurrentCustomer(),
          sis: stepItem.slug,
          usii: {
            step_slug: stepSlug,
            surface_slug: surfaceSlug
          },
          si: sortInput
        }
      }).subscribe(
        response => {
          resolve(response.data.updateStepitem);
        },
        error => {
          reject();
        });
    });
  }

  getAvaiableLayoutType() {
    return new Promise<PlaylistType[]>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: avaiableLayoutType,
        fetchPolicy: 'network-only',
        context: {
          uri: this.graph.getGraphqlUrl() + '?PlaylistsService.getAvaiableLayoutType'
        },
        variables: {
          slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getCustomer.available_layout_types);
        });
    });
  }
}

// FRAGMENT

const layoutFr = gql`
  fragment layoutFr on Layout{
    slug
    name
    type
    io
    surfaces{
      order
      slug
      height
      width
      x
      y
      preview_x
      preview_y
    }
  }
`;

const stepItemFr = gql`
  fragment stepItemFr on Stepitem{
    slug
    order
    duration
    surface{
      slug
    }
    content{
      slug
      name
      type
      duration
      assets{
        is_ready
        path
      }
    }
  }
`;

const stepFr = gql`
  fragment stepFr on Step{
    slug
    order
    io
    layout{
      ...layoutFr
    }
    stepitems{
      ...stepItemFr
    }
    transition{
      slug
      duration
    }
  }
  ${stepItemFr}
  ${layoutFr}
`;

const implementationFr = gql`
  fragment implementationFr on Implementation {
    slug
    ratio{
      slug
      name
    }
    steps{
      ...stepFr
    }
  }
  ${stepFr}
`;

// QUERY

const avaiableLayoutType = gql`
  query avaiableLayoutType($slug: String!){
    getCustomer(customer_slug: $slug){
      available_layout_types
    }
  }
`;

const playlists = gql`
  query getPlaylists($slug: String!){
    getPlaylists(customer_slug: $slug){
      slug
      name
      programmings{
        slug
      }
      is_draft
      created_at
      updated_at
    }
  }
`;

const playlist = gql`
  query getPlaylist($cs: String!, $ps: String!){
    getPlaylist(customer_slug: $cs, playlist_slug: $ps){
      slug
      name
      is_draft
      color
      type
      implementations{
        slug
        ratio{
          name
        }
      }
    }
  }
`;

const implementation = gql`
  query getImplementation($cs: String!, $is: String!){
    getImplementation(customer_slug: $cs, implementation_slug: $is){
      ...implementationFr
    }
  }
  ${implementationFr}
`;

const contents = gql`
  query getContents($slug: String!){
    getContents(customer_slug: $slug, usable: true){
      slug
      name
      type
      duration
      assets{
        is_ready
        path
        width
        height
      }
    }
  }
`;

const transition = gql`
  query transition($cs: String!){
    getTransitions(customer_slug: $cs){
      slug
      name
      duration
      class
    }
  }
`;

const layouts = gql`
  query getLayouts($slug: String!){
    getLayouts(customer_slug: $slug){
      ...layoutFr
    }
  }
  ${layoutFr}
`;

const createPlaylist = gql`
  mutation ($cs: String!, $cpi: CreatePlaylistInput!){
    createPlaylist(customer_slug: $cs, input: $cpi){
      slug
    }
  }
`;

const updatePlaylist = gql`
  mutation ($cs: String!, $ps: String!, $upi: UpdatePlaylistInput!){
    updatePlaylist(customer_slug: $cs,playlist_slug: $ps, input: $upi){
      slug
    }
  }
`;

const deletePlaylist = gql`
  mutation ($cs: String!, $ps: String!, $force: Boolean){
    deletePlaylist(customer_slug: $cs,playlist_slug: $ps, force: $force)
  }
`;

const createStep = gql`
  mutation ($cs: String!, $csi: CreateStepInput!, $si: SortInput){
    createStep(customer_slug: $cs, input: $csi, sort: $si, force_error: false){
      ...stepFr
    }
  }
  ${stepFr}
`;

const deleteStep = gql`
  mutation ($cs: String!, $ss: String!){
    deleteStep(customer_slug: $cs, step_slug: $ss)
  }
`;

const updateStep = gql`
  mutation ($cs: String!,$ss: String!, $usi: UpdateStepInput!, $si: SortInput){
    updateStep(customer_slug: $cs, step_slug: $ss,input: $usi,sort: $si, force_error: false){
      ...stepFr
    }
  }
  ${stepFr}
`;

const createStepItem = gql`
  mutation ($cs: String!, $csii: CreateStepitemInput!, $si: SortInput){
    createStepitem(customer_slug: $cs, input: $csii, sort: $si){
      ...stepItemFr
    }
  }
  ${stepItemFr}
`;

const deleteStepItem = gql`
  mutation ($cs: String!, $sis: String!){
    deleteStepitem(customer_slug: $cs, stepitem_slug: $sis)
  }
`;

const updateStepItem = gql`
  mutation ($cs: String!, $sis: String!, $usii: UpdateStepitemInput!, $si: SortInput){
    updateStepitem(customer_slug: $cs, stepitem_slug: $sis, input: $usii, sort: $si){
      ...stepItemFr
    }
  }
  ${stepItemFr}
`;
