import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatAutocompleteSelectedEvent, MatChipInputEvent, MatDialogRef} from '@angular/material';
import {FormControl} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'aesys-contents-list-add-tag-dialog',
  templateUrl: './contents-list-add-tag-dialog.component.html',
  styleUrls: ['./contents-list-add-tag-dialog.component.scss']
})
export class ContentsListAddTagDialogComponent implements OnInit {

  visible = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagsCtrl = new FormControl();
  allTags: string[] = [];
  filteredTags: Observable<string[]>;
  tags: string[] = [];


  constructor(public dialogRef: MatDialogRef<ContentsListAddTagDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.allTags = data.tags;
    this.filteredTags = data.tags;
    this.filteredTags = this.tagsCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
  }

  ngOnInit() {
  }

  add(event: any): void {
    // const input = event.input;
    // const value = event.value;
    let input: any;
    let value: string;
    if (event.input) {
      input = event.input;
    } else {
      value = event.option.value;
    }

    const index = this.tags.indexOf(value);
    if (index === -1) {
      if ((value || '').trim()) {
        this.tags.push(value.trim());
      }
    }
    if (input) {
      input.value = '';
    }
    this.tagsCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  addTags() {
    this.dialogRef.close(this.tags);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }
}
