import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {QuickPanelService} from './quick-panel.service';
import ICustomer = AES.ICustomer;
import {FuseMatSidenavHelperService} from '../../../../../@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import {MatDialog} from '@angular/material';
import {LayoutManagerListComponent} from '../../../content/layout-manager/layout-manager-list/layout-manager-list.component';
import RoleName = AES.RoleName;

@Component({
  selector: 'fuse-quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FuseQuickPanelComponent implements OnInit {

  customer: ICustomer = null;
  showLayoutManager = false;

  constructor(
    private qpService: QuickPanelService,
    private translateService: TranslateService,
    private d: FuseMatSidenavHelperService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit() {

    this.d.getSidenav('quick-panel').openedChange
      .subscribe(open => {
        if (open) {
          this.qpService.getCustomerDetail()
            .then(customer => {
              this.customer = customer;
            });
          this.qpService.getRoles()
            .then(me => {
              for (const role of me.roles) {
                if (role.name === RoleName.ADMIN || role.name === RoleName.CUSTOMER_ADMIN) {
                  this.showLayoutManager = true;
                }
              }
            });
        }
      });
  }

  openLayoutManager() {
    this.dialog.open(LayoutManagerListComponent, {
      width: '800px',
      maxHeight: '600px'
    });
  }
}
