import {NgModule} from '@angular/core';
import {ChannelsListComponent} from './channels-list/channels-list.component';
import {CustomerResolver} from '../../../shared/resolver/customer.resolver';
import {AuthGuard} from '../../../shared/guard/auth-guard';
import {SharedModule} from '../../../shared/shared.module';
import {RouterModule} from '@angular/router';
import { ChannelsService } from './channels.service';
import {ClipboardModule} from 'ngx-clipboard';

const routes = [
  {
    path: 'customer/:custId/channels',
    canActivate: [AuthGuard],
    resolve: {
      customer: CustomerResolver
    },
    component: ChannelsListComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    ClipboardModule
  ],
  declarations: [
    ChannelsListComponent
  ],
  providers: [ChannelsService]
})
export class ChannelsModule {
}
