import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import IAsset = AES.IAsset;
import {ContentsService} from '../../contents.service';

@Component({
  selector: 'aesys-ratio-resolution-dialog',
  templateUrl: './ratio-resolution-dialog.component.html',
  styleUrls: ['./ratio-resolution-dialog.component.scss']
})
export class RatioResolutionComponent {

  message = '';
  assetsList: IAsset[];
  resolutionsList: string[] = [''];
  ratioList: string[] = [];
  availableAssets: string[] = [];

  constructor(public contentService: ContentsService,
              public dialogRef: MatDialogRef<RatioResolutionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.assetsList = data.assets;
    for (const asset of this.assetsList){
      if (this.ratioList.indexOf(asset.ratio_string) === -1){
        this.ratioList.push(asset.ratio_string);
      }
      if (this.resolutionsList.indexOf(asset.resolution_class) === -1){
        this.resolutionsList.push(asset.resolution_class);
      }
      if (this.availableAssets.indexOf(asset.ratio_string + '-' + asset.resolution_class) == -1){
        this.availableAssets.push(asset.ratio_string + '-' + asset.resolution_class);
      }

    }

    // sorting lists (for ratio list custom sort)
    this.ratioList.sort((a, b) => this.ratioListCustomSort(a, b));
    this.resolutionsList.sort();

    // console.log('assets list: ');
    // console.log(this.assetsList);
    //
    // console.log('ratio list: ');
    // console.log(this.ratioList);
    //
    // console.log('resolutions list: ');
    // console.log(this.resolutionsList);
    //
    // console.log('available assets: ');
    // console.log(this.availableAssets);

  }


  /**
   * custom sort for ratio list, element a & b  (strings in "ratio format" x:y) are tokenized on ":" char, then normal
   * sorting operation are done on each token of both elements, sequentially (first evaluate first token of a & b, if equals
   * evaluate the second token)
   * @param {string} a
   * @param {string} b
   * @returns {number}
   *      number > 0 - doesn't switch elements
   *      number < 0 - switch elements order
   */
  ratioListCustomSort (a: string, b: string): number {
    if (a == null || b == null) {
      return 1;
    }
    const first = a.split(':');
    const second = b.split(':');
    if (first[0] !== second[0]){
      return Number(first[0]) - Number(second[0]);
    }
    else{
      if (first[1] !== second[1]){
        return Number(first[1]) - Number(second[1]);
      }
      else {
        return 1;
      }
    }
  }

  ratioResAvailable(ratio: string, resolution: string): boolean {
    if (this.availableAssets.indexOf(ratio + '-' + resolution) != -1){
      return true;
    }else {
      return false;
    }
  }

}
