import {Component, ComponentFactoryResolver, EventEmitter, Injector, Output, QueryList, ViewChildren, ViewContainerRef} from '@angular/core';
import ITimeline = AES.ITimeline;
import * as _ from 'lodash';
import IProgramming = AES.IProgramming;
import {TlSimulatorPriorityComponent} from './tl-simulator-priority/tl-simulator-priority.component';
import {FormControl} from '@angular/forms';
import {MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE, MatDialog} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {Moment} from 'moment';
import {TlSConditionComponent} from './tl-s-condition/tl-s-condition.component';
import IPlaylist = AES.IPlaylist;
import {ConditionValue} from '../../../../network/data/condition-value.model';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D/M/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'aesys-tl-simulator',
  templateUrl: './tl-simulator.component.html',
  styleUrls: ['./tl-simulator.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class TlSimulatorComponent {

  @ViewChildren('priorityInsert', {read: ViewContainerRef}) priorityInsert: QueryList<ViewContainerRef>;
  @ViewChildren('graded') graded;

  @Output()
  openSchedules: EventEmitter<{ playlist: IPlaylist, priority: number }> = new EventEmitter<{ playlist: IPlaylist, priority: number }>();

  dateStartForm = new FormControl(moment());
  dateEndForm = new FormControl(moment());
  dateStart = this.correctTime(this.dateStartForm.value);
  dateEnd = this.correctTime(this.dateStartForm.value);

  timeline: ITimeline = null;
  filterConditions: ConditionValue[] = [];
  settedConditionCount = '';

  private currentMax = 0;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    public dialog: MatDialog
  ) {
  }

  public refresh(tl: ITimeline) {
    this.timeline = tl;
    this.updateView();
  }

  updateView() {
    let date = new Date(this.dateStart);
    date.setUTCHours(0);
    date.setUTCMinutes(0);
    date.setUTCSeconds(0);
    this.dateStart = date.getTime();
    date = new Date(this.dateEnd);
    date.setUTCHours(23);
    date.setUTCMinutes(59);
    date.setUTCSeconds(59);
    this.dateEnd = date.getTime();
    if (this.dateStart < this.dateEnd) {
      this.priorityInsert.first.clear();
      let maxP = 3;
      for (const p of this.timeline.programmings) {
        if (p.priority > maxP) {
          maxP = p.priority;
        }
      }
      this.currentMax = maxP;
      for (let i = 1; i <= maxP; i++) {
        this.addPriorityView(i, _(this.timeline.programmings).filter(p => p.priority == i).value());
      }
      this.graded.toArray()[0].refresh(this.dateStart, this.dateEnd);
    }
  }

  private addPriorityView(priority: number, programmings: IProgramming[]) {
    const dComponent = this.componentFactoryResolver.resolveComponentFactory(TlSimulatorPriorityComponent);
    const cRef = this.priorityInsert.first.createComponent(dComponent);
    cRef.instance.openSchedulesChild.subscribe((data: { playlist: IPlaylist, priority: number }) => {
      this.openSchedules.emit(data);
    });
    const dInstance = <TlSimulatorPriorityComponent>cRef.instance;
    setTimeout(() => {
      dInstance.refesh(priority, programmings, this.filterConditions, this.dateStart, this.dateEnd);
    });
  }

  correctTime(event: Moment): number {
    return ((event.unix() * 1000) + (event.utcOffset() * 60 * 1000));
  }

  addPriority() {
    this.currentMax++;
    this.addPriorityView(this.currentMax, []);
  }

  getDate(time: number): Date {
    return new Date(time + ((new Date()).getTimezoneOffset() * 60 * 1000));
  }

  openFilterDialog() {
    const dialogRef = this.dialog.open(TlSConditionComponent, {
      width: '800px',
      data: {
        filterConditions: _.clone(this.filterConditions)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.filterConditions = result.filter;
        this.updateView();
        if (this.filterConditions.length > 0) {
          this.settedConditionCount = '(' + this.filterConditions.length + ')';
        } else {
          this.settedConditionCount = '';
        }

      }
    });
  }

  public followingDate() {
    const DAY = 24 * 60 * 60 * 1000;
    let day = Math.round(((this.dateEnd - this.dateStart) / (DAY)) / 2);
    day = day === 0 ? 1 : day;
    this.dateStart = this.dateStart + (day * DAY);
    this.dateEnd = this.dateEnd + (day * DAY);
    (this.dateEndForm.value as Moment).add(day, 'd');
    (this.dateStartForm.value as Moment).add(day, 'd');
    this.dateEndForm.setValue(moment(this.dateEndForm.value.unix() * 1000));
    this.dateStartForm.setValue(moment(this.dateStartForm.value.unix() * 1000));
    this.updateView();

  }

  public previousDate() {
    const DAY = 24 * 60 * 60 * 1000;
    let day = Math.round(((this.dateEnd - this.dateStart) / (DAY)) / 2);
    day = day === 0 ? 1 : day;
    this.dateStart = this.dateStart - (day * DAY);
    this.dateEnd = this.dateEnd - (day * DAY);
    (this.dateStartForm.value as Moment).subtract(day, 'd');
    (this.dateEndForm.value as Moment).subtract(day, 'd');
    this.dateStartForm.setValue(moment(this.dateStartForm.value.unix() * 1000));
    this.dateEndForm.setValue(moment(this.dateEndForm.value.unix() * 1000));
    this.updateView();
  }

}
