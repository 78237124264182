import {Component, EventEmitter, Inject, OnInit, Output, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatTabGroup} from '@angular/material';
import {PlLayoutDialogComponent} from '../../../playlists/playlist-detail/pl/pl-layout-dialog/pl-layout-dialog.component';
import IProgramming = AES.IProgramming;
import * as _ from 'lodash';
import {isUndefined} from 'util';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'aesys-tl-dialog-programmings',
  templateUrl: './tl-dialog-programmings.component.html',
  styleUrls: ['./tl-dialog-programmings.component.scss']
})
export class TlDialogProgrammingsComponent implements OnInit {

  @ViewChildren('tabForms') tabForms;
  @ViewChildren('tabForms') matTabGroup: MatTabGroup;
  @Output() progammingUpdated = new EventEmitter<IProgramming[]>();
  programmings: IProgramming[] = [];
  index = 0;
  lastIndex = 0;
  currentPosition = 0;
  dataReceived = null;

  constructor(
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<TlDialogProgrammingsComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.index = (this.data.priority - 1);
    this.dataReceived = this.data;
    this.lastIndex = this.index;
  }

  ngOnInit() {

    this.programmings = _(this.data.programmings).filter(p => p.playlist.slug === this.data.playlistSlug).value();
    this.programmings.sort((n1, n2) => {
      return n1.priority - n2.priority;
    });

    let added = false;
    for (const prg of this.programmings) {
      if (prg.priority == this.data.priority) {
        added = true;
      }
    }
    if (!added) {
      const partialProgrammings: IProgramming[] = [];
      let alreadyAdded = false;
      for (const prg of this.programmings) {
        if (this.dataReceived.priority < prg.priority && !alreadyAdded) {
          partialProgrammings.push(null);
          alreadyAdded = true;
          partialProgrammings.push(prg);
        } else {
          partialProgrammings.push(prg);
        }
      }
      this.programmings = partialProgrammings;
      if (!alreadyAdded) {
        this.programmings.push(null);
      }
    }
    this.positionFromPriority();
  }

  indexChange(index: number) {
    setTimeout(() => {
      if (!this.isPristine(this.lastIndex)) {
        this.index = this.lastIndex;
        this.snackBar.open(this.translateService.instant('TIMELINE_DIALOG.NEED_SAVE'), '', {
          duration: 1000,
        });
      } else {
        this.lastIndex = index;
      }
    }, 100);

  }

  isPristine(index: number): boolean {
    if (!isUndefined(this.tabForms)) {
      return this.tabForms.toArray()[index].isPristine();
    }
    return true;
  }

  positionFromPriority() {
    for (let i = 0; i < this.programmings.length; i++) {
      if (this.programmings[i] == null || this.dataReceived.priority == this.programmings[i].priority) {
        this.currentPosition = i;
      }
    }
  }

}
