import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material';

import {FuseSharedModule} from '@fuse/shared.module';
import {FuseNavigationModule, FuseSearchBarModule, FuseShortcutsModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components/index';

import {FuseContentModule} from 'app/main/content/content.module';
import {FuseFooterModule} from 'app/main/layout/layout-component/footer/footer.module';
import {FuseNavbarModule} from 'app/main/layout/layout-component/navbar/navbar.module';
import {FuseQuickPanelModule} from 'app/main/layout/layout-component/quick-panel/quick-panel.module';
import {FuseToolbarModule} from 'app/main/layout/layout-component/toolbar/toolbar.module';

import {FuseMainComponent} from './main.component';
import {LogoComponent} from '../layout-component/logo/logo.component';
import {UploadModule} from '../layout-component/upload/upload.module';

@NgModule({
  declarations: [
    FuseMainComponent,
    LogoComponent
  ],
  imports: [
    RouterModule,

    MatSidenavModule,

    FuseSharedModule,

    FuseThemeOptionsModule,
    FuseNavigationModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    FuseSidebarModule,

    FuseContentModule,
    FuseFooterModule,
    FuseNavbarModule,
    FuseQuickPanelModule,
    FuseToolbarModule,


    UploadModule
  ],
  exports: [
    FuseMainComponent
  ]
})
export class FuseMainModule {
}
