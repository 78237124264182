import {Injectable} from '@angular/core';
import {GraphService} from '../../../network/graph.service';
import {environment} from '../../../../../environments/environment';
import ICustomer = AES.ICustomer;
import gql from 'graphql-tag';
import IMe = AES.IMe;

@Injectable()
export class QuickPanelService {

  constructor(private graph: GraphService) {

  }

  // QUERIES
  public getCustomerDetail(): Promise<ICustomer> {
    return new Promise<ICustomer>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getCustomer,
        context: {
          uri: this.graph.getGraphqlUrl() + '?QuickPanelService.getCustomerDetail'
        },
        fetchPolicy: 'network-only',
        variables: {
          customer_slug: this.graph.getCurrentCustomer()
        }
      }).subscribe(
        response => {
          resolve(response.data.getCustomer);
        });
    });
  }

  public getRoles(): Promise<IMe> {
    return new Promise<any>((resolve, reject) => {
      this.graph.getApollo().query<any>({
        query: getRoles,
        context: {
          uri: this.graph.getGraphqlUrl() + '?QuickPanelService.getCustomers'
        },
        fetchPolicy: 'network-only'
      }).subscribe(
        response => {
          resolve(response.data.getMe);
        },
        error => {
          reject(error);
        });
    });
  }

}

const getCustomer = gql`
  query getCustomer($customer_slug: String!){
    getCustomer(customer_slug: $customer_slug){
      slug
      name
      quota
      used_quota
      free_quota
    }
  }
`;

const getRoles = gql`
  query {
    getMe{
      roles{
        name
      }
    }
  }
`;
