import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {GraphService} from '../../../network/graph.service';

import IMe = AES.IMe;
import IAsset = AES.IAsset;
import {ToolbarService} from './toolbar.service';
import {interval} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {ChannelsService} from '../../../content/channels/channels.service';
import {NotificationPollingService} from '../../../../util/notification-polling.service';
import OperationType = AES.OperationType;
import RoleName = AES.RoleName;

@Component({
  selector: 'fuse-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class FuseToolbarComponent implements OnInit, OnDestroy, AfterViewInit {

  private SELECTED_LANGUAGE_ID = 'pref_selected_lang';
  private notificationObserver = null;
  private INTERVAL = 5 * 1000; // 30 * 1000;

  languages: any;
  selectedLanguage: any;
  showLoadingBar: boolean;
  horizontalNav: boolean;
  noNav: boolean;

  me: IMe = null;
  notifications: NotificationToolbar[] = [];
  actualNotificationIntervalTime: number;
  intervalResetRef: any = null;
  intervalNotificationRef: any = null;

  constructor(
    private router: Router,
    private fuseConfig: FuseConfigService,
    private sidebarService: FuseSidebarService,
    private translate: TranslateService,
    private graphService: GraphService,
    private channelService: ChannelsService,
    private toolbarService: ToolbarService,
    private notificationPollingService: NotificationPollingService
  ) {
    this.languages = [
      {
        'id': 'en',
        'title': 'English',
        'flag': 'en'
      },
      {
        'id': 'it',
        'title': 'Italiano',
        'flag': 'it'
      },
      {
        'id': 'fr',
        'title': 'Français',
        'flag': 'fr'
      }
    ];

    const selectedLangId: string = localStorage.getItem(this.SELECTED_LANGUAGE_ID);
    if (selectedLangId != null && selectedLangId != undefined) {
      for (const lan of this.languages) {
        if (lan.id === selectedLangId) {
          this.selectedLanguage = lan;
        }
      }
    } else {
      this.selectedLanguage = this.languages[0];
    }
    this.translate.use(this.selectedLanguage.id);
    this.fuseConfig.onConfigChanged.subscribe((settings) => {
      this.horizontalNav = settings.layout.navigation === 'top';
      this.noNav = settings.layout.navigation === 'none';
    });

    setTimeout(() => {
      router.events.subscribe(
        (event) => {
          if (event instanceof NavigationStart) {
            this.showLoadingBar = true;
          }
          if (event instanceof NavigationEnd) {
            this.showLoadingBar = false;
          }
        });
    }, 1);

  }

  ngOnInit(): void {
    this.toolbarService.getCustomers(false)
      .then(me => {
        this.me = me;
      })
      .catch(error => {
      });
  }

  ngOnDestroy(): void {
    if (this.notificationObserver !== null) {
      this.notificationObserver.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.getNotifications();
    this.startNotificationInterval();
  }

  /**
   * Recursive function, every time it is called reset the getNotification polling at frequency defined by 'notificationPollingService'.
   * If the function detect that the 'inactivity trigger threshold' (number of cycles allowed with default speed before trigger the inactivity) is exceeded
   * and there is a change or reset of the polling rate, the function unsubscribe the current polling and call itself again, instantiating a new polling
   * cycle with updated frequency.
   */
  startNotificationInterval() {
    this.actualNotificationIntervalTime = this.notificationPollingService.getPollingInterval();
    // console.log('notification interval STARTED');
    if (this.intervalNotificationRef != null) {
      this.intervalNotificationRef.unsubscribe();
      this.intervalNotificationRef = null;
    }
    this.intervalNotificationRef = interval(this.actualNotificationIntervalTime).subscribe((x) => {

      // 'Reset polling rate' check cycle (higher frequency of 5s allow to avoid potentially 2 minutes delay between different getNotifications cycles)
      if (this.intervalResetRef != null) {
        this.intervalResetRef.unsubscribe();
      }
      this.intervalResetRef = interval(5000).subscribe((x) => {
        if (this.notificationPollingService.getPollingInterval() != this.actualNotificationIntervalTime) {
          // console.log('notification interval DESTROYED');
          this.intervalResetRef.unsubscribe();
          this.intervalResetRef = null;
          this.intervalNotificationRef.unsubscribe();
          this.intervalNotificationRef = null;
          this.startNotificationInterval();
        }
      });

      // console.log('----> call getNotification (INTERVAL)');
      this.getNotifications();

      // 'Increase polling time' check (via notificationPollingService check if actual polling time have exceeded the inactivity
      // trigger threshold and if polling frequency need to be updated, in that case trigger the recursion)
      if (this.notificationPollingService.getInactivityCounter() < this.notificationPollingService.INACTIVITY_TRIGGER_THRESHOLD) {
        this.notificationPollingService.increaseInactivityCounter();
      } else {
        if (this.notificationPollingService.getPollingInterval() < this.notificationPollingService.INTERVAL_MAX) {
          this.notificationPollingService.increasePollingInterval();
          this.intervalNotificationRef.unsubscribe();
          this.intervalNotificationRef = null;
          // console.log('notification interval DESTROYED');
          setTimeout(() => {
            this.startNotificationInterval();
          }, 10);
        }
      }
    });
  }

  getNotifications() {
    this.toolbarService.getNotification()
      .then(me => {

        this.notifications = [];
        for (const n of me.notifications) {

          const notification: NotificationToolbar = {
            slug: n.id,
            message: this.translate.instant('TOOLBAR_NOTIFICATION.' + n.data.operation_type + '.' + n.data.operation_status),
            path: ''
          };

          switch (n.data.operation_type) {
            case OperationType.OPERATION_TYPE_ASSET_PROCESS:
              if (n.data.model !== null) {
                notification.path = '/customer/' + n.data.customer_slug + '/contents/' + (n.data.model as IAsset).model.slug;
              } else {
                continue;
              }
              break;
            case OperationType.OPERATION_TYPE_PUBLICATION_PUBLISH:
              this.channelService.channelUpdated.emit();
              notification.path = '/customer/' + n.data.customer_slug + '/dashboard';
              break;
            case OperationType.OPERATION_TYPE_CUSTOMER_SETUP:
              notification.path = '/customer';
              break;
          }
          this.notifications.push(notification);
        }
      })
      .catch(error => {
      });

  }

  readAll() {
    const ns: string[] = [];
    for (const n of this.notifications) {
      ns.push(n.slug);
    }
    this.toolbarService.readNotification(ns)
      .then(success => {
        this.getNotifications();
      });
  }


  getCurrentCustomer(): string {
    if (this.me != null) {
      for (const c of this.me.customers) {
        if (c.slug === this.graphService.getCurrentCustomer()) {
          return c.name;
        }
      }
    }
    return '';
  }

  toggleSidebarOpened(key) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  setLanguage(lang) {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;
    localStorage.setItem(this.SELECTED_LANGUAGE_ID, lang.id);

    // Use the selected language for translations
    this.translate.use(lang.id);
  }

  logout() {
    this.graphService.logout(this.router);
  }

  openProfile() {
    window.open(environment.aesysBasePath + '/Manage/Index', '_blank');
  }

  isAdmin(): boolean {
    if (this.me != null) {
      for (const role of this.me.roles) {
        if (role.name === RoleName.ADMIN || role.name === RoleName.CUSTOMER_ADMIN) {
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  readAndOpen(n: NotificationToolbar) {
    this.router.navigate([n.path]);
    const ns: string[] = [n.slug];
    this.toolbarService.readNotification(ns)
      .then(success => {
        this.getNotifications();
      });
  }

}

interface NotificationToolbar {
  slug: string;
  message: string;
  path: string;
}
