import {NgModule} from '@angular/core';
import {MaintenancePageComponent} from './maintenance-page/maintenance-page.component';
import {SharedModule} from '../../../shared/shared.module';
import {RouterModule} from '@angular/router';

const routes = [
  {
    path: 'maintenance',
    component: MaintenancePageComponent
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    MaintenancePageComponent
  ]
})
export class MaintenanceModule {
}
