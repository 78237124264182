import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {LoginGuard} from './login-guard';
import { LoginService } from './login.service';

const routes = [
  {
    path: 'login',
    canActivate: [LoginGuard],
    component: LoginComponent
  }, {
    path: 'login/:code',
    canActivate: [LoginGuard],
    component: LoginComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule
  ],
  providers: [
    LoginGuard,
    LoginService
  ],
  declarations: [
    LoginComponent
  ]
})
export class LoginModule {
}
