import {Component, Input, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';

import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {GraphService} from '../../../network/graph.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {ChannelsService} from '../../../content/channels/channels.service';

@Component({
  selector: 'fuse-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FuseNavbarComponent implements OnDestroy {
  private fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private dashBadge = null;

  @ViewChild(FusePerfectScrollbarDirective, {static: false}) set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this.fusePerfectScrollbar = theDirective;

    this.navigationServiceWatcher =
      this.navigationService.onItemCollapseToggled.subscribe(() => {
        this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
          this.fusePerfectScrollbar.update();
        }, 310);
      });
  }

  @Input() layout;
  navigation: any;
  navigationServiceWatcher: Subscription;
  fusePerfectScrollbarUpdateTimeout;

  constructor(
    private route: ActivatedRoute,
    private sidebarService: FuseSidebarService,
    private navigationService: FuseNavigationService,
    private graphService: GraphService,
    private channelService: ChannelsService,
    private translateService: TranslateService
  ) {
    // Default layout
    this.layout = 'vertical';
    this.refreshMenu();
    this.graphService.currentCustomerObserver.subscribe(customer => {
      this.refreshMenu();
      this.checkChannel();
    });
    this.channelService.channelUpdated.subscribe(() => {
      this.checkChannel();
    });

  }

  private refreshMenu() {
    this.navigation = [
      {
        'id': 'dashboard',
        'title': this.translateService.instant('NAV.DASHBOARD'),
        'translate': 'NAV.DASHBOARD',
        'type': 'item',
        'icon': 'tv',
        'class': 'nav-dashboard',
        'url': '/customer/' + this.graphService.getCurrentCustomer() + '/dashboard',
        'badge': this.dashBadge
      },
      {
        'id': 'content',
        'title': this.translateService.instant('NAV.CONTENTS'),
        'translate': 'NAV.CONTENTS',
        'type': 'item',
        'icon': 'content_copy',
        'class': 'nav-content',
        'url': '/customer/' + this.graphService.getCurrentCustomer() + '/contents'
      },
      {
        'id': 'playlist',
        'title': this.translateService.instant('NAV.PLAYLIST'),
        'translate': 'NAV.PLAYLIST',
        'type': 'item',
        'icon': 'dashboard',
        'class': 'nav-playlist',
        'url': '/customer/' + this.graphService.getCurrentCustomer() + '/playlists'
      },
      {
        'id': 'timeline',
        'title': this.translateService.instant('NAV.TIMELINE'),
        'translate': 'NAV.TIMELINE',
        'type': 'item',
        'icon': 'event',
        'class': 'nav-timeline',
        'url': '/customer/' + this.graphService.getCurrentCustomer() + '/timelines'
      }
    ];
  }

  ngOnDestroy() {
    if (this.fusePerfectScrollbarUpdateTimeout) {
      clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
    }

    if (this.navigationServiceWatcher) {
      this.navigationServiceWatcher.unsubscribe();
    }
  }

  public checkChannel() {
    this.channelService.getChannels()
      .then(cs => {
        let needRefresh = 0;
        for (const channel of cs) {
          if (channel.is_outdate) {
            needRefresh++;
          }
        }
        if (needRefresh > 0) {
          this.dashBadge = {
            'translate': '' + needRefresh,
            'title': '' + needRefresh,
            'bg': 'red',
            'fg': 'white'
          };
        } else {
          this.dashBadge = null;
        }
        this.refreshMenu();
      });
  }

}
