import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {MatButtonModule, MatIconModule} from '@angular/material';

import {FuseSharedModule} from '@fuse/shared.module';

import {FuseNavbarComponent} from 'app/main/layout/layout-component/navbar/navbar.component';
import {FuseNavigationModule} from '@fuse/components/index';

@NgModule({
  declarations: [
    FuseNavbarComponent
  ],
  imports: [
    RouterModule,

    MatButtonModule,
    MatIconModule,

    FuseSharedModule,
    FuseNavigationModule
  ],
  exports: [
    FuseNavbarComponent
  ]
})
export class FuseNavbarModule {
}
