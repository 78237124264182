import gql from 'graphql-tag';

export class QueryModel {

  getMe = gql`
    query {
      getMe{
        slug
        name
        email
        own_customer
        customers{
          slug
          aesys_customer_id
          name
          is_ready
          is_active
        }
        customers_visibility
        roles{
          name
        }
      }
    }
  `;

  getNotification = gql`
    query {
      getMe{
        notifications(unreadOnly: true) {
          id
          __typename
          ... on MessageNotification {
            data {
              operation_type
              operation_status
              customer_slug
              model {
                __typename
                slug
                ... on Asset {
                  model{
                    slug
                  }
                }
              }
            }
          }
        }
        customers {
          slug
          name
        }
      }
    }
  `;

}
