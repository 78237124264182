import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {IoModel} from '../../../../../../network/data/io.model';
import {StepIoModel} from '../../../../../../network/data/step-io.model';

@Component({
  selector: 'aesys-pl-edit-output-dialog',
  templateUrl: './pl-edit-output-dialog.component.html',
  styleUrls: ['./pl-edit-output-dialog.component.scss']
})
export class PlEditOutputDialogComponent implements OnInit {

  outputValue = '';
  currentOutput: StepIoModel;
  output: IoModel;

  constructor(
    public dialogRef: MatDialogRef<PlEditOutputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.output = data.output;
    this.currentOutput = data.currentOutput;
    this.outputValue = this.currentOutput.value;
  }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(this.outputValue);
  }
}
