import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {GraphService} from '../../../network/graph.service';
import {MatCheckboxChange, MatDialog, MatSnackBar, MatSort, MatTableDataSource} from '@angular/material';
import {ContentsService} from '../contents.service';
import IContent = AES.IContent;
import IAsset = AES.IAsset;
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import {RatioResolutionComponent} from './ratio-resolution-dialog/ratio-resolution-dialog.component';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {TranslateService} from '@ngx-translate/core';
import {UploadEvent} from 'ngx-file-drop';
import * as _ from 'lodash';
import {ContentsListAddTagDialogComponent} from './contents-list-add-tag-dialog/contents-list-add-tag-dialog.component';
import ContentType = AES.ContentType;
import {ContentTypeEnum} from '../../../network/interface/graph-enum.model';

@Component({
  selector: 'aesys-contents-list',
  templateUrl: './contents-list.component.html',
  styleUrls: ['./contents-list.component.scss']
})
export class ContentsListComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  loader: any = null;
  contentType = ContentTypeEnum;
  displayedColumns = ['select', 'type', 'name', 'updated_at', 'dimension', 'used', 'aspect', 'tags', 'del'];
  contentsList: RowContent[];
  dataSource: MatTableDataSource<RowContent> = null;
  typeFilter = [false, false, false, false, false];
  searchText: string;
  dragZindex = -1;
  dragover: any = null;
  mouseover: any = null;

  // TABLE OPTIONS
  canBalkDelete = false;
  canBalkAddTag = false;
  allTagsList: string[] = [];

  constructor(
    private router: Router,
    private graphService: GraphService,
    public contentService: ContentsService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    public errorBar: MatSnackBar
  ) {
    const that = this;
    this.dragover = function (e) {
      that.dragZindex = 0;
    };
    this.mouseover = function (e) {
      that.dragZindex = -1;
    };
    document.body.addEventListener('dragover', this.dragover, false);
    document.body.addEventListener('mouseover', this.mouseover, false);
  }

  ngOnInit() {
    this.init();
    this.contentService.getTags()
      .then(res => {
        this.allTagsList = res;
      })
      .catch(err => {
        this.allTagsList = [];
      });
  }

  ngOnDestroy(): void {
    document.body.removeEventListener('dragover', this.dragover, false);
    document.body.removeEventListener('mouseover', this.mouseover, false);
  }

  init() {
    setTimeout(() => {
      if (this.loader == null) {
        this.loader = LoadingComponent.showLoading(this.dialog);
      }
      this.contentService.getContents()
        .then(contents => {
          LoadingComponent.hideLoading(this.loader);
          this.loader = null;
          this.contentsList = _.clone(contents) as RowContent[];
          for (const content of this.contentsList) {
            content.resolutionsList = [];
            for (const asset of content.assets) {
              if (content.resolutionsList.indexOf(asset.resolution_class) === -1) {
                content.resolutionsList.push(asset.resolution_class);
              }
            }

            content.svgIcon = this.contentService.getMatIconNameFromType(content.type as any);
            if (content.type === ContentType.PICTOGRAM) {
              for (const a of content.assets) {
                if (a.is_ready && a.path) {
                  content.pathIcon = a.path;
                }
              }
            }

            switch (content.type) {
              case ContentType.ARCHIVE:
                content.tooltipType = 'CONTENT_L.ARCHIVE';
                break;
              case ContentType.VIDEO:
                content.tooltipType = 'CONTENT_L.VIDEO';
                break;
              case ContentType.IMAGE:
                content.tooltipType = 'CONTENT_L.IMAGE';
                break;
              case ContentType.URL:
                content.tooltipType = 'CONTENT_L.URL';
                break;
              case ContentType.PICTOGRAM:
                content.tooltipType = 'CONTENT_L.PICTOGRAM';
                break;
            }
          }
          this.dataSource = new MatTableDataSource(this.contentsList as RowContent[]);
          this.dataSource.sort = this.sort;
          this.dataSource.filterPredicate = this.filterPredicate;
          this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
        })
        .catch(error => {
          LoadingComponent.hideLoading(this.loader);
          this.loader = null;
          this.errorBar.open(this.translateService.instant('CONTENT_L.ERR_GET_CONTENTS'), '', {
            duration: 1000
          });
        });
    }, 1);
  }

  isUsed(content: IContent): boolean {
    return content.is_used;
  }

  /**
   * Open dialog Delete content
   * @param {AES.IContent} content
   */
  deleteContent(content: IContent) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        message: this.translateService.instant('CONTENT_L.DELETE_CONTENT_MESSAGE'),
        positiveButton: this.translateService.instant('CONTENT_L.DIALOG_CONFIRM'),
        negativeButton: this.translateService.instant('CONTENT_L.DIALOG_UNDO')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.loader == null) {
          this.loader = LoadingComponent.showLoading(this.dialog);
        }
        this.contentService.deleteContent(content, false)
          .then(success => {
            this.init();
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.loader = null;
            this.errorBar.open(this.translateService.instant('CONTENT_L.ERR__CONTENT'), '', {
              duration: 1000
            });
          });
      }
    });
  }

  /**
   * open dialog Ratio Resolution
   * @param {AES.IContent} content
   */
  openAspectRatio(content: IContent) {
    const dialogRef = this.dialog.open(RatioResolutionComponent, {
      width: '300px',
      data: {
        message: 'Available Ratio/Resolution',
        assets: content.assets
      }
    });
  }

  openContent(content: IContent) {
    this.router.navigate(['/customer/' + this.graphService.getCurrentCustomer() + '/contents/' + content.slug]);
  }

  newContent() {
    this.router.navigate(['/customer/' + this.graphService.getCurrentCustomer() + '/contents/upload']);
  }

  getAssetsSize(assetsList: IAsset[]): number {
    let sum = 0;
    for (const asset of assetsList) {
      if (asset.size) {
        sum += Number(asset.size);
      }
    }
    return sum;
  }

  searchContents(event: any) {
    let filterValue = event.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /**
   * Filter contents by type (img, video, archive, url), create new material table only with requested
   * types and recreate new table datasource at the end of the method
   *
   * @param {ContentType} filter
   */
  setTypeFilter(filter: ContentType) {
    switch (filter) {
      case ContentType.URL:
        this.typeFilter[0] = !this.typeFilter[0];
        break;
      case ContentType.ARCHIVE:
        this.typeFilter[1] = !this.typeFilter[1];
        break;
      case ContentType.IMAGE:
        this.typeFilter[2] = !this.typeFilter[2];
        break;
      case ContentType.VIDEO:
        this.typeFilter[3] = !this.typeFilter[3];
        break;
      case ContentType.PICTOGRAM:
        this.typeFilter[4] = !this.typeFilter[4];
        break;
    }
    let supportList: RowContent[];
    if (!this.typeFilter[0] && !this.typeFilter[1] && !this.typeFilter[2] && !this.typeFilter[3] && !this.typeFilter[4]) {
      supportList = Object.assign([], this.contentsList);
    } else {
      supportList = [];
      for (const item of this.contentsList) {
        switch (item.type) {
          case ContentType.URL:
            if (this.typeFilter[0]) {
              supportList.push(item);
            }
            break;
          case ContentType.ARCHIVE:
            if (this.typeFilter[1]) {
              supportList.push(item);
            }
            break;
          case ContentType.IMAGE:
            if (this.typeFilter[2]) {
              supportList.push(item);
            }
            break;
          case ContentType.VIDEO:
            if (this.typeFilter[3]) {
              supportList.push(item);
            }
            break;
          case ContentType.PICTOGRAM:
            if (this.typeFilter[4]) {
              supportList.push(item);
            }
            break;
        }
      }
    }
    this.dataSource = new MatTableDataSource(supportList);
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = this.filterPredicate;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
    this.dataSource.filter = this.searchText;
  }

  /**
   *  Material table custom filter predicate, used in top search bar (text search)
   *
   * @param {AES.IContent} data
   * @param {string} filter
   * @returns {boolean}
   */
  filterPredicate: ((data: IContent, filter: string) => boolean) = (data: IContent, filter: string): boolean => {
    // Transform the data into a lowercase string of all property values.
    const dataStr = data.name.toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return (dataStr.indexOf(transformedFilter) !== -1);
  };

  /**
   * Material table custom sorting logic
   * @param {AES.IContent} data
   * @param {string} sortHeaderId
   * @returns {string | number}
   */
  sortingDataAccessor: ((data: IContent, sortHeaderId: string) => string | number) =
    (data: IContent, sortHeaderId: string): string | number => {
      switch (sortHeaderId) {
        case this.displayedColumns[3]:
          return this.getAssetsSize(data.assets);
        case this.displayedColumns[4]:
          if (this.isUsed(data)) {
            return 0;
          } else {
            return 1;
          }
        default:
          return data[sortHeaderId];
      }
    };

  dropped(event: UploadEvent) {
    this.contentService.temporaryUpload = event;
    this.router.navigate(['/customer/' + this.graphService.getCurrentCustomer() + '/contents/upload']);
  }

  contentChecked(checkbox: MatCheckboxChange, rowContent: RowContent) {
    rowContent.selected = checkbox.checked;
    this.canBalkDelete = false;
    this.canBalkAddTag = false;
    for (const rc of this.contentsList) {
      if (rc.selected) {
        this.canBalkAddTag = true;
        this.canBalkDelete = true;

      }
    }
    for (const rc of this.contentsList) {
      if (rc.selected) {
        if (rc.is_used) {
          this.canBalkDelete = false;
        }
      }
    }
  }

  addBalkTag() {
    const dialogRef = this.dialog.open(ContentsListAddTagDialogComponent, {
      width: '450px',
      data: {
        tags: this.allTagsList
      }
    });
    dialogRef.afterClosed().subscribe(tags => {
      if (tags) {
        const selectedContentsSlug: string[] = [];
        const selectedContents: RowContent[] = [];
        for (const rc of this.contentsList) {
          if (rc.selected) {
            selectedContentsSlug.push(rc.slug);
            selectedContents.push(rc);
          }
        }
        LoadingComponent.showLoading(this.dialog);
        this.contentService.addBalkTags(selectedContentsSlug, tags)
          .then(res => {
            LoadingComponent.hideStaticLoading();
            if (res) {
              this.errorBar.open(this.translateService.instant('CONTENT_L.ADD_TAG_SUCCESS'), '', {
                duration: 1000
              });
              for (const sc of selectedContents) {
                sc.selected = false;
                let newTags: string [] = [];
                if (sc.tags) {
                  sc.tags.concat(tags).forEach(item => {
                    if (newTags.indexOf(item) == -1) {
                      newTags.push(item);
                    }
                  });
                } else {
                  newTags = tags;
                }

                sc.tags = newTags;
              }

            } else {
              this.errorBar.open(this.translateService.instant('CONTENT_L.ADD_TAG_ERROR'), '', {
                duration: 1000
              });
            }
          })
          .catch(error => {
            LoadingComponent.hideStaticLoading();
            this.errorBar.open(this.translateService.instant('CONTENT_L.ADD_TAG_ERROR'), '', {
              duration: 1000
            });
          });
      }
    });

  }

  deleteBalk() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        message: this.translateService.instant('CONTENT_L.DELETE_BALK_DIALOG.CONTENT'),
        positiveButton: this.translateService.instant('CONTENT_L.DELETE_BALK_DIALOG.DELETE_ALL'),
        negativeButton: this.translateService.instant('CONTENT_L.DELETE_BALK_DIALOG.UNDO')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const selectedContentsSlug: string[] = [];
        const selectedContents: RowContent[] = [];
        for (const rc of this.contentsList) {
          if (rc.selected) {
            selectedContentsSlug.push(rc.slug);
            selectedContents.push(rc);
          }
        }
        LoadingComponent.showLoading(this.dialog);
        this.contentService.deleteBalk(selectedContentsSlug)
          .then(res => {
            LoadingComponent.hideStaticLoading();
            if (res) {
              this.errorBar.open(this.translateService.instant('CONTENT_L.DELETE_BALK_SUCCESS'), '', {
                duration: 1000
              });
              for (const selected of selectedContents) {
                console.log(this.contentsList);
                this.contentsList.splice(this.contentsList.indexOf(selected), 1);
                console.log(this.contentsList);
                this.dataSource = new MatTableDataSource(this.contentsList as RowContent[]);
              }
            } else {
              this.errorBar.open(this.translateService.instant('CONTENT_L.DELETE_BALK_ERROR'), '', {
                duration: 1000
              });
            }
          })
          .catch(error => {
            LoadingComponent.hideStaticLoading();
            this.errorBar.open(this.translateService.instant('CONTENT_L.DELETE_BALK_ERROR'), '', {
              duration: 1000
            });
          });
      }
    });
  }

  deleteTag(element: RowContent, tags: string[], tag: string) {
    LoadingComponent.showLoading(this.dialog);
    tags.splice(tags.indexOf(tag), 1);
    this.contentService.deleteBalkTag(element.slug, tags)
      .then(newItem => {
        LoadingComponent.hideStaticLoading();
        element.tags = tags;
      })
      .catch(error => {
        LoadingComponent.hideStaticLoading();
      });
  }
}

interface RowContent extends IContent {
  resolutionsList: string[];
  svgIcon: string;
  pathIcon: string;
  tooltipType: string;
  selected: boolean;
  tags: any[];
}


