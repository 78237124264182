import {NgModule} from '@angular/core';
import {CustomerComponent} from './customer/customer.component';
import {AuthGuard} from '../../../shared/guard/auth-guard';
import {SharedModule} from '../../../shared/shared.module';
import {RouterModule} from '@angular/router';
import {CustomerService} from './customer.service';
import {NewCustomerDialogComponent} from './customer/customer-dialog/customer-dialog.component';

const routes = [
  {
    path: 'customer',
    canActivate: [AuthGuard],
    component: CustomerComponent
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
  ],
  declarations: [
    CustomerComponent,
    NewCustomerDialogComponent
  ],
  providers: [
    CustomerService
  ],
  entryComponents: [
    NewCustomerDialogComponent
  ]
})
export class CustomerModule {
}
