export class IoModel {
  key: string;
  values: string[] = [];
  disabled = false;

  static parse(ioString: string): IoModel[] {
    const ios: IoModel[] = [];
    if (ioString) {
      const items = JSON.parse(ioString);
      for (const item of items) {
        ios.push({
          key: item.key,
          values: item.values,
          disabled: false
        });
      }
    }
    return ios;
  }

  static stringify(ios: IoModel[]): string {
    if (ios.length === 0) {
      return null;
    } else {
      const tmp = [];
      for (const io of ios) {
        if (io.key) {
          tmp.push({
            key: io.key,
            values: io.values
          });
        }
      }
      return JSON.stringify(tmp);
    }
  }
}
