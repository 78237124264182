import {Component, OnDestroy, OnInit} from '@angular/core';
import {GraphService} from '../../../network/graph.service';
import {FuseConfigService} from '../../../../../@fuse/services/config.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {CustomerService} from '../customer.service';
import {ActivatedRoute, Router} from '@angular/router';

import ICreateCustomerInput = AES.ICreateCustomerInput;
import IMe = AES.IMe;
import ICustomer = AES.ICustomer;
import {NewCustomerDialogComponent} from './customer-dialog/customer-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import {interval} from 'rxjs';
import * as _ from 'lodash';
import RoleName = AES.RoleName;

@Component({
  selector: 'aesys-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit, OnDestroy {

  private meObserver = null;
  loader: any;
  INTERVAL = 5000;
  me: IMe = null;
  canActivateFlag = false;
  canOpen = false;
  activeCustomers: ICustomer[] = [];
  deactiveCustomers: [string, string][] = [];

  constructor(
    private fuseConfig: FuseConfigService,
    private graph: GraphService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    public snackBar: MatSnackBar
  ) {
    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });


  }

  ngOnInit() {
    setTimeout(() => {
      this.update(true);
    }, 1);

  }

  private update(firstTime?: boolean) {
    if (firstTime) {
      this.loader = LoadingComponent.showLoading(this.dialog);
    }

    this.customerService.getCustomers(true)
      .then(me => {
        LoadingComponent.hideLoading(this.loader);

        this.me = me;
        this.activeCustomers = _.orderBy(me.customers, 'name');

        for (const role of this.me.roles) {
          if (role.name === RoleName.ADMIN || role.name === RoleName.CUSTOMER_ADMIN || role.name === RoleName.CUSTOMER_USER) {
            this.canOpen = true;
          }
        }

        if (this.checkAdminRole()) {
          this.canActivateFlag = true;
        } else {
          for (const cust of this.me.customers) {
            if (cust.slug === this.me.own_customer && cust.is_active === true) {
              this.open(cust);
            }
          }
        }
        this.deactiveCustomers = _.orderBy(this.getDeactiveCustomer(), 0) as any;
      })
      .catch(() => {

      });
  }

  getDeactiveCustomer(): [string, string][] {
    const customers: [string, string][] = [];
    for (const id of this.me.customers_visibility) {
      let add = true;
      let name = '';
      for (const cust of this.me.customers) {
        if (cust.aesys_customer_id === id) {
          add = false;
          name = cust.name;
        }
      }
      if (add) {
        customers.push([id, name]);
      }
    }
    return customers;
  }

  active(cust: [string, string]) {
    if (this.canActivateFlag) {
      const dialogRef = this.dialog.open(NewCustomerDialogComponent, {
        width: '350px',
        data: {
          id: cust[0],
          custName: cust[1]
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const cust: ICreateCustomerInput = {
            aesys_customer_id: result.id,
            name: result.name,
            quota: result.quota,
            bucket_location: result.bucket
          };
          this.customerService.createCustomer(cust)
            .then(cutomer => {
              this.update();
              this.updateCustomer();
            })
            .catch(error => {
              this.snackBar.open(this.translateService.instant('CUSTOMER.ERR_CUSTOMER_CREATE'), '', {
                duration: 1000,
              });
            });
        }
      });
    } else {
      this.dialog.open(GenericDialogComponent, {
        width: '350px',
        data: {
          message: this.translateService.instant('CUSTOMER.DIALOG_ONLY_ADMIN_CAN_ACTIVATE_MSG'),
          positiveButton: this.translateService.instant('CUSTOMER.DIALOG_UNDO')
        }
      });
    }
  }

  open(cust: ICustomer) {
    this.router.navigate(['/customer/' + cust.slug]);
  }

  updateCustomer() {
    if (this.meObserver === null) {
      this.meObserver = interval(this.INTERVAL).subscribe((x) => {
        this.update();
      });
    }
  }

  checkAdminRole(): boolean {
    for (const role of this.me.roles) {
      if (role.name === RoleName.ADMIN || role.name === RoleName.CUSTOMER_ADMIN) {
        return true;
      }
    }
    return false;
  }

  logout() {
    this.graph.logout(this.router);

  }

  ngOnDestroy(): void {
    if (this.meObserver !== null) {
      this.meObserver.unsubscribe();
    }
  }

}
