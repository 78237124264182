import {Pipe, PipeTransform} from '@angular/core';
import {StepIoModel} from '../../../../../network/data/step-io.model';

@Pipe({name: 'getOutputValue'})
export class GetOutputValueByKeyPipe implements PipeTransform {
  transform(outputs: StepIoModel[], key: string): string {
    for (const o of outputs) {
      if (o.key === key) {
        return o.value;
      }
    }
    return 'nill';
  }
}
