import {Injectable} from '@angular/core';
import {GraphService} from '../../../network/graph.service';
import IMe = AES.IMe;
import gql from 'graphql-tag';

@Injectable()
export class ToolbarService {

  constructor(
    private graphService: GraphService
  ) {
  }

  // QUERIES
  public getCustomers(force: boolean): Promise<IMe> {
    return new Promise<any>((resolve, reject) => {
      if (!this.graphService.getTokenManager().isTokenValid()) {
        reject();
      } else {
        this.graphService.getApollo().query<any>({
          query: getCustomers,
          context: {
            uri: this.graphService.getGraphqlUrl() + '?ToolbarService.getCustomers'
          },
          fetchPolicy: 'network-only'
        }).subscribe(
          response => {
            resolve(response.data.getMe);
          },
          error => {
            reject(error);
          });
      }
    });
  }

  public readNotification(notidications: string[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.graphService.getApollo().mutate<any>({
        mutation: readNotification,
        context: {
          uri: this.graphService.getGraphqlUrl() + '?ToolbarService.readNotification'
        },
        variables: {
          mrni: {
            notifications: notidications
          }
        }
      }).subscribe(
        response => {
          resolve();
        });
    });
  }

  public getNotification(): Promise<IMe> {
    return new Promise<IMe>((resolve, reject) => {
      if (this.graphService.getTokenManager().isTokenValid()) {
        this.graphService.getApollo().query<any>({
          query: getNotification,
          context: {
            uri: this.graphService.getGraphqlUrl() + '?ToolbarService.getNotification'
          },
          fetchPolicy: 'no-cache'
        }).subscribe(
          response => {
            resolve(response.data.getMe);
          });
      } else {
        reject();
      }
    });
  }
}

const getCustomers = gql`
  query {
    getMe{
      slug
      name
      email
      own_customer
      customers{
        slug
        aesys_customer_id
        name
        is_ready
        is_active
      }
      customers_visibility
      roles{
        name
      }
    }
  }
`;

const readNotification = gql`
  mutation readNotification($mrni: MarkAsReadNotificationsInput!){
    markAsReadNotifications(input: $mrni)
  }
`;

const getNotification = gql`
  query {
    getMe{
      notifications(unreadOnly: true) {
        id
        __typename
        ... on MessageNotification {
          data {
            operation_type
            operation_status
            customer_slug
            model {
              __typename
              slug
              ... on Asset {
                model{
                  slug
                }
              }
            }
          }
        }
      }
      customers {
        slug
        name
      }
    }
  }
`;
