import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return this.convert(value, args);
  }

  /**
   * - Usage of format pipe in html:
   * | format:['text to replace'],
   *
   * - String placeholder format:
   * string = 'the text in args array will replace this placeholder: {0}'
   *
   * The number between curly braces represent the array position of the text to replace
   *
   * @param {string} text - original text
   * @param {string[]} args - array of strings to replace in original text
   * @returns {string} - return original text with replaced placeholders
   */
  private convert(text: string, args?: string[]): string {
    let formatted = text;
    for (let i = 0; i < arguments.length; i++) {
      const regexp = new RegExp('\\{' + i + '\\}', 'gi');
      formatted = formatted.replace(regexp, args[i]);
    }
    return formatted;
  }

}
