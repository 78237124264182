import {Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute} from '@angular/router';
import {Injectable} from '@angular/core';
import {GraphService} from '../../main/network/graph.service';
import {Observable, Observer} from 'rxjs';

@Injectable()
export class CustomerResolver implements Resolve<string> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      if (route.params.custId) {
        this.graphService.setCurrentCustomer(route.params.custId);
        observer.next(route.params.custId);
        observer.complete();
      } else {
        this.router.navigateByUrl('/customer');
      }

    });
  }

  constructor(private route: ActivatedRoute, private router: Router, private graphService: GraphService) {
  }
}
