import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard/dashboard.component';
import {RouterModule} from '@angular/router';
import {AuthGuard} from '../../../shared/guard/auth-guard';
import {CustomerResolver} from '../../../shared/resolver/customer.resolver';
import {SharedModule} from '../../../shared/shared.module';
import {DashboardService} from './dashboard.service';
import {ClipboardModule} from 'ngx-clipboard';
import {NgxChartsModule} from '@swimlane/ngx-charts';

const routes = [
  {
    path: 'customer/:custId',
    redirectTo: 'customer/:custId/dashboard'
  },
  {
    path: 'customer/:custId/dashboard',
    canActivate: [AuthGuard],
    resolve: {
      customer: CustomerResolver
    },
    component: DashboardComponent
  }
];

@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    SharedModule,
    NgxChartsModule,
    RouterModule.forChild(routes),
    ClipboardModule
  ],
  providers: [
    AuthGuard,
    DashboardService
  ],
  exports: [
    DashboardComponent
  ]
})
export class DashboardModule {
}
