import {NgModule} from '@angular/core';
import {SettingComponent} from './setting/setting.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';

const routes = [
  {
    path: 'settings',
    component: SettingComponent
  }
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes)
  ],
  declarations: [
    SettingComponent
  ]
})
export class SettingsModule {
}
