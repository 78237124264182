import {FuseMaterialColorPickerModule} from '../../../../@fuse/components/material-color-picker/material-color-picker.module';
import {NgModule, Provider} from '@angular/core';
import {PlaylistsService} from './playlists.service';
import {PlaylistListComponent} from './playlist-list/playlist-list.component';
import {PlaylistDetailComponent} from './playlist-detail/playlist-detail.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {CustomerResolver} from '../../../shared/resolver/customer.resolver';
import {AuthGuard} from '../../../shared/guard/auth-guard';
import {PlContentComponent} from './playlist-detail/pl/pl-content/pl-content.component';
import {PlSurfaceComponent} from './playlist-detail/pl/pl-surface/pl-surface.component';
import {PlStepComponent} from './playlist-detail/pl/pl-step/pl-step.component';
import {PlLayoutDialogComponent} from './playlist-detail/pl/pl-layout-dialog/pl-layout-dialog.component';
import {PlDialogDurationComponent} from './playlist-detail/pl/pl-content/pl-dialog-duration/pl-dialog-duration.component';
import {PlGradedComponent} from './playlist-detail/pl-graded/pl-graded.component';
import {PlTransitionDialogComponent} from './playlist-detail/pl/pl-transition-dialog/pl-transition-dialog.component';
import { PlPreviewDialogComponent } from './playlist-detail/pl-preview-dialog/pl-preview-dialog.component';
import {LayoutManagerModule} from '../layout-manager/layout-manager.module';
import {HasOutputPipe} from './playlist-detail/pl/pl-step/has-output.pipe';
import { PlEditOutputDialogComponent } from './playlist-detail/pl/pl-step/pl-edit-output-dialog/pl-edit-output-dialog.component';
import {GetOutputValueByKeyPipe} from './playlist-detail/pl/pl-step/get-output-value-by-key.pipe';


const routes = [
  {
    path: 'customer/:custId/playlists',
    canActivate: [AuthGuard],
    resolve: {
      customer: CustomerResolver
    },
    component: PlaylistListComponent
  },
  {
    path: 'customer/:custId/playlists/:playlistId',
    canActivate: [AuthGuard],
    resolve: {
      customer: CustomerResolver
    },
    component: PlaylistDetailComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    FuseMaterialColorPickerModule,
    LayoutManagerModule
  ],
  declarations: [

    PlaylistListComponent,
    PlaylistDetailComponent,

    PlStepComponent,
    PlContentComponent,
    PlSurfaceComponent,

    PlLayoutDialogComponent,
    PlDialogDurationComponent,
    PlGradedComponent,
    PlTransitionDialogComponent,
    PlPreviewDialogComponent,

    HasOutputPipe,
    GetOutputValueByKeyPipe,

    PlEditOutputDialogComponent

  ],
  entryComponents: [
    PlStepComponent,
    PlContentComponent,
    PlSurfaceComponent,

    PlLayoutDialogComponent,
    PlDialogDurationComponent,
    PlTransitionDialogComponent,
    PlPreviewDialogComponent,
    PlEditOutputDialogComponent
  ],
  providers: [

    PlaylistsService
  ]
})
export class PlaylistsModule {
}
