import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Component, Inject} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AesysFormControl} from '../../../../../util/form/AesysFormControl';
import BucketLocation = AES.BucketLocation;

@Component({
  selector: 'aesys-dialog-new-customer',
  templateUrl: './customer-dialog.component.html',
})
export class NewCustomerDialogComponent {

  bucketFormControll = new FormControl('', [
    Validators.required
  ]);

  quotaFormControll = new FormControl('', [
    Validators.required
  ]);

  formName = '';
  formQuota = '';
  formBucket = '';

  buckets: ([string, BucketLocation])[] = [];

  // bucket = BucketLocation;

  constructor(
    public dialogRef: MatDialogRef<NewCustomerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    ) {

    this.formName = this.data.id;
    this.buckets.push(['EU', BucketLocation.EU]);
    this.buckets.push(['US', BucketLocation.US]);
  }

  close(): void {
    this.dialogRef.close();
  }

  create() {
    if (this.formName !== '' && this.formQuota !== '' && this.formBucket !== '') {
      this.data.name = this.formName;
      this.data.quota = (Number(this.formQuota) * 1024 * 1024 * 1024);
      this.data.bucket = this.formBucket;
      this.dialogRef.close(this.data);
    }
  }

}
