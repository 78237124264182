import {AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn} from '@angular/forms';
import {FormError} from './form-error.model';
import {TranslateService} from '@ngx-translate/core';

export class AesysFormControl extends FormControl {

  private formError: FormError = new FormError();

  constructor(private field: string,
              private translateService: TranslateService,
              formState?: any,
              validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
              asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null) {
    super(formState, validatorOrOpts, asyncValidator);

    this.valueChanges.subscribe(
      value => {
        for (const e of this.formError.error) {
          if (e[0] === field) {
            this.formError.error.splice(this.formError.error.indexOf(e, 0), 1);
            this.setErrors({'incorrect': false});
          }
        }
      }
    );
  }

  public setCustomError(error: FormError) {
    this.formError = error;
  }

  public hasCustomError() {
    for (const e of this.formError.error) {
      if (e[0] === this.field) {
        this.setErrors({'incorrect': true});
        return true;
      }
    }
    return false;
  }

  public getTranslatedError(): string {
    for (const e of this.formError.error) {
      if (e[0] === this.field) {
        let detail = '';
        for (const eKey of e[1]) {
          const translated = this.translateService.instant('FORM_' + eKey);
          detail = detail + ' ' + translated;
        }
        return detail;
      }
    }
  }

}
