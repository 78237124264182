import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';

@Component({
  selector: 'aesys-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {

  private static loader: any = null;

  public static showLoading(dialog: MatDialog): any {
    this.hideStaticLoading();
    const loader: any = dialog.open(LoadingComponent, {
      disableClose: true
    });

    (loader._containerInstance as any)._elementRef.nativeElement.style.backgroundColor = 'transparent';
    (loader._containerInstance as any)._elementRef.nativeElement.style.boxShadow = 'none';
    this.loader = loader;
    return loader;
  }

  public static hideLoading(loader: any) {
    if (loader != null) {
      loader.close();
      loader = null;
    }
  }

  public static hideStaticLoading() {
    if (this.loader != null) {
      this.loader.close();
    }
  }

  constructor(public dialogRef: MatDialogRef<void>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
