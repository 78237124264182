import {AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, QueryList, ViewChildren, ViewContainerRef} from '@angular/core';

import {PlContentComponent} from '../pl-content/pl-content.component';
import {PlSurfaceModel} from '../model/pl-surface.model';
import {PlContentModel} from '../model/pl-content.model';
import IStepitem = AES.IStepitem;
import {PlManager} from '../pl-manager';
import {PlaylistsService} from '../../../playlists.service';
import {MatDialog} from '@angular/material';
import {PlStepModel} from '../model/pl-step.model';

@Component({
  selector: 'aesys-pl-surface',
  templateUrl: './pl-surface.component.html',
  styleUrls: ['./pl-surface.component.css'],
})
export class PlSurfaceComponent implements OnInit, AfterViewInit {

  @Output() deleteContent = new EventEmitter<PlContentModel>();
  @Output() updatedContent = new EventEmitter<PlContentModel>();
  @ViewChildren('surfaceInsert', {read: ViewContainerRef}) surfaceInsert: QueryList<ViewContainerRef>;
  loader: any;

  surface: PlSurfaceModel;
  step: PlStepModel;
  stepItems: IStepitem[] = [];
  plManager: PlManager;

  constructor(
    private plService: PlaylistsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public dialog: MatDialog,
  ) {
  }

  ngAfterViewInit(): void {
    this.surface.containerRef = this.surfaceInsert.first;

    setTimeout(() => {
      for (const si of this.stepItems) {
        if (si.surface.slug === this.surface.surface.slug) {
          this.addContent(this.surface, si);
        }
      }
    }, 1);
  }

  private addContent(surface: PlSurfaceModel, stepItem: IStepitem) {
    const plC = this.componentFactoryResolver.resolveComponentFactory(PlContentComponent);
    const cRef = surface.containerRef.createComponent(plC);
    const dComponent = <PlContentComponent>cRef.instance;
    const plContent: PlContentModel = new PlContentModel(stepItem.content);
    plContent.cRef = cRef;
    plContent.surfaceId = surface.id;
    plContent.stepItem = stepItem;
    plContent.style = this.surface.style;
    plContent.order = stepItem.order;
    plContent.duration = stepItem.duration;
    this.surface.plContents.push(plContent);
    dComponent.plManager = this.plManager;
    dComponent.content = plContent;
    this.init(dComponent);
  }

  public init(dComponent: PlContentComponent) {
    dComponent.deleteContent
      .subscribe(plContent => {
        this.deleteContent.emit(plContent);
      });

    dComponent.updatedContent
      .subscribe(plContent => {
        this.updatedContent.emit(plContent);
      });
  }

  ngOnInit() {
  }

  getTotalStepItem(): number {
    let tot = 0;
    for (const s of this.plManager.getStepFromId(this.step.id).surfaces) {
      tot += s.plContents.length;
    }
    return tot;
  }

}
