import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSelectChange, MatSnackBar} from '@angular/material';
import {LayoutManagerListComponent} from '../layout-manager-list/layout-manager-list.component';
import {LayoutManagerService} from '../layout-manager.service';
import * as _ from 'lodash';
import ILayout = AES.ILayout;
import ISurface = AES.ISurface;
import {IoModel} from '../../../network/data/io.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {TranslateService} from '@ngx-translate/core';
import LayoutType = AES.LayoutType;
import {LayoutTypeEnum} from '../../../network/interface/graph-enum.model';
import ICondition = AES.ICondition;

@Component({
  selector: 'aesys-layout-manager-detail',
  templateUrl: './layout-manager-detail.component.html',
  styleUrls: ['./layout-manager-detail.component.scss']
})
export class LayoutManagerDetailComponent implements OnInit, AfterViewInit {

  layoutType = LayoutTypeEnum;
  requiredValidator = Validators.compose([Validators.required]);
  surfaceValidator = Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')]);

  remoteIos: IoModel[] = [];
  // DATA
  private remoteSourceIo: IoModel[] = [];
  private ioRefreshed = false;
  layout: ILayout;
  ios: IoModel[] = [];

  // FORM
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  canEdit = false;
  layoutForm: FormGroup;

  // VIEW
  name = '';

  constructor(
    private _formBuilder: FormBuilder,
    private layoutService: LayoutManagerService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<LayoutManagerListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.layout) {
      this.layout = _.clone(data.layout);
      this.name = this.layout.name;
    } else {
      this.layout = new class implements ILayout {
        __typename: 'Layout';
        created_at: number;
        io: string;
        is_used = false;
        name: string;
        slug: string;
        steps: Array<AES.IStep>;
        surfaces: Array<AES.ISurface>;
        type: LayoutType.TFT;
        updated_at: number;
      };
    }
    if (!this.layout.surfaces) {
      this.layout.surfaces = [];
    }

    this.ios = IoModel.parse(this.layout.io);

    this.layoutForm = this._formBuilder.group({
      name: [this.layout.name ? this.layout.name : '', Validators.required],
      type: [this.layout.type ? this.layout.type : LayoutType.TFT, Validators.required],
      surface: [],
      io: []
    });

    this.addIoValidator();
    this.addSurfaceValidator();

    if (this.layout.is_used) {
      this.canEdit = false;
      this.layoutForm.disable();
    } else {
      this.canEdit = true;
    }

  }

  ngOnInit(): void {
    LoadingComponent.showLoading(this.dialog);
    this.layoutService.getIO()
      .then(io => {
        this.remoteIos = [];
        for (const i of io) {
          if (i.values.length > 0) {
            let add = true;
            for (const ci of this.ios) {
              if (ci.key === i.key) {
                add = false;
                this.remoteIos.push(ci);
              }
            }
            if (add) {
              this.remoteIos.push({
                key: i.key,
                values: i.values,
                disabled: false
              });
            }
            this.remoteSourceIo.push({
              key: i.key,
              values: i.values,
              disabled: false
            });
          }
        }
        for (const io of this.ios) {
          let add = false;
          for (const rio of this.remoteIos) {
            if (rio.key === io.key) {
              add = true;
            }
          }
          if (!add) {
            this.remoteIos.push(io);
          }
        }
        this.checkIO();
        LoadingComponent.hideStaticLoading();
      })
      .catch(error => {
        this.dialogRef.close();
        LoadingComponent.hideStaticLoading();
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.layout.surfaces.length === 0) {
        this.addSurface();
      }
    });
  }

  saveLayout() {
    LoadingComponent.showLoading(this.dialog);
    this.layoutService.saveUpdateLayout(this.layout, this.ios)
      .then(layout => {
        LoadingComponent.hideStaticLoading();
        this.snackBar.open(this.translateService.instant('LAYOUT_D.LAYOUT_UPDATED'), '', {
          duration: 1000,
        });
        this.dialogRef.close(layout);
      })
      .catch(error => {
        LoadingComponent.hideStaticLoading();
      });
  }

  addSurface(event?: any) {
    // if keyboard continue
    if (event && event.screenX === 0) {
      return;
    }
    this.layout.surfaces.push(new class implements ISurface {
      __typename: 'Surface';
      slug: string;
      height: number;
      width: number;
      x: number;
      y: number;
      preview_x: number;
      preview_y: number;
      layout: ILayout;
      order: number;
      created_at: number;
      updated_at: number;
    });
    this.addSurfaceValidator();
  }

  deleteSurface(i: number) {
    this.layout.surfaces.splice(i, 1);
    if (this.layout.surfaces.length === 0) {
      this.addSurface();
    }
    setTimeout(() => {
      this.addSurfaceValidator();
    });
  }

  addIO(event: any) {
    // if keyboard continue
    if (event.screenX === 0) {
      return;
    }
    this.ios.push(new IoModel());
    this.addIoValidator();
  }

  removeIoValue(value: string, io: IoModel, i: any) {
    const index = io.values.indexOf(value);

    if (index >= 0) {
      io.values.splice(index, 1);
    }
    this.layoutForm.get('io').get('ioValues' + i).markAsTouched({onlySelf: true});
    if (io.values.length > 0) {
      this.layoutForm.get('io').get('ioValues' + i).setErrors(null);
    } else {
      this.layoutForm.get('io').get('ioValues' + i).setErrors({'required': true});
    }
  }

  addIoValue(event: any, io: IoModel, i: any) {
    const input = event.input;
    const value = event.value;

    const index = io.values.indexOf(value);

    if (index < 0) {
      if ((value || '').trim()) {
        io.values.push(value.trim());
      }
    }
    if (input) {
      input.value = '';
    }
    if (io.values.length > 0) {
      this.layoutForm.get('io').get('ioValues' + i).setErrors(null);
    } else {
      this.layoutForm.get('io').get('ioValues' + i).setErrors({'required': true});
    }
  }

  deleteIo(i: number) {
    this.ios.splice(i, 1);
    setTimeout(() => {
      this.addIoValidator();
    });
  }

  ioSelectionChange(selectChange: MatSelectChange, index: number) {
    this.ios[index] = (selectChange.value as IoModel);
    this.checkIO();
  }

  refreshIO() {
    this.ioRefreshed = true;
    this.remoteIos = this.remoteSourceIo;
    const tmpIO: IoModel[] = [];
    for (const io of this.ios) {
      for (const rio of this.remoteIos) {
        if (rio.key === io.key) {
          tmpIO.push(rio);
        }
      }
    }
    this.ios = tmpIO;
    this.checkIO();
  }

  private addSurfaceValidator() {
    this.layoutForm.removeControl('surface');
    const surfaceGroup: FormGroup = new FormGroup({});
    for (let i = 0; i < this.layout.surfaces.length; i++) {

      const controlWidth: FormControl = new FormControl(this.layout.surfaces[i].width, this.surfaceValidator);
      const controlHeight: FormControl = new FormControl(this.layout.surfaces[i].height, this.surfaceValidator);
      const controlX: FormControl = new FormControl(this.layout.surfaces[i].x, this.surfaceValidator);
      const controlY: FormControl = new FormControl(this.layout.surfaces[i].y, this.surfaceValidator);
      const controlPreviewX: FormControl = new FormControl(this.layout.surfaces[i].preview_x, this.surfaceValidator);
      const controlPreviewY: FormControl = new FormControl(this.layout.surfaces[i].preview_y, this.surfaceValidator);

      surfaceGroup.addControl('surfaceWidth' + i, controlWidth);
      surfaceGroup.addControl('surfaceHeight' + i, controlHeight);
      surfaceGroup.addControl('surfaceX' + i, controlX);
      surfaceGroup.addControl('surfaceY' + i, controlY);
      surfaceGroup.addControl('surfacePreviewX' + i, controlPreviewX);
      surfaceGroup.addControl('surfacePreviewY' + i, controlPreviewY);
    }
    this.layoutForm.addControl('surface', surfaceGroup);
    this.checkIO();

  }

  private addIoValidator() {
    this.layoutForm.removeControl('io');
    const ioGroup: FormGroup = new FormGroup({});
    for (let i = 0; i < this.ios.length; i++) {
      const controlKey: FormControl = new FormControl(this.ios[i], this.requiredValidator);
      const controlValues: FormControl = new FormControl(this.ios[i].values);

      ioGroup.addControl('ioKey' + i, controlKey);
      ioGroup.addControl('ioValues' + i, controlValues);
    }
    this.layoutForm.addControl('io', ioGroup);
    this.checkIO();
  }

  private checkIO() {
    for (const io of this.remoteIos) {
      let disabled = false;
      for (const currentIO of this.ios) {
        if (currentIO.key === io.key) {
          disabled = true;
        }
      }
      io.disabled = disabled;
    }
  }
}

export function chipsListNotEmpty(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return control.value.length > 0 ? null : {'required': true};
  };
}
