import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import 'hammerjs';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {fuseConfig} from './fuse-config';
import {AppComponent} from './app.component';
import {FuseMainModule} from './main/layout/main/main.module';
import {SharedModule} from './shared/shared.module';
import {CustomerResolver} from './shared/resolver/customer.resolver';
import {GraphService} from './main/network/graph.service';
import {ApolloModule} from 'apollo-angular';
import {CommonModule} from '@angular/common';
import {HttpLinkModule} from 'apollo-angular-link-http';
import {UploadModule} from './main/layout/layout-component/upload/upload.module';
import {AppInterceptor} from './util/app-interceptor';
import {NotificationPollingService} from './util/notification-polling.service';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

const appRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/customer'
  },
  {
    path: '**',
    redirectTo: '/customer'
  }
];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/app/', '.json?v=3');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  providers: [
    GraphService,
    CustomerResolver,
    NotificationPollingService,
    {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true}
  ],
  imports: [
    // generic + lib
    CommonModule,
    ApolloModule,
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpLinkModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: false
    }),
    RouterModule.forRoot(appRoutes),

    // Fuse Main and Shared modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,
    FuseMainModule,

    // app
    UploadModule
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {

  constructor(
    private graph: GraphService,
  ) {
    this.graph.init();
  }
}
