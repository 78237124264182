import {Directive, Input, OnInit, HostListener, OnDestroy, HostBinding} from '@angular/core';
import {MatSidenav} from '@angular/material';
import {MediaObserver} from '@angular/flex-layout';

import {FuseMatchMediaService} from '@fuse/services/match-media.service';
import {FuseMatSidenavHelperService} from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
import {Subscription} from 'rxjs/internal/Subscription';

@Directive({
  selector: '[fuseMatSidenavHelper]'
})
export class FuseMatSidenavHelperDirective implements OnInit, OnDestroy {
  matchMediaSubscription: Subscription;
  @HostBinding('class.mat-is-locked-open') isLockedOpen = true;
  @Input('fuseMatSidenavHelper') id: string;
  @Input('mat-is-locked-open') matIsLockedOpenBreakpoint: string;

  constructor(
    private fuseMatSidenavService: FuseMatSidenavHelperService,
    private fuseMatchMedia: FuseMatchMediaService,
    private observableMedia: MediaObserver,
    private matSidenav: MatSidenav
  ) {
  }

  ngOnInit() {
    this.fuseMatSidenavService.setSidenav(this.id, this.matSidenav);
    this.update();
    this.matchMediaSubscription = this.fuseMatchMedia.onMediaChange.subscribe(() => {
      this.update();
    });
  }

  private update() {
    if (this.matIsLockedOpenBreakpoint) {
      if (this.observableMedia.isActive(this.matIsLockedOpenBreakpoint)) {
        this.isLockedOpen = true;
        this.matSidenav.mode = 'side';
        this.matSidenav.toggle(true);
      } else {
        this.isLockedOpen = false;
        this.matSidenav.mode = 'over';
        this.matSidenav.toggle(false);
      }
    }
  }

  ngOnDestroy() {
    this.matchMediaSubscription.unsubscribe();
  }
}

@Directive({
  selector: '[fuseMatSidenavToggler]'
})
export class FuseMatSidenavTogglerDirective {
  @Input('fuseMatSidenavToggler') id;

  constructor(private fuseMatSidenavService: FuseMatSidenavHelperService) {
  }

  @HostListener('click')
  onClick() {
    this.fuseMatSidenavService.getSidenav(this.id).toggle();
  }
}
