import {NgModule} from '@angular/core';

import {TimelinesService} from './timelines.service';
import {TimelinesListComponent} from './timelines-list/timelines-list.component';
import {CustomerResolver} from '../../../shared/resolver/customer.resolver';
import {AuthGuard} from '../../../shared/guard/auth-guard';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {TimelinesDetailComponent} from './timelines-detail/timelines-detail.component';
import {DragulaModule} from 'ng2-dragula';
import {TlDialogProgrammingsComponent} from './timelines-detail/tl-dialog-programmings/tl-dialog-programmings.component';
import {TlDialogSchedulingComponent} from './timelines-detail/tl-dialog-programmings/tl-dialog-scheduling/tl-dialog-scheduling.component';
import {TlDialogProgrammingsTabComponent} from './timelines-detail/tl-dialog-programmings/tl-dialog-programmings-tab/tl-dialog-programmings-tab.component';
import {TlSimulatorComponent} from './timelines-detail/tl-simulator/tl-simulator.component';
import {TlSimulatorPriorityComponent} from './timelines-detail/tl-simulator/tl-simulator-priority/tl-simulator-priority.component';
import {TlGradedComponent} from './timelines-detail/tl-simulator/tl-graded/tl-graded.component';
import {TlDialogPlanningComponent} from './timelines-list/tl-dialog-planning/tl-dialog-planning.component';
import {TlPlanningComponent} from './timelines-list/tl-dialog-planning/tl-planning/tl-planning.component';
import {TlSConditionComponent} from './timelines-detail/tl-simulator/tl-s-condition/tl-s-condition.component';

const routes = [
  {
    path: 'customer/:custId/timelines',
    canActivate: [AuthGuard],
    resolve: {
      customer: CustomerResolver
    },
    component: TimelinesListComponent
  },
  {
    path: 'customer/:custId/timelines/:timelineId',
    canActivate: [AuthGuard],
    resolve: {
      customer: CustomerResolver
    },
    component: TimelinesDetailComponent
  }
];

@NgModule({
  declarations: [
    TimelinesListComponent,
    TimelinesDetailComponent,
    TlDialogProgrammingsComponent,
    TlDialogSchedulingComponent,
    TlDialogProgrammingsTabComponent,
    TlSimulatorComponent,
    TlSimulatorPriorityComponent,
    TlGradedComponent,
    TlDialogPlanningComponent,
    TlPlanningComponent,
    TlSConditionComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    SharedModule,
    DragulaModule
  ],
  providers: [
    TimelinesService
  ],
  entryComponents: [
    TlDialogProgrammingsComponent,
    TlDialogPlanningComponent,
    TlSimulatorPriorityComponent,
    TlSConditionComponent
  ]
})
export class TimelinesModule {
}
