import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import ISchedule = AES.ISchedule;
import {TranslateService} from '@ngx-translate/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {DateAdapter, ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {Moment} from 'moment';
import ICondition = AES.ICondition;
import {LoadingComponent} from '../../../../../../shared/loading/loading.component';
import {MatDialog, MatDialogRef, MatSelect} from '@angular/material';
import {TimelinesService} from '../../../timelines.service';
import {GenericDialogComponent} from '../../../../../../shared/generic-dialog/generic-dialog.component';
import {ConditionValue} from '../../../../../network/data/condition-value.model';
import ScheduleFrequency = AES.ScheduleFrequency;
import {ScheduleFrequencyEnum} from '../../../../../network/interface/graph-enum.model';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM Y',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM Y'
  },
};

@Component({
  selector: 'aesys-tl-dialog-scheduling',
  templateUrl: './tl-dialog-scheduling.component.html',
  styleUrls: ['./tl-dialog-scheduling.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class TlDialogSchedulingComponent implements AfterViewInit {

  @Input() scheduleTs: [number, ISchedule];
  @Input() lastSchedule: boolean;

  @Output()
  delete: EventEmitter<number> = new EventEmitter<number>();

  loader: any = null;

  // comodo
  type = ScheduleFrequencyEnum;

  // form
  scheduleForm: FormGroup;
  dateB = new FormControl(moment());
  dateE = new FormControl(moment());
  beginDate = null;
  endDate = null;
  schedulingType: ScheduleFrequency;

  // data
  schedule: ISchedule = null;
  timestamp: number;
  weeklyDay = 0;
  monthlyDay = null;
  yearlyMonth = null;
  yearlyDay = null;
  fixedStart = ['00', '00'];
  fixedEnd = ['23', '59'];

  conditions: ICondition[] = [];
  selectedConditions: ConditionValue[] = [];

  // Form validators patterns
  matcher = new MyErrorStateMatcher();
  untouchedMatcher = new UntouchedErrorStateMatcher();
  validatorsHourFields = Validators.compose([Validators.required, Validators.min(0), Validators.max(23), Validators.pattern('[0-9]{0,2}')]);
  validatorsMinuteFields = Validators.compose([Validators.required, Validators.min(0), Validators.max(59), Validators.pattern('[0-9]{0,2}')]);
  keyFieldValidator = Validators.compose([Validators.required]);
  valueFieldValidator = Validators.compose([Validators.required]);

  slices: [string, string, string, string, string][] = [];
  weeklyFilter = [false, false, false, false, false, false, false];

  constructor(private translateService: TranslateService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<GenericDialogComponent>,
              private tlService: TimelinesService) {

    // assign to form all form validation constraints
    this.scheduleForm = this.formBuilder.group({
      dates: this.formBuilder.group({
        beginDate: null,
        endDate: null
      }),
      frequency: [null, Validators.compose([Validators.required])],
      monthly: [],
      yearly: [],
      fixed: [],
      slices: [],
      condition: []
    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    });
  }

  private init() {
    if (this.scheduleTs != null) {
      this.timestamp = this.scheduleTs[0];
      this.schedule = this.scheduleTs[1];
    }

    this.tlService.getConditions()
      .then(conditions => {
        LoadingComponent.hideLoading(this.loader);
        this.conditions = conditions;
        this.initFormData();
      })
      .catch(error => {
        LoadingComponent.hideLoading(this.loader);
        this.dialogRef.close();
      });
  }

  private initFormData() {
    if (this.schedule !== null) {
      this.beginDate = this.schedule.from_date_at;
      this.endDate = this.schedule.to_date_at;
      this.schedulingType = this.schedule.frequency;
      this.weeklyDay = this.schedule.weekly_day_of_week;
      this.parseFromWeeklyDays();
      this.monthlyDay = this.schedule.monthly_day_of_month;

      this.yearlyDay = this.schedule.yearly_day;
      this.yearlyMonth = this.schedule.yearly_month;

      this.fixedStart[0] = this.schedule.fixed_from_time_at ? this.schedule.fixed_from_time_at.substr(0, 2) : '';
      this.fixedStart[1] = this.schedule.fixed_from_time_at ? this.schedule.fixed_from_time_at.substr(3, 2) : '';
      this.fixedEnd[0] = this.schedule.fixed_to_time_at ? this.schedule.fixed_to_time_at.substr(0, 2) : '';
      this.fixedEnd[1] = this.schedule.fixed_to_time_at ? this.schedule.fixed_to_time_at.substr(3, 2) : '';

      for (const slice of this.schedule.timeslices) {
        this.slices.push([
          slice.from_time_at.substr(0, 2),
          slice.from_time_at.substr(3, 2),
          slice.to_time_at.substr(0, 2),
          slice.to_time_at.substr(3, 2),
          slice.slug]);
      }

      // INIT CONDITIONS

      if (this.schedule.conditions) {
        this.selectedConditions = [];
        const conditions = JSON.parse(this.schedule.conditions);
        if (conditions.length > 0) {
          for (const fc of conditions[0]) {
            const cond = {
              key: null,
              value: null,
              options: [],
              showOptions: [],
              showWarning: false,
              exclusive: false
            };
            this.selectedConditions.push(cond);
            let conditionAdd = false;
            for (const condition of this.conditions) {
              if (condition.key === fc.key) {
                conditionAdd = true;
              }
            }
            if (!conditionAdd) {
              this.conditions.push({
                __typename: 'Condition',
                key: fc.key,
                values: [],
                exclusive: false,
                is_action: false
              });
            }
            this.conditionKeyChange(cond, fc.key);
            for (const condition of this.conditions) {
              if (condition.key === fc.key) {
                let valueAdd = false;
                for (const values of condition.values) {
                  if (values === fc.value) {
                    valueAdd = true;
                  }
                }
                if (!valueAdd) {
                  cond.showOptions = [...condition.values, fc.value];
                }
              }
            }
            this.conditionValueChange(cond, fc.value);
          }
        }
      } else {
        this.selectedConditions = [];
      }

    }
    this.initForm();
  }

  initForm() {
    this.scheduleForm.controls['frequency'].setValue(this.schedulingType);

    this.addConditionValidator();
    this.scheduleFrenquencyChange(this.schedulingType);

  }

  deleteSchedule() {
    this.delete.emit(this.timestamp);
  }

  addSlice(sHH = '', sMM = '', eHH = '', eMM = '') {
    this.slices.push([sHH, sMM, eHH, eMM, null]);
    this.addSlicesValidationControls();
  }

  deleteSlice(index: number) {
    if (this.slices.length > 1) {
      if (index > -1 && index < this.slices.length) {
        this.slices.splice(index, 1);
        this.addSlicesValidationControls();
      }
    }
  }

  public addCondition() {
    this.selectedConditions.push({
      key: null,
      value: null,
      options: [],
      showOptions: [],
      showWarning: false,
      exclusive: false
    });
    this.addConditionValidator();
  }

  deleteCondition(index: number) {
    this.selectedConditions.splice(index, 1);
    setTimeout(() => {
      this.addConditionValidator();
    });
  }

  scheduleFrenquencyChange(value: any) {

    if (value === ScheduleFrequency.FIXED) {
      this.addDateTimeValidator();
      this.removeSlicesValidationControls();
    } else {
      this.addSlicesValidationControls();
      if (this.slices.length == 0) {
        this.addSlice('00', '00', '23', '59');
      }
      this.addDateValidator();
      switch (value) {
        case ScheduleFrequency.DAILY:
        case ScheduleFrequency.WEEKLY:
          this.removeMonthlyValidationControls();
          this.removeYearlyValidationControls();
          break;
        case ScheduleFrequency.MONTHLY:
          this.addMonthlyValidationControls();
          this.removeYearlyValidationControls();
          break;
        case ScheduleFrequency.YEARLY:
          this.removeMonthlyValidationControls();
          this.addYearlyValidationControls();
          break;
      }
    }
  }

  parseFromWeeklyDays() {
    if (this.weeklyDay != null && this.weeklyDay != undefined) {
      const binaryWeek = this.weeklyDay.toString(2);
      let binaryCounter = 0;
      for (let i = 0; i < this.weeklyFilter.length; i++) {
        if (i < this.weeklyFilter.length - binaryWeek.length) {
          this.weeklyFilter[i] = false;
        } else {
          this.weeklyFilter[i] = binaryWeek.charAt(binaryCounter) != '0';
          binaryCounter++;
        }
      }
    } else {
      this.weeklyFilter = [false, false, false, false, false, false, false];
    }
  }

  parseToWeeklyDays(): number {
    let binaryResult = '';
    for (const item of this.weeklyFilter) {
      if (item) {
        binaryResult += '1';
      } else {
        binaryResult += '0';
      }
    }
    const result = parseInt(binaryResult, 2);
    return result;
  }

  selectWeeklyDay(index: number) {
    this.weeklyFilter[index] = !this.weeklyFilter[index];
  }

  isPristine(): boolean {
    return this.scheduleForm.pristine;
  }

  getUpdatedSchedule(): any {

    if (this.scheduleForm.invalid) {
      this.validateAllFields(this.scheduleForm);
      return null;
    }
    const syncSchedule: any = {};
    if (this.schedule !== null) {
      syncSchedule.slug = this.schedule.slug;
    }
    syncSchedule.is_exception = false;
    syncSchedule.frequency = this.schedulingType;
    syncSchedule.from_date_at = this.beginDate;
    syncSchedule.to_date_at = this.endDate;

    switch (this.schedulingType) {
      case ScheduleFrequency.YEARLY:
        syncSchedule.yearly_day = this.yearlyDay;
        syncSchedule.yearly_month = this.yearlyMonth;
        break;
      case ScheduleFrequency.MONTHLY:
        syncSchedule.monthly_day_of_month = this.monthlyDay;
        break;
      case ScheduleFrequency.WEEKLY:
        syncSchedule.weekly_day_of_week = this.parseToWeeklyDays();
        break;
      case ScheduleFrequency.DAILY:
        break;
      case ScheduleFrequency.FIXED:
        syncSchedule.fixed_from_time_at = this.fixedStart[0] + ':' + this.fixedStart[1] + ':00';
        syncSchedule.fixed_to_time_at = this.fixedEnd[0] + ':' + this.fixedEnd[1] + ':00';
        break;
    }

    syncSchedule.timeslices = [];

    if (this.schedulingType !== ScheduleFrequency.FIXED) {
      for (const slice of this.slices) {
        const s: any = {};
        if (slice[4] !== null) {
          s.slug = slice[4];
        }
        s.from_time_at = slice[0] + ':' + slice[1] + ':00';
        s.to_time_at = slice[2] + ':' + slice[3] + ':00';
        syncSchedule.timeslices.push(s);
      }
    }
    const conditions = [];
    const set = [];
    conditions.push(set);
    for (const condition of this.selectedConditions) {
      const conditionSet = {};
      conditionSet['key'] = condition.key;
      conditionSet['value'] = condition.value;
      set.push(conditionSet);
    }
    syncSchedule.conditions = JSON.stringify(conditions);
    return syncSchedule;
  }

  getCurrentFormatData(event: Moment, isBeginDate: boolean): string {
    let month;
    let date;
    if ((event.month() + 1) < 10) {
      month = '0' + (event.month() + 1);
    } else {
      month = '' + (event.month() + 1);
    }
    if (event.date() < 10) {
      date = '0' + event.date();
    } else {
      date = '' + event.date();
    }
    const dateStr = event.year() + '-' + month + '-' + date;
    if (isBeginDate) {
      // this.scheduleForm.get('dates.endDate').enable({onlySelf: true, emitEvent: true});
      this.beginDate = dateStr;
    } else {
      this.endDate = dateStr;
    }
    return dateStr;
  }

  // FORM VALIDATION CONTROLS SETUP
  addDateValidator() {
    // clear previous dates validator
    this.scheduleForm.removeControl('dates');
    // add dates validators
    const datesFormGroup: FormGroup = new FormGroup({}, (fg: FormGroup) => {
      return dateTimeValidator(fg, true);
    });
    const controlBeginDate: FormControl = new FormControl(this.beginDate, Validators.compose([Validators.required]));
    const controlEndDate: FormControl = new FormControl(this.endDate, Validators.compose([Validators.required]));
    datesFormGroup.addControl('beginDate', controlBeginDate);
    datesFormGroup.addControl('endDate', controlEndDate);
    this.scheduleForm.addControl('dates', datesFormGroup);

    // this.scheduleForm.get('dates.endDate').markAsTouched({onlySelf: true});
  }

  addDateTimeValidator() {
    // clear previous dates validator
    this.scheduleForm.removeControl('dates');
    // add dates validators
    const datesFormGroup: FormGroup = new FormGroup({}, (fg: FormGroup) => {
      return dateTimeValidator(fg, false);
    });
    const controlBeginDate: FormControl = new FormControl(this.beginDate, Validators.compose([Validators.required]));
    const controlEndDate: FormControl = new FormControl(this.endDate, Validators.compose([Validators.required]));
    datesFormGroup.addControl('beginDate', controlBeginDate);
    datesFormGroup.addControl('endDate', controlEndDate);
    datesFormGroup.addControl('fixed', this.getFixedValidationControls());
    this.scheduleForm.addControl('dates', datesFormGroup);

    // this.scheduleForm.get('dates.endDate').markAsTouched({onlySelf: true});
  }

  /**
   * Prepare validators for form "fixed" fields section, this fields are under "dates" formGroup because in "fixed" case the
   * date must be evaluated with time data.
   */
  getFixedValidationControls(): FormGroup {
    // clear previous fixed controls
    this.scheduleForm.removeControl('fixed');

    // Building fixed validators
    const fixedFormGroup: FormGroup = new FormGroup({});
    const controlFixedHS: FormControl = new FormControl(this.fixedStart[0], this.validatorsHourFields);
    const controlFixedMS: FormControl = new FormControl(this.fixedStart[1], this.validatorsMinuteFields);
    const controlFixedHE: FormControl = new FormControl(this.fixedEnd[0], this.validatorsHourFields);
    const controlFixedME: FormControl = new FormControl(this.fixedEnd[1], this.validatorsMinuteFields);
    fixedFormGroup.addControl('fixedHoursStart', controlFixedHS);
    fixedFormGroup.addControl('fixedMinutesStart', controlFixedMS);
    fixedFormGroup.addControl('fixedHoursEnd', controlFixedHE);
    fixedFormGroup.addControl('fixedMinutesEnd', controlFixedME);

    // this.scheduleForm.addControl('fixed', fixedFormGroup);
    return fixedFormGroup;
  }

  addMonthlyValidationControls() {
    this.scheduleForm.removeControl('monthly');

    const monthlyFormGroup: FormGroup = new FormGroup({});
    const controlMonthDay: FormControl = new FormControl(this.monthlyDay, Validators.compose([Validators.required]));
    monthlyFormGroup.addControl('monthlyMonthDay', controlMonthDay);

    this.scheduleForm.addControl('monthly', monthlyFormGroup);
  }

  removeMonthlyValidationControls() {
    this.scheduleForm.removeControl('monthly');
  }

  addYearlyValidationControls() {
    this.scheduleForm.removeControl('yearly');

    const monthlyFormGroup: FormGroup = new FormGroup({});
    const controlDay: FormControl = new FormControl(this.yearlyDay, Validators.compose([Validators.required]));
    const controlMonth: FormControl = new FormControl(this.yearlyMonth, Validators.compose([Validators.required]));
    monthlyFormGroup.addControl('yearlyDay', controlDay);
    monthlyFormGroup.addControl('yearlyMonth', controlMonth);

    this.scheduleForm.addControl('yearly', monthlyFormGroup);
  }

  removeYearlyValidationControls() {
    this.scheduleForm.removeControl('yearly');
  }

  /**
   * add Validators all the fields of every created slice
   */
  addSlicesValidationControls() {
    // clear previous slices
    this.scheduleForm.removeControl('slices');

    // Building dynamic created slices validation
    const slicesFormGroup: FormGroup = new FormGroup({});
    let sliceBlock: FormGroup;
    for (let i = 0; i < this.slices.length; i++) {
      sliceBlock = new FormGroup({}, (fg: FormGroup) => {
        return slicesTimeValidator(fg, i);
      });
      const controlHoursStart: FormControl = new FormControl(this.slices[i][0], this.validatorsHourFields);
      const controlMinutesStart: FormControl = new FormControl(this.slices[i][1], this.validatorsMinuteFields);
      const controlHoursEnd: FormControl = new FormControl(this.slices[i][2], this.validatorsHourFields);
      const controlMinutesEnd: FormControl = new FormControl(this.slices[i][3], this.validatorsMinuteFields);
      sliceBlock.addControl(i + 'HoursStart', controlHoursStart);
      sliceBlock.addControl(i + 'MinutesStart', controlMinutesStart);
      sliceBlock.addControl(i + 'HoursEnd', controlHoursEnd);
      sliceBlock.addControl(i + 'MinutesEnd', controlMinutesEnd);
      slicesFormGroup.addControl(i + 'SliceBlock', sliceBlock);
    }
    this.scheduleForm.addControl('slices', slicesFormGroup);
  }

  /**
   * remove slices validators
   */
  removeSlicesValidationControls() {
    this.scheduleForm.removeControl('slices');
  }

  /**
   * conditions validator
   */
  private addConditionValidator() {
    this.scheduleForm.removeControl('condition');
    const conditionsGroup: FormGroup = new FormGroup({});
    for (let i = 0; i < this.selectedConditions.length; i++) {
      const controlKey: FormControl = new FormControl(this.selectedConditions[i].key, this.keyFieldValidator);
      conditionsGroup.addControl('conditionKey' + i, controlKey);
      const controlValue: FormControl = new FormControl(this.selectedConditions[i].value, this.valueFieldValidator);
      conditionsGroup.addControl('conditionValue' + i, controlValue);
    }
    this.scheduleForm.addControl('condition', conditionsGroup);
  }

  isOptionDisabled(key: string): boolean {
    for (const fc of this.selectedConditions) {
      if (fc.key === key) {
        return true;
      }
    }
    return false;
  }

  private validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  conditionValueChange(condition: ConditionValue, value: any) {
    condition.value = value;
    let warning = true;
    for (const opt of condition.options) {
      if (opt === value) {
        warning = false;
      }
    }
    if (condition.exclusive) {
      condition.showWarning = warning;
    }
  }

  addCustomConditionValue(conditionValueSelect: MatSelect, formName: string, condition: ConditionValue, value: string): void {
    if (value !== '') {
      condition.value = value;
      let setted = false;
      for (const options of conditionValueSelect.options.toArray()) {
        if (options.value === condition.value) {
          setted = true;
          this.scheduleForm.get('condition').get(formName).setValue(options);
          conditionValueSelect.value = value;
        }
        if (!setted) {
          condition.showOptions = [...condition.options, value];
          this.scheduleForm.get('condition').get(formName).setValue(value);
          conditionValueSelect.value = value;
        }
        conditionValueSelect.close();
        this.conditionValueChange(condition, value);
      }
    }
  }

  conditionKeyChange(condition: ConditionValue, value: string) {
    condition.key = value;
    condition.value = null;
    condition.options = [];
    condition.showWarning = false;
    condition.exclusive = false;
    for (const c of this.conditions) {
      if (c.key === condition.key) {
        condition.options = c.values;
        condition.exclusive = c.exclusive;
      }
    }
    condition.showOptions = condition.options;
  }

  addCustomConditionKey(conditionKeySelect: MatSelect, formName: string, condition: ConditionValue, key: string) {
    if (key !== '' && !this.isOptionDisabled(key)) {
      condition.key = key;
      let setted = false;
      for (const options of conditionKeySelect.options.toArray()) {
        if (options.value === condition.key) {
          setted = true;
          this.scheduleForm.get('condition').get(formName).setValue(options);
          conditionKeySelect.value = key;
        }
      }
      if (!setted) {
        this.conditions.push({
          __typename: 'Condition',
          key: condition.key,
          values: [],
          exclusive: false,
          is_action: false
        });
        this.scheduleForm.get('condition').get(formName).setValue(key);
        conditionKeySelect.value = key;
      }
      conditionKeySelect.close();
      this.conditionKeyChange(condition, key);
    }
  }

}

/**
 * custom dateValidator (function defined below) is applied at 'dates' FormGroup, providing 'joint' validaton for both children (beginDate & endDate).
 * Angular default form error handler only check for FormControls errors (attributes of a FormGroup, a FormControl provide error check only for his form-field, doesn't evaluate
 * conditions that includes different form-fields), but not for parent FormGroup errors.
 * With this ErrorStateMatcher, attached directly to <input> field in html, we're saying to form element to check if his parent FormGroup has error.
 * FormControl validators will continue to work as always after 'parent errors check' provided from ErrorStateMatcher
 *
 * This class tell to form field to check 'parents' (FormGroup) validators, not only his FormControl validators, but the logic of parents validators and the binding of these
 * to the form validation component are handled elsewhere.
 *
 */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = control && control.invalid;
    const invalidParent = control && control.parent && control.parent.invalid;

    return (invalidCtrl || invalidParent) && !control.untouched;
  }
}

export class UntouchedErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = control && control.invalid && !control.untouched;

    return (invalidCtrl);
  }
}

/**
 * Custom Validator for dates fields (beginDate & endDate) and time fields (time only if [onlyDate] flag is set to false), throw error if beginDate is higher than endDate, if
 * times are available, they are evaluated with dates.
 *
 * DateHourValidator is used for both "fixed" and  "slices based"(daily, weekly...) scheduling frequencies.
 * In "slices based" scheduling ([onlyDate] = true) only the dates are evaluated, due to periodic format of this scheduling types, times aren't evaluated with the dates but
 * have splitted validation logic.
 * In "fixed" scheduling ([onlyDate] = false) begin date & time and end date & time work as an unique entity so they are evaluated together.
 *
 * This function do:
 * Provide correct date parsing (dates are saved in DB in 'string' format, while when they are modified from datepicker they become Moment instances).
 * After dates parsing, check between dates (and times) and throw error (dateInv) if validation isn't succesfull.
 */
export const dateTimeValidator = (control: AbstractControl, onlyDate: boolean): { [key: string]: boolean } => {
  let bd: string[] = [];
  let ed: string[] = [];

  // preparing data variables for validation check
  try {
    // translate begin date value in usable value;
    if (typeof control.value.beginDate === 'string') {
      bd = control.value.beginDate.split('-');
    } else {
      try {
        bd.push('' + control.value.beginDate.year());
        bd.push('' + (control.value.beginDate.month() + 1));
        bd.push('' + control.value.beginDate.date());
      } catch (e) {
        return {dateInv: true};
      }
    }

    // translate end date value in usable value;
    if (typeof control.value.endDate === 'string') {
      ed = control.value.endDate.split('-');
    } else {
      try {
        ed.push('' + control.value.endDate.year());
        ed.push('' + (control.value.endDate.month() + 1));
        ed.push('' + control.value.endDate.date());
      } catch (e) {
        return {dateInv: true};
      }
    }

  } catch (e) {
    return {dateInv: true};
  }

  // setting time values
  if (onlyDate) {
    bd.push('0');
    bd.push('0');
    ed.push('0');
    ed.push('0');
  } else {
    if (control.get('fixed') != null) {
      bd.push(control.get('fixed.fixedHoursStart').value.toString());
      bd.push(control.get('fixed.fixedMinutesStart').value.toString());
      ed.push(control.get('fixed.fixedHoursEnd').value.toString());
      ed.push(control.get('fixed.fixedMinutesEnd').value.toString());
    } else {
      return {dateInv: true};
    }
  }

  // Validation algorithm
  if (bd != undefined && ed != undefined) {
    const appDateB = new Date(Number(bd[0]), Number(bd[1]), Number(bd[2]), Number(bd[3]), Number(bd[4]));
    const appDateE = new Date(Number(ed[0]), Number(ed[1]), Number(ed[2]), Number(ed[3]), Number(ed[4]));
    if (appDateB.getTime() >= appDateE.getTime()) {
      return {dateInv: true};
    } else {
      return null;
    }
  } else {
    return null;
  }
};

/**
 * Custom validator used to validate start & end times specified for every slice
 */
export const slicesTimeValidator = (control: AbstractControl, index: number): { [key: string]: boolean } => {
  if (
    control !== null &&
    control.get(index + 'HoursStart') !== null &&
    control.get(index + 'MinutesStart') !== null &&
    control.get(index + 'HoursEnd') !== null &&
    control.get(index + 'MinutesEnd') !== null
  ) {
    try {
      const sh = Number(control.get(index + 'HoursStart').value);
      const sm = Number(control.get(index + 'MinutesStart').value);
      const eh = Number(control.get(index + 'HoursEnd').value);
      const em = Number(control.get(index + 'MinutesEnd').value);

      if (eh > sh) {
        return null;
      } else {
        if (eh === sh) {
          if (em > sm) {
            return null;
          } else {
            return {timeInv: true};
          }
        } else {
          return {timeInv: true};
        }
      }
    } catch (e) {
      return {timeInv: true};
    }
  } else {
    return {timeInv: true};
  }
};
