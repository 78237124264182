import {Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';

import {FuseMatchMediaService} from '@fuse/services/match-media.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FuseConfigService} from '@fuse/services/config.service';

import {navigation} from 'app/navigation/navigation';
import {Subscription} from 'rxjs/internal/Subscription';
import {MediaObserver} from '@angular/flex-layout';

@Component({
  selector: 'fuse-shortcuts',
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, OnDestroy {

  shortcutItems: any[] = [];
  navigationItems: any[];
  filteredNavigationItems: any[];
  searching = false;
  mobileShortcutsPanelActive = false;
  toolbarColor: string;
  matchMediaSubscription: Subscription;
  onConfigChanged: Subscription;

  @ViewChild('searchInput', {static: false}) searchInputField;
  @ViewChild('shortcuts', {static: false}) shortcutsEl: ElementRef;

  constructor(
    private renderer: Renderer2,
    private observableMedia: MediaObserver,
    private fuseMatchMedia: FuseMatchMediaService,
    private fuseNavigationService: FuseNavigationService,
    private fuseConfig: FuseConfigService,
    private cookieService: CookieService
  ) {
    this.filteredNavigationItems = this.navigationItems = this.fuseNavigationService.getFlatNavigation(navigation);

    this.onConfigChanged =
      this.fuseConfig.onConfigChanged
        .subscribe(
          (newSettings) => {
            this.toolbarColor = newSettings.colorClasses.toolbar;
          }
        );
  }

  ngOnInit() {

    this.matchMediaSubscription =
      this.fuseMatchMedia.onMediaChange.subscribe(() => {
        if (this.observableMedia.isActive('gt-sm')) {
          this.hideMobileShortcutsPanel();
        }
      });
  }

  ngOnDestroy() {
    this.matchMediaSubscription.unsubscribe();
  }

  search(event) {
    const value = event.target.value.toLowerCase();

    if (value === '') {
      this.searching = false;
      this.filteredNavigationItems = this.navigationItems;

      return;
    }

    this.searching = true;

    this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
      return navigationItem.title.toLowerCase().includes(value);
    });
  }

  onMenuOpen() {
    setTimeout(() => {
      this.searchInputField.nativeElement.focus();
    });
  }

  showMobileShortcutsPanel() {
    this.mobileShortcutsPanelActive = true;
    this.renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
  }

  hideMobileShortcutsPanel() {
    this.mobileShortcutsPanelActive = false;
    this.renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
  }
}
