import {AfterViewChecked, AfterViewInit, Component, DoCheck, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PlManager} from '../pl/pl-manager';
import {TimePipe} from '../../../../../shared/util/time.pipe';

@Component({
  selector: 'aesys-pl-graded',
  templateUrl: './pl-graded.component.html',
  styleUrls: ['./pl-graded.component.scss']
})
export class PlGradedComponent implements DoCheck {

  @Input() plManager: PlManager;
  list: [string, number][] = [];

  private width = 0;
  private scale = 0;

  private time = 0;
  private UPDATE_FR = 100;

  public currentscale = 4;

  constructor(
    private el: ElementRef,
    private timePipe: TimePipe
  ) {

  }

  getEstimatedWidth(text: string): number {
    if (text.length <= 5) {
      return 27;
    } else {
      return 36;
    }
  }

  setScale(value) {
    this.currentscale = value;
    let val = 1;
    switch (value) {
      case 1:
        val = 0.1;
        break;
      case 2:
        val = 0.4;
        break;
      case 3:
        val = 0.7;
        break;
      case 4:
        val = 1;
        break;
      case 5:
        val = 4;
        break;
      case 6:
        val = 7;
        break;
      case 7:
        val = 10;
        break;
      case 8:
        val = 13;
        break;
      case 9:
        val = 16;
        break;
      case 10:
        val = 24;
        break;
      case 11:
        val = 35;
        break;
      case 12:
        val = 40;
        break;
    }
    this.plManager.scale = val;
  }

  ngDoCheck(): void {
    if (Date.now() > this.time + this.UPDATE_FR) {
      this.time = Date.now();
      this.generateView();
    }
  }

  private generateView() {
    for (const pc of this.el.nativeElement.parentNode.parentNode.childNodes) {
      if (pc.id === 'playlist-container') {
        for (const ps of pc.childNodes) {
          if (ps.id === 'playlist-steps') {
            if (this.width !== ps.scrollWidth || this.scale !== this.plManager.scale) {
              this.width = ps.scrollWidth;

              const d = this.width < 10000 ? 60 : (this.width / 1200000) * 2000;
              const m = this.width < 10000 ? 4 : 1;

              this.scale = this.plManager.scale;

              const t = d / this.scale;
              this.list = [];
              for (let i = 0; i < ((this.width / d) - 1); i++) {
                this.list.push([i % m === 0 ? this.timePipe.transform(t * i) : '', d]);
              }
            }
          }
        }
      }
    }
  }

  public zoomOut(){
    if(this.currentscale > 1){
      this.currentscale --;
      this.setScale(this.currentscale);
    }
  }

  public zoomIn(){
    if(this.currentscale < 12){
      this.currentscale ++;
      this.setScale(this.currentscale);
    }
  }

}
