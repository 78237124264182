import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseSharedModule} from '@fuse/shared.module';

import {FuseToolbarComponent} from 'app/main/layout/layout-component/toolbar/toolbar.component';
import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components/index';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../../../shared/shared.module';
import { ToolbarService } from './toolbar.service';

@NgModule({
  declarations: [
    FuseToolbarComponent
  ],
  imports: [
    RouterModule,

    SharedModule,

    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    TranslateModule
  ],
  exports: [
    FuseToolbarComponent
  ],
  providers: [ToolbarService]
})
export class FuseToolbarModule {
}
