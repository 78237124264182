export class TokenManager {

  private REMEMBER = 'remember';

  private FIELD_ACCESS_TOKEN = 'pref_access_token';
  private FIELD_REFRESH_TOKEN = 'pref_refresh_token';
  private FIELD_TOKEN_TYPE = 'pref_token_type';
  private FIELD_EXPIRES_IN = 'pref_expires_in';
  private FIELD_EXPIRES_AT = 'pref_expires_at';

  constructor() {
  }

  public setToken(
    access_token: string,
    refresh_token: string,
    token_type: string,
    expires_in: number,
    expires_at: number
  ) {
    if (this.remember()) {
      localStorage.setItem(this.FIELD_ACCESS_TOKEN, access_token);
      localStorage.setItem(this.FIELD_REFRESH_TOKEN, refresh_token);
      localStorage.setItem(this.FIELD_TOKEN_TYPE, token_type);
      localStorage.setItem(this.FIELD_EXPIRES_IN, '' + expires_in);
      localStorage.setItem(this.FIELD_EXPIRES_AT, '' + expires_at);
      sessionStorage.removeItem(this.FIELD_ACCESS_TOKEN);
      sessionStorage.removeItem(this.FIELD_REFRESH_TOKEN);
      sessionStorage.removeItem(this.FIELD_TOKEN_TYPE);
      sessionStorage.removeItem(this.FIELD_EXPIRES_IN);
      sessionStorage.removeItem(this.FIELD_EXPIRES_AT);
    } else {
      localStorage.removeItem(this.FIELD_ACCESS_TOKEN);
      localStorage.removeItem(this.FIELD_REFRESH_TOKEN);
      localStorage.removeItem(this.FIELD_TOKEN_TYPE);
      localStorage.removeItem(this.FIELD_EXPIRES_IN);
      localStorage.removeItem(this.FIELD_EXPIRES_AT);
      sessionStorage.setItem(this.FIELD_ACCESS_TOKEN, access_token);
      sessionStorage.setItem(this.FIELD_REFRESH_TOKEN, refresh_token);
      sessionStorage.setItem(this.FIELD_TOKEN_TYPE, token_type);
      sessionStorage.setItem(this.FIELD_EXPIRES_IN, '' + expires_in);
      sessionStorage.setItem(this.FIELD_EXPIRES_AT, '' + expires_at);
    }
  }

  public hasToken(): boolean {
    return this.getItem(this.FIELD_ACCESS_TOKEN) != null;
  }

  public hasRefreshToken(): boolean {
    return this.getItem(this.FIELD_REFRESH_TOKEN) != null;
  }

  public isTokenExpired(): boolean {
    const expire: number = +this.getItem(this.FIELD_EXPIRES_AT);
    return (new Date().getTime()) > new Date(expire * 1000).getTime();
  }

  public exipireIn(milli: number): boolean {
    const expire: number = +this.getItem(this.FIELD_EXPIRES_AT);
    return (new Date().getTime() + milli) > new Date(expire * 1000).getTime();
  }

  public remember(): boolean {
    // return this.sessionStorage.getItem(this.REMEMBER) === 'true';
    return true;
  }

  public setRemember(remember: boolean) {
    sessionStorage.setItem(this.REMEMBER, '' + remember);
  }

  public getRefreshToken(): string {
    return this.getItem(this.FIELD_REFRESH_TOKEN);
  }

  public getToken(): string {
    return this.getItem(this.FIELD_ACCESS_TOKEN);
  }

  public isTokenValid(): boolean {
    return this.hasToken() && !this.isTokenExpired();
  }

  public clearToken() {
    sessionStorage.removeItem(this.FIELD_ACCESS_TOKEN);
    sessionStorage.removeItem(this.FIELD_REFRESH_TOKEN);
    sessionStorage.removeItem(this.FIELD_TOKEN_TYPE);
    sessionStorage.removeItem(this.FIELD_EXPIRES_IN);
    sessionStorage.removeItem(this.FIELD_EXPIRES_AT);
  }

  public deleteToken() {
    sessionStorage.removeItem(this.FIELD_ACCESS_TOKEN);
    sessionStorage.removeItem(this.FIELD_REFRESH_TOKEN);
    localStorage.removeItem(this.FIELD_ACCESS_TOKEN);
    localStorage.removeItem(this.FIELD_REFRESH_TOKEN);
  }

  private getItem(key: string): string {
    if (this.remember()) {
      return localStorage.getItem(key);
    } else {
      return sessionStorage.getItem(key);
    }
  }
}
