/**
 * Created by ciccio on 19/12/17.
 */
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {GraphService} from '../../main/network/graph.service';
import {Observable} from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private graph: GraphService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.graph.getTokenManager().isTokenValid()) {
      this.router.navigate(['login']);
      this.graph.saveDeeplinkUrl(state.url);
      return false;
    } else {
      return true;
    }
  }
}
