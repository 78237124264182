import {Component, OnInit} from '@angular/core';
import {GraphService} from '../../../network/graph.service';
import {TimelinesService} from '../timelines.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import ITimeline = AES.ITimeline;
import {DragulaService} from 'ng2-dragula';
import IPlanning = AES.IPlanning;
import * as _ from 'lodash';
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import {TlDialogPlanningComponent} from './tl-dialog-planning/tl-dialog-planning.component';
import SortDirection = AES.SortDirection;

@Component({
  selector: 'aesys-timelines-list',
  templateUrl: './timelines-list.component.html',
  styleUrls: ['./timelines-list.component.scss', '../../../../../../node_modules/dragula/dist/dragula.css']
})
export class TimelinesListComponent implements OnInit {

  loader: any = null;
  planningsList: IPlanning[];
  filteredList: IPlanning[];
  searchBoxEvent: any;

  constructor(
    private router: Router,
    private graphService: GraphService,
    private timelinesService: TimelinesService,
    private translateService: TranslateService,
    private dragulaService: DragulaService,
    public dialog: MatDialog,
    public errorBar: MatSnackBar
  ) {
    // dragula
    dragulaService.drop.subscribe((value) => {
      this.onDrop(value);
    });
  }

  ngOnInit() {
    this.init();
  }

  /**
   * on init() function plannings/timelines data are retrieved (plannings are "child" of channel)
   */
  init() {
    setTimeout(() => {
      if (this.loader == null) {
        this.loader = LoadingComponent.showLoading(this.dialog);
      }
      this.timelinesService.getChannels()
        .then(channels => {
          this.timelinesService.getChannel(channels[0].slug)
            .then(channel => {
              LoadingComponent.hideLoading(this.loader);
              this.setPlannings(channel.plannings);
              this.filteredList = _.clone(this.planningsList);
            })
            .catch(error => {
              LoadingComponent.hideLoading(this.loader);
              this.loader = null;
              this.errorBar.open(this.translateService.instant('TIMELINE_L.ERR_GET_TIMELINES'), '', {
                duration: 1000
              });
            });
        })
        .catch(error => {
          LoadingComponent.hideLoading(this.loader);
          this.loader = null;
          this.errorBar.open(this.translateService.instant('TIMELINE_L.ERR_GET_TIMELINES'), '', {
            duration: 1000
          });
        });
    }, 1);
  }

  openTimeline(timeline: ITimeline) {
    this.router.navigate(['/customer/' + this.graphService.getCurrentCustomer() + '/timelines/' + timeline.slug]);
  }

  newTimeline() {
    this.router.navigate(['/customer/' + this.graphService.getCurrentCustomer() + '/timelines/NEW']);
    this.planningsList = [];
    this.filteredList = [];
  }

  setPlannings(plannings: IPlanning[]) {
    this.planningsList = _.sortBy(plannings, ['order']);
  }

  /**
   * Triggered from search bar, the searched text (event.target.value) is used to filter timelines printed list.
   * @param event
   */
  searchTimelines(event: any) {
    this.searchBoxEvent = event;
    let filterValue = event.target.value;
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches

    this.filteredList = [];
    for (const planning of this.planningsList) {
      if (planning.timeline.name.trim().toLowerCase().indexOf(filterValue) != -1) {
        this.filteredList.push(planning);
      }
    }
  }

  hasCondition(planning: IPlanning): boolean {
    if (!planning || planning.conditions === null) {
      return false;
    } else {
      return JSON.parse(planning.conditions).length > 0;
    }
  }

  /**
   * check if search is active
   * @returns {boolean} - true:
   */
  searchActive() {
    return this.searchBoxEvent != null && this.searchBoxEvent.target.value != '';
  }


  /**
   * Knowing timelines slug it's possible retrieve his "father" planning (timelines & planning have a one to one relationship, one planning have only one timeline)
   * @param {string} tSlug - timeline slug
   * @returns {AES.IPlanning} - retrieved planning object
   */
  getPlanningFromTimelineSlug(tSlug: string): IPlanning {
    for (const planning of this.planningsList) {
      if (planning.timeline.slug == tSlug) {
        return planning;
      }
    }
    return null;
  }

  deleteTimeline(timeline: ITimeline) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        message: this.translateService.instant('TIMELINE_L.DELETE_MESSAGE'),
        positiveButton: this.translateService.instant('TIMELINE_L.DELETE_DIALOG_CONFIRM'),
        negativeButton: this.translateService.instant('TIMELINE_L.DELETE_DIALOG_UNDO')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loader = LoadingComponent.showLoading(this.dialog);
        this.timelinesService.deleteTimeline(timeline.slug)
          .then(res => {
            LoadingComponent.hideLoading(this.loader);
            this.init();
          })
          .catch(error => {
            LoadingComponent.hideLoading(this.loader);
            this.errorBar.open(this.translateService.instant('TIMELINE_L.ERR_DELETE_TIMELINE'), '', {
              duration: 1000
            });
          });
      }
    });
  }

  updatePlanningCondition(planning: IPlanning) {
    const dialogRef = this.dialog.open(TlDialogPlanningComponent, {
      width: '800px',
      data: {
        planning: planning
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const newPlanning: IPlanning = result.newPlanning;

        for (let i = 0; i < this.planningsList.length; i++) {
          if (this.planningsList[i].slug == newPlanning.slug) {
            this.planningsList[i] = newPlanning;
          }
        }
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i].slug == newPlanning.slug) {
            this.filteredList[i] = newPlanning;
          }
        }
      }
    });

  }

  /**
   * handle drop event for dragged item, {el} represents the dragged DOM element, while {ef} the element before (in list) the drop positions of {el}.
   *
   * When onDrop is Triggered from Dragula service, first are prepared correct [start item slug], [target item slug] and [direction] used for update mutation.
   * After data are ready the [updatePlanning] mutation is executed, on promise received, the current page datamodel is refreshed with the new ordered data provided
   * from mutation response.
   * @param args
   */
  private onDrop(args) {
    const [e, el, containerl, containerf, ef] = args;
    setTimeout(() => {
      // Preparing data for update
      let pft: IPlanning;
      pft = this.getPlanningFromTimelineSlug(el.id);
      const planningSlug = (pft == null) ? null : pft.slug;
      let targetSlug: string;
      let sortDirection: SortDirection;
      if (ef != null) {
        pft = this.getPlanningFromTimelineSlug(ef.id);
        targetSlug = (pft == null) ? null : pft.slug;
        sortDirection = SortDirection.BEFORE;
      } else {
        sortDirection = SortDirection.AFTER;
        targetSlug = null;
        if (this.planningsList.length > 0) {
          targetSlug = this.planningsList[this.planningsList.length - 1].slug;
        }
      }

      // Executing update and refresh datamodel on promise received
      if (planningSlug != null && targetSlug != null && sortDirection != null) {
        this.timelinesService.updatePlanningPosition(planningSlug, targetSlug, sortDirection)
          .then(channel => {
            this.setPlannings(channel.plannings);
            this.filteredList = Object.assign([], this.planningsList);
            if (this.searchBoxEvent != null && this.searchBoxEvent.target.value != '') {
              this.searchTimelines(this.searchBoxEvent);
            }
          })
          .catch(error => {
            this.errorBar.open(this.translateService.instant('TIMELINE_L.ERR_UPDATE_TIMELINE'), '', {
              duration: 1000
            });
          });
      }
    }, 1);
  }
}
