import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import ICondition = AES.ICondition;
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {noWhitespaceValidator} from '../tl-dialog-planning.component';
import {MatSelect} from '@angular/material';
import {ConditionValue} from '../../../../../network/data/condition-value.model';

@Component({
  selector: 'aesys-tl-planning',
  templateUrl: './tl-planning.component.html',
  styleUrls: ['./tl-planning.component.scss']
})
export class TlPlanningComponent implements AfterViewInit {

  @Output() deleted: EventEmitter<number> = new EventEmitter();
  @Input() conditionSet: [number, ConditionValue[]] = null;
  @Input() conditions: ICondition[] = null;

  // form
  conditionsForm: FormGroup;
  keyFieldValidator = Validators.compose([Validators.required]);
  valueFieldValidator = Validators.compose([Validators.required, noWhitespaceValidator]);

  // output
  flatConditions: ConditionValue[] = [];

  constructor(
    private formBuilder: FormBuilder
  ) {
    this.conditionsForm = this.formBuilder.group({
      condition: []
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    }, 1);
  }

  public init() {
    if (this.conditionSet[1] === null || this.conditionSet[1].length === 0) {
      this.addCondition();
    } else {
      for (const fc of this.conditionSet[1]) {
        const cond = {
          key: null,
          value: null,
          options: [],
          showOptions: [],
          showWarning: false,
          exclusive: false
        };
        this.flatConditions.push(cond);
        let conditionAdd = false;
        for (const condition of this.conditions) {
          if (condition.key === fc.key) {
            conditionAdd = true;
          }
        }
        if (!conditionAdd) {
          this.conditions.push({
            __typename: 'Condition',
            key: fc.key,
            values: [],
            exclusive: false,
            is_action: false
          });
        }
        this.conditionKeyChange(cond, fc.key);
        for (const condition of this.conditions) {
          if (condition.key === fc.key) {
            let valueAdd = false;
            for (const values of condition.values) {
              if (values === fc.value) {
                valueAdd = true;
              }
            }
            if (!valueAdd) {
              cond.showOptions = [...condition.values, fc.value];
            }
          }
        }
        this.conditionValueChange(cond, fc.value);
      }
      this.addConditionValidator();
    }
  }

  public addCondition() {
    this.flatConditions.push({
      key: null,
      value: null,
      options: [],
      showOptions: [],
      showWarning: false,
      exclusive: false
    });
    this.addConditionValidator();
  }

  public deleteCondition(position: number) {
    this.flatConditions.splice(position, 1);
    setTimeout(() => {
      this.addConditionValidator();
      if (this.flatConditions.length == 0) {
        this.deleted.emit(this.conditionSet[0]);
      }
    });
  }

  /**
   * Elimina tutta la conditionset specificata
   */
  deleteConditionset() {
    this.deleted.emit(this.conditionSet[0]);
  }

  public validateAndGetData(): ConditionValue[] {
    if (this.conditionsForm.valid) {
      return this.flatConditions;
    } else {
      this.validateAllFields(this.conditionsForm);
      return null;
    }
  }

  private validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  private addConditionValidator() {
    this.conditionsForm.removeControl('condition');
    const conditionsGroup: FormGroup = new FormGroup({});
    for (let i = 0; i < this.flatConditions.length; i++) {
      const controlKey: FormControl = new FormControl(this.flatConditions[i].key, this.keyFieldValidator);
      conditionsGroup.addControl('conditionKey' + i, controlKey);

      const controlValue: FormControl = new FormControl(this.flatConditions[i].value, this.valueFieldValidator);
      conditionsGroup.addControl('conditionValue' + i, controlValue);
    }
    this.conditionsForm.addControl('condition', conditionsGroup);
  }

  isOptionDisabled(key: string): boolean {
    for (const fc of this.flatConditions) {
      if (fc.key === key) {
        return true;
      }
    }
    return false;
  }

  conditionValueChange(condition: ConditionValue, value: any) {
    condition.value = value;
    let warning = true;
    for (const opt of condition.options) {
      if (opt === value) {
        warning = false;
      }
    }
    if (condition.exclusive) {
      condition.showWarning = warning;
    }
  }

  addCustomConditionValue(conditionValueSelect: MatSelect, formName: string, condition: ConditionValue, value: string): void {
    if (value !== '') {
      condition.value = value;
      let setted = false;
      for (const options of conditionValueSelect.options.toArray()) {
        if (options.value === condition.value) {
          setted = true;
          this.conditionsForm.get('condition').get(formName).setValue(options);
          conditionValueSelect.value = value;
        }
        if (!setted) {
          condition.showOptions = [...condition.options, value];
          this.conditionsForm.get('condition').get(formName).setValue(value);
          conditionValueSelect.value = value;
        }
        conditionValueSelect.close();
        this.conditionValueChange(condition, value);
      }
    }
  }

  conditionKeyChange(condition: ConditionValue, value: string) {
    condition.key = value;
    condition.value = null;
    condition.options = [];
    condition.showWarning = false;
    condition.exclusive = false;
    for (const c of this.conditions) {
      if (c.key === condition.key) {
        condition.options = c.values;
        condition.exclusive = c.exclusive;
      }
    }
    condition.showOptions = condition.options;
  }

  addCustomConditionKey(conditionKeySelect: MatSelect, formName: string, condition: ConditionValue, key: string) {
    if (key !== '' && !this.isOptionDisabled(key)) {
      condition.key = key;
      let setted = false;
      for (const options of conditionKeySelect.options.toArray()) {
        if (options.value === condition.key) {
          setted = true;
          this.conditionsForm.get('condition').get(formName).setValue(options);
          conditionKeySelect.value = key;
        }
      }
      if (!setted) {
        this.conditions.push({
          __typename: 'Condition',
          key: condition.key,
          values: [],
          exclusive: false,
          is_action: false
        });
        this.conditionsForm.get('condition').get(formName).setValue(key);
        conditionKeySelect.value = key;
      }
      conditionKeySelect.close();
      this.conditionKeyChange(condition, key);
    }
  }
}
