import {Component, Inject, OnInit} from '@angular/core';
import {GenericDialogComponent} from '../../../../../shared/generic-dialog/generic-dialog.component';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Validators, FormControl} from '@angular/forms';
import {environment} from '../../../../../../environments/environment';

interface Resolution {
  name: string;
  value: string;
}

@Component({
  selector: 'aesys-pl-preview-dialog',
  templateUrl: './pl-preview-dialog.component.html',
  styleUrls: ['./pl-preview-dialog.component.scss']
})
export class PlPreviewDialogComponent implements OnInit {
  resolutionControl = new FormControl('', [Validators.required]);
  resolutions: Resolution[];
  selRes: Resolution;
  basePath = environment.infotainmentITPlayerPath + '/#/player?';

  constructor(public dialogRef: MatDialogRef<GenericDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.resolutions = [
      {
        name: 'Landscape HD',
        value: '1366:768'
      },
      /* {
         name: 'Landscape Full HD stretched',
         value: '1920:720'
       },
       {
         name: 'Landscape Full HD',
         value: '1920:1080'
       },*/
      {
        name: 'Portrait HD',
        value: '768:1366'
      },
      /* {
         name: 'Portrait Full HD stretched',
         value: '720:1920'
       },
       {
         name: 'Portrait Full HD',
         value: '1080:1920'
       }*/
    ];
  }

  openNewPage() {
    if (this.resolutionControl.valid) {
      const res = this.selRes.value.split(':');
      const myWindow = window.open(this.basePath + 'customer=' + this.data.cs + '&playlist=' + this.data.ps, '_blank', 'width=' + res[0] + ', height=' + res[1]);
      myWindow.focus();
      this.dialogRef.close(true);
    } else {
      this.resolutionControl.markAsTouched({onlySelf: true});
    }


  }

}
