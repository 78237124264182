import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'byteSize'
})
export class ByteSizePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return this.assetSizeScaleUnit(value);
  }

  public assetSizeScaleUnit(size: number): string {
    const unitList = ['B', 'KB', 'MB', 'GB', 'TB'];
    if (size == 0) {
      return '-';
    }
    for (let i = 0; i < unitList.length; i++) {
      if (size < 1000) {
        if (i === 3) {
          return size.toFixed(1) + ' ' + unitList[i];
        } else {
          return size.toFixed(0) + ' ' + unitList[i];
        }
      }
      else {
        size = size / 1024;
      }
    }
    return size.toFixed(0) + ' ' + unitList[unitList.length - 1];
  }


}
