import {NgModule} from '@angular/core';
import {ClickStopPropagationDirective} from './util/click-stop-propagation.directive';
import {
  MatAutocompleteModule,
  MatButtonModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule,
  MatDialogModule, MatDividerModule, MatExpansionModule,
  MatFormFieldModule, MatIconModule, MatIconRegistry, MatInputModule, MatListModule,
  MatMenuModule,
  MatNativeDateModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule, MatRippleModule,
  MatSelectModule, MatSidenavModule, MatSliderModule, MatSnackBarModule,
  MatSortModule,
  MatTableModule, MatTabsModule,
  MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FuseSharedModule} from '../../@fuse/shared.module';
import {LoadingComponent} from './loading/loading.component';
import {GenericDialogComponent} from './generic-dialog/generic-dialog.component';
import {AuthGuard} from './guard/auth-guard';
import {TimePipe} from './util/time.pipe';
import {BackgroundColorPipe} from './util/background-color.pipe';
import {DatePipe} from '@angular/common';
import {FormatPipe} from './util/format.pipe';
import {ByteSizePipe} from './util/byte-size.pipe';
import {DomSanitizer} from '@angular/platform-browser';
import {EnumComparePipe} from './util/enum-compare.pipe';
import {TranslateConditionalPipe} from './util/translate-conditional.pipe';

const materialModule = [
  FormsModule,
  MatCardModule,
  MatRadioModule,
  MatTabsModule,
  MatButtonModule,
  MatSliderModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatToolbarModule,
  MatTableModule,
  MatNativeDateModule,
  ReactiveFormsModule,
  MatProgressBarModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatDividerModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatListModule,
  MatChipsModule,
  MatAutocompleteModule
];

const ieModule = [
  ...materialModule,
  TranslateModule,
  FuseSharedModule
];

const entryComponent = [
  LoadingComponent,
  GenericDialogComponent
];

const pipe = [
  TimePipe,
  FormatPipe,
  ByteSizePipe,
  EnumComparePipe,
  TranslateConditionalPipe
];

const ieDeclaration = [
  ...entryComponent,
  ...pipe,
  ClickStopPropagationDirective,
  BackgroundColorPipe
];

@NgModule({
  imports: [
    ieModule,
  ],
  declarations: [
    ieDeclaration,
  ],
  providers: [
    AuthGuard,
    BackgroundColorPipe,
    DatePipe,
    ...pipe
  ],
  entryComponents: [
    entryComponent
  ],
  exports: [
    ...ieModule,
    ...ieDeclaration
  ]
})
export class SharedModule {

  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/custom_icon.svg'));
  }
}
