import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatTableDataSource} from '@angular/material';
import {LayoutManagerService} from '../layout-manager.service';
import ILayout = AES.ILayout;
import {LayoutManagerDetailComponent} from '../layout-manager-detail/layout-manager-detail.component';
import {LoadingComponent} from '../../../../shared/loading/loading.component';
import {GenericDialogComponent} from '../../../../shared/generic-dialog/generic-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'aesys-layout-manager-list',
  templateUrl: './layout-manager-list.component.html',
  styleUrls: ['./layout-manager-list.component.scss']
})
export class LayoutManagerListComponent implements OnInit {

  dataSource: MatTableDataSource<ILayout> = null;
  displayedColumns = ['name', 'type', 'surface', 'io', 'delete'];
  layouts: ILayout[] = [];

  constructor(
    private layoutService: LayoutManagerService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<LayoutManagerListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.layoutService.getLayouts()
      .then(layouts => {
        this.layouts = layouts;
        this.dataSource = new MatTableDataSource(this.layouts);
      })
      .catch(error => {
        this.dialogRef.close();
      });
  }

  deleteLayout(layout: ILayout) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '300px',
      data: {
        message: this.translateService.instant('LAYOUT_L.DELETE_LAYOUT.TITLE'),
        positiveButton: this.translateService.instant('LAYOUT_L.DELETE_LAYOUT.POSITIVE'),
        negativeButton: this.translateService.instant('LAYOUT_L.DELETE_LAYOUT.NEGATIVE')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        LoadingComponent.showLoading(this.dialog);
        this.layoutService.deleteLayout(layout.slug)
          .then(result => {
            LoadingComponent.hideStaticLoading();
            if (result) {
              const newLayouts: ILayout[] = [];
              for (const l of this.layouts) {
                if (l.slug !== layout.slug) {
                  newLayouts.push(l);
                }
              }
              this.layouts = newLayouts;
              this.dataSource = new MatTableDataSource(this.layouts);
              this.snackBar.open(this.translateService.instant('LAYOUT_L.DELETE_LAYOUT.SUCCESS'), '', {
                duration: 1000,
              });
            } else {
              this.snackBar.open(this.translateService.instant('LAYOUT_L.DELETE_LAYOUT.ERROR'), '', {
                duration: 1000,
              });
            }
          })
          .catch(error => {
            LoadingComponent.hideStaticLoading();
            this.snackBar.open(this.translateService.instant('LAYOUT_L.DELETE_LAYOUT.ERROR'), '', {
              duration: 1000,
            });
          });
      }
    });
  }

  openLayout(layout: ILayout) {
    LoadingComponent.showLoading(this.dialog);
    this.layoutService.getLayout(layout.slug)
      .then(layout => {
        LoadingComponent.hideStaticLoading();
        this.openLayoutEdit(layout);
      })
      .catch(error => {
        LoadingComponent.hideStaticLoading();
      });
  }

  openLayoutEdit(layout?: ILayout) {
    const ref = this.dialog.open(LayoutManagerDetailComponent, {
      width: '800px',
      maxHeight: '600px',
      data: {
        layout: layout
      }
    });
    ref.afterClosed().subscribe(layout => {
      if (layout) {
        let added = false;
        for (let i = 0; i < this.layouts.length; i++) {
          if (this.layouts[i].slug === layout.slug) {
            this.layouts[i] = layout;
            added = true;
          }
        }
        if (!added) {
          this.layouts.push(layout);
        }
        this.dataSource = new MatTableDataSource(this.layouts);
      }
    });
  }
}
