import {AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, OnInit, Output} from '@angular/core';
import {PlManager} from '../pl-manager';
import {PlContentModel} from '../model/pl-content.model';
import {MatDialog} from '@angular/material';
import {GenericDialogComponent} from '../../../../../../shared/generic-dialog/generic-dialog.component';
import {LoadingComponent} from '../../../../../../shared/loading/loading.component';
import {PlaylistsService} from '../../../playlists.service';
import {PlDialogDurationComponent} from './pl-dialog-duration/pl-dialog-duration.component';
import {TimePipe} from '../../../../../../shared/util/time.pipe';
import IAsset = AES.IAsset;
import PlaylistType = AES.PlaylistType;
import ContentType = AES.ContentType;
import {ContentTypeEnum} from '../../../../../network/interface/graph-enum.model';

@Component({
  selector: 'aesys-pl-content',
  templateUrl: './pl-content.component.html',
  styleUrls: ['./pl-content.component.css']
})
export class PlContentComponent implements OnInit, AfterViewInit {

  @Output() deleteContent = new EventEmitter<PlContentModel>();
  @Output() updatedContent = new EventEmitter<PlContentModel>();

  @HostBinding('style.order') public order = 0;
  @HostBinding('style.position') public position = 'static';
  @HostBinding('style.zIndex') public zIndex = '100';
  @HostBinding('style.left') private leftPos = '0px';
  @HostBinding('style.top') private topPos = '0px';

  ct = ContentTypeEnum;
  loader: any;

  content: PlContentModel;
  contentType: ContentType;
  plManager: PlManager;
  resizing = false;
  assetPreview: IAsset;

  showName = true;

  constructor(
    private plService: PlaylistsService,
    private elRef: ElementRef,
    private timePipe: TimePipe,
    public dialog: MatDialog
  ) {
  }

  ngOnInit() {
    this.showName = this.plManager.getType() === PlaylistType.TFT;
    if (this.content.content.type === ContentType.PICTOGRAM && this.content.content.assets) {
      this.contentType = this.content.content.type;
      for (const asset of this.content.content.assets) {
        if (asset.is_ready && asset.path) {
          this.assetPreview = asset;
        }
      }
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.order = this.content.order;
    }, 1);
  }

  getIcon(): string {
    switch (this.content.content.type) {
      case ContentType.ARCHIVE:
        return 'newarchive';
      case ContentType.IMAGE:
        return 'photo';
      case ContentType.VIDEO:
        return 'local_movies';
      case ContentType.URL:
        return 'linknew';
      case ContentType.PICTOGRAM:
        return 'pictogram';

    }
  }

  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '250px',
      data: {
        message: 'Eliminare?',
        positiveButton: 'SI',
        negativeButton: 'ANNULLA'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteContent.emit(this.content);
      }
    });
  }

  changeDuration() {
    const dialogRef = this.dialog.open(PlDialogDurationComponent, {
      width: '250px',
      data: {
        time: this.content.duration
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.duration) {
        this.loader = LoadingComponent.showLoading(this.dialog);
        this.plService.updateStepItemDuration(this.content.stepItem, result.duration)
          .then(stepitem => {
            this.content.duration = stepitem.duration;
            LoadingComponent.hideLoading(this.loader);
            this.updatedContent.emit(this.content);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });
  }

  getDuration(): string {
    return this.timePipe.transform(this.content.duration);
  }

  canChangeDuration(): boolean {
    return this.content.content.type !== ContentType.VIDEO;
  }

  setFixedPos(parentMoved: boolean) {
    if (parentMoved) {
      this.leftPos = (this.elRef.nativeElement.getBoundingClientRect().left - this.elRef.nativeElement.parentNode.getBoundingClientRect().left) + 'px';
      this.topPos = (this.elRef.nativeElement.getBoundingClientRect().top - this.elRef.nativeElement.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect().top) + 'px';
    } else {
      this.leftPos = this.elRef.nativeElement.getBoundingClientRect().left + 'px';
      this.topPos = this.elRef.nativeElement.getBoundingClientRect().top + 'px';
    }
    this.zIndex = '101';
    this.position = 'fixed';
  }

  setFixedListPos(rect: any) {
    this.leftPos = rect.x + 'px';
    this.topPos = rect.y + 'px';
    this.zIndex = '101';
    this.position = 'fixed';
  }

  setStaticPos() {
    this.position = 'static';
    this.zIndex = '100';
    this.elRef.nativeElement.style.width = 'auto';
  }

  setWidth() {
    this.elRef.nativeElement.style.width = this.plManager.getType() === PlaylistType.TFT ? '100%' : '33%';
  }

}
