import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {onError} from 'apollo-link-error';
import {QueryModel} from './graphql-model/query.model';
import {MutationModel} from './graphql-model/mutation.model';
import {environment} from '../../../environments/environment';
import {TokenManager} from './manager/token-manager.manager';
import {HttpLink} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {setContext} from 'apollo-link-context';
import {HttpHeaders} from '@angular/common/http';
import {ApolloBase} from 'apollo-angular/Apollo';

import {Router} from '@angular/router';
import {isUndefined} from 'util';
import {LoadingComponent} from '../../shared/loading/loading.component';
import {Subject, interval} from 'rxjs';
import TimeoutLink from 'apollo-link-timeout';


@Injectable()
export class GraphService {

  private query: QueryModel = new QueryModel();
  private mutation: MutationModel = new MutationModel();

  private REFRESH_TOKEN_INTERVAL = 28 * 60 * 1000;
  private refreshTokenTimeoutCreated = false;

  private tokenManager: TokenManager = null;

  private me: AES.IMe;
  private apolloName: string;
  private currentCustomer = 'undefined';

  private graphqlUrl = environment.graphql;
  public currentCustomerObserver = new Subject();

  constructor(
    private httpLink: HttpLink,
    private apollo: Apollo,
    private router: Router) {
  }

  public init() {
    let link = null;
    const env = sessionStorage.getItem('Environment');
    if (env !== null && !isUndefined(env) && env !== '') {
      const p = '://';
      const split = environment.graphql.split(p);
      this.graphqlUrl = split[0] + p + env + split[1];
    } else {
      this.graphqlUrl = environment.graphql;
    }

    const http = (new TimeoutLink(6000)).concat(this.httpLink.create({uri: this.graphqlUrl}));
    const error = onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          if (!isUndefined((error as any).code) && (error as any).code === 401) {
            LoadingComponent.hideStaticLoading();
            sessionStorage.clear();
            localStorage.clear();
            this.saveDeeplinkUrl(this.router.url);
            // this.graph.saveDeeplinkUrl(state.url);
            this.router.navigate(['/login']);
          }
        }
      }
      if (networkError) {
        if ((networkError as any).status === 503) {
          LoadingComponent.hideStaticLoading();
          this.router.navigate(['/maintenance']);
        }
      }
    });

    if (this.getTokenManager().isTokenValid()) {
      const auth = setContext((_, {headers}) => {
        if (headers === undefined) {
          headers = new HttpHeaders();
        }
        return {
          headers: headers.append('Authorization', 'Bearer ' + this.getTokenManager().getToken())
        };
      });

      link = error.concat(auth.concat(http));
    }
    if (link === null) {
      link = error.concat(http);
    }
    this.createNewApolloConnection(link);
  }

  public getGraphqlUrl(): string {
    return this.graphqlUrl;
  }

  private createNewApolloConnection(link: any) {
    try {
      this.apolloName = '' + new Date().getTime();
      this.apollo.createNamed(this.apolloName, {
        link: link,
        queryDeduplication: false,
        cache: new InMemoryCache(),
        defaultOptions: {
          watchQuery: {
            errorPolicy: 'all'
          }
        }
      });
      this.apollo.use(this.apolloName);
      this.refreshTokenCron();
    } catch (e) {
    }
  }

  private refreshTokenCron() {
    if (!this.refreshTokenTimeoutCreated) {
      this.refreshTokenTimeoutCreated = true;
      this.refresh();
      interval(this.REFRESH_TOKEN_INTERVAL).subscribe((x) => {
        this.refresh();
      });
    }
  }

  private refresh() {
    if (this.getTokenManager().exipireIn(this.REFRESH_TOKEN_INTERVAL)) {
      this.renewToken()
        .then(() => {
        })
        .catch(error => {
        });
    }
  }

  public getApollo(): ApolloBase<any> {
    return this.apollo.use(this.apolloName);
  }

  public getTokenManager() {
    if (this.tokenManager == null) {
      this.tokenManager = new TokenManager();
    }
    return this.tokenManager;
  }

  public getCurrentCustomer() {
    return this.currentCustomer;
  }

  public setCurrentCustomer(currentCustomer: string) {
    this.currentCustomer = currentCustomer;
    this.currentCustomerObserver.next(currentCustomer);
  }

  public renewToken(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (this.getTokenManager().isTokenValid()) {
        this.getApollo().mutate<any>({
          mutation: this.mutation.refreshToken,
          context: {
            uri: this.getGraphqlUrl() + '?GraphService.renewToken'
          },
          variables: {
            rti: {
              refresh_token: this.getTokenManager().getRefreshToken(),
              redirect_url: environment.infotainmentLoginRedirectPath
            }
          }
        }).subscribe(
          response => {
            const token = response.data.refreshToken;
            this.getTokenManager().setToken(
              token.access_token,
              token.refresh_token,
              token.token_type,
              token.expires_in,
              token.expires_at
            );
            this.init();
            resolve(true);
          }, error => {
            reject();
          });
      } else {
        reject();
      }
    });
  }

  public logout(router?: Router) {
    sessionStorage.clear();
    localStorage.clear();
    if (!isUndefined(router) && router !== null) {
      window.location.href = environment.aesysBasePath + '/Account/Logout';
    }
  }

  public saveDeeplinkUrl(deeplinkUrl: string) {
    sessionStorage.setItem('deeplinkUrl', deeplinkUrl);
  }

  public getDeeplinkUrl(): string {
    return sessionStorage.getItem('deeplinkUrl');
  }

  public removeDeeplinkUrl() {
    sessionStorage.removeItem('deeplinkUrl');
  }
}
