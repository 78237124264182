import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {NotificationPollingService} from './notification-polling.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    public notificationPollingService: NotificationPollingService
  ) {}

  pollingRequest = ['ToolbarService.getNotification', 'GraphService.renewToken'];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const splitReq = req.url.split('?');
    if (this.pollingRequest.indexOf(splitReq[splitReq.length - 1]) != -1) {
    } else {
      this.notificationPollingService.resetPolling();
    }

    return next.handle(req);
  }
}
