export class FormError {

  error: [string, string[]][] = [];

  constructor(er?: any, formName?: string) {
    if (er) {
      for (const e of er.graphQLErrors) {
        for (const key in e.validation) {
          const field = key.replace(formName + '.', '');
          this.error.push([field, []]);
          for (const detail of e.validation[key]) {
            this.error[this.error.length - 1][1].push(detail);
          }
        }
      }
    }
  }
}
