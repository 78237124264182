import IStepitem = AES.IStepitem;
import IContent = AES.IContent;
import {PlManager} from '../pl-manager';

export class PlContentModel {

  static SHADOW_CONTENT_WIDTH = 40;
  static DEFAULT_DURATION = 30;
  static MIN_DURATION = 3;

  stepItem: IStepitem = null;
  id: string;
  order: number;
  duration: number;
  surfaceId: string = null;
  style = '';
  cRef: any;

  constructor(public content: IContent) {
    this.id = '_' + Math.random().toString(36).substr(2, 9);
    this.duration = content.duration ? content.duration : PlContentModel.DEFAULT_DURATION;
    this.style = 'pl-content-list';
  }

  getWidth(plManager: PlManager): string {
    if (this.style === 'pl-content-list') {
      return '100%';
    } else if (this.style === 'pl-content-shadow') {
      return PlContentModel.SHADOW_CONTENT_WIDTH + 'px';
    } else {
      return (this.duration * plManager.scale) + 'px';
    }
  }

}
