import {Component, OnInit} from '@angular/core';
import {FuseConfigService} from '../../../../../@fuse/services/config.service';
import {MatSnackBar} from '@angular/material';
import {GraphService} from '../../../network/graph.service';
import {Router} from '@angular/router';

@Component({
  selector: 'aesys-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})

export class SettingComponent implements OnInit {

  private psw = 'Admin';
  environment = '';
  secure = '';

  constructor(
    private fuseConfig: FuseConfigService,
    private graphService: GraphService,
    private router: Router,
    public snackBar: MatSnackBar
  ) {
    this.fuseConfig.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });
    this.environment = sessionStorage.getItem('Environment');
  }

  ngOnInit() {
  }

  saveSetting() {
    if (this.secure === this.psw) {
      this.graphService.logout(this.router);
      sessionStorage.setItem('Environment', this.environment);
      this.graphService.init();
    } else {
      this.snackBar.open('INCORRECT PASSWORD', '', {
        duration: 1000,
      });
    }
  }

}
