import {
  AfterViewInit, Component, ComponentFactoryResolver, DoCheck, ElementRef, EventEmitter, HostBinding, HostListener, OnInit, Output, QueryList, ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {PlSurfaceComponent} from '../pl-surface/pl-surface.component';
import {PlStepModel} from '../model/pl-step.model';
import {PlSurfaceModel} from '../model/pl-surface.model';
import * as _ from 'lodash';
import {PlManager} from '../pl-manager';
import {PlContentModel} from '../model/pl-content.model';
import {isUndefined} from 'util';
import {IoModel} from '../../../../../network/data/io.model';
import LayoutType = AES.LayoutType;
import {LayoutTypeEnum} from '../../../../../network/interface/graph-enum.model';
import {PlEditOutputDialogComponent} from './pl-edit-output-dialog/pl-edit-output-dialog.component';
import {MatDialog} from '@angular/material';
import {StepIoModel} from '../../../../../network/data/step-io.model';
import {LoadingComponent} from '../../../../../../shared/loading/loading.component';
import {PlaylistsService} from '../../../playlists.service';
import {GenericDialogComponent} from '../../../../../../shared/generic-dialog/generic-dialog.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'aesys-pl-step',
  templateUrl: './pl-step.component.html',
  styleUrls: ['./pl-step.component.css']
})
export class PlStepComponent implements OnInit, AfterViewInit, DoCheck {

  @Output() delete = new EventEmitter<PlStepModel>();
  @Output() deleteContent = new EventEmitter<PlContentModel>();
  @Output() updatedContent = new EventEmitter<PlContentModel>();
  @Output() add = new EventEmitter<PlStepModel>();
  @Output() changeTransition = new EventEmitter<PlStepModel>();
  @Output() ready = new EventEmitter();

  @ViewChildren('surfaceInsert', {read: ViewContainerRef}) surfaceInsert: QueryList<ViewContainerRef>;
  @ViewChildren('layoutContainer', {read: ViewContainerRef}) layoutContainer;
  @ViewChildren('outputView', {read: ViewContainerRef}) outputView;
  @HostBinding('style.order') public order = 0;
  @HostBinding('style.position') public position = 'static';
  @HostBinding('style.zIndex') public zIndex = '98';
  @HostBinding('style.left') private leftPos = '0px';

  layoutType = LayoutTypeEnum;
  lt: LayoutType;
  step: PlStepModel;
  plManager: PlManager;
  showButton = true;
  allIoKey: string[];
  outputs: StepIoModel[];
  outputMarginTop = 0;

  private layoutOutputs: IoModel[];
  private surfaceStyle = [
    'pl-surface-1',
    'pl-surface-2',
    'pl-surface-3',
    'pl-surface-4',
    'pl-surface-5',
  ];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private elRef: ElementRef,
    private playlistService: PlaylistsService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.layoutOutputs = IoModel.parse(this.step.step.layout.io);
    this.outputs = StepIoModel.parse(this.step.step.io);
    let ioEdited = false;
    for (const lo of this.layoutOutputs) {
      let keyFound = false;
      for (const io of this.outputs) {
        if (lo.key === io.key) {
          keyFound = true;
          if (io.value && lo.values.indexOf(io.value) === -1) {
            lo.values.push(io.value);
          }
          if (!io.value) {
            ioEdited = true;
            io.value = lo.values[0];
          }
        }
      }
      if (!keyFound) {
        ioEdited = true;
        this.outputs.push({
          key: lo.key,
          value: lo.values[0]
        });
      }
    }
    if (ioEdited) {
      this.saveIO(this.outputs);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init();
    }, 1);
  }

  private init() {
    this.addSurfaces();
    this.ready.emit();
  }

  private addSurfaces() {
    const surfaces = _.sortBy(this.step.step.layout.surfaces, ['order']);
    this.lt = this.step.step.layout.type;
    for (let i = 0; i < surfaces.length; i++) {
      const plSurface = this.componentFactoryResolver.resolveComponentFactory(PlSurfaceComponent);
      const cRef = this.surfaceInsert.first.createComponent(plSurface);
      const dComponent = <PlSurfaceComponent>cRef.instance;

      const surface: PlSurfaceModel = new PlSurfaceModel();
      surface.surface = surfaces[i];
      surface.style = this.surfaceStyle[i];
      surface.cRef = cRef;
      dComponent.plManager = this.plManager;
      dComponent.surface = surface;
      dComponent.step = this.step;
      dComponent.stepItems = _(this.step.step.stepitems).filter(si => si.surface.slug === surface.surface.slug).value();

      dComponent.deleteContent.subscribe(plContent => {
        this.deleteContent.emit(plContent);
      });

      dComponent.updatedContent.subscribe(plContent => {
        this.updatedContent.emit(plContent);
      });
      this.step.surfaces.push(surface);
    }
  }

  setFixedPos() {
    this.position = 'fixed';
    this.zIndex = '99';
    this.leftPos = this.elRef.nativeElement.getBoundingClientRect().left + 'px';
  }

  setStaticPos() {
    this.position = 'static';
    this.zIndex = '98';
  }

  ngDoCheck(): void {
    if (!isUndefined(this.layoutContainer)) {
      this.showButton = this.layoutContainer.first.element.nativeElement.clientWidth > 40;
    }
  }

  editOutput(key: string): void {
    let list: IoModel;
    let output: StepIoModel;
    for (const lo of this.layoutOutputs) {
      if (lo.key === key) {
        list = lo;
      }
    }
    for (const lo of this.outputs) {
      if (lo.key === key) {
        output = lo;
      }
    }
    if (list) {
      const dialogRef = this.dialog.open(PlEditOutputDialogComponent, {
        width: '300px',
        data: {
          currentOutput: output,
          output: list
        }
      });
      dialogRef.afterClosed().subscribe(value => {
        if (value) {
          const tmpOutputs = _.clone(this.outputs);
          for (const o of tmpOutputs) {
            if (o.key === key) {
              o.value = value;
            }
          }
          this.saveIO(tmpOutputs);
        }
      });
    } else {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        width: '250px',
        data: {
          message: this.translateService.instant('PLAYLIST_D.IO_CONDITIONS.DELETE_DIAL.CONTENT'),
          positiveButton: this.translateService.instant('PLAYLIST_D.IO_CONDITIONS.DELETE_DIAL.POSITIVE'),
          negativeButton: this.translateService.instant('PLAYLIST_D.IO_CONDITIONS.DELETE_DIAL.NEGATIVE')
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const tmpOutputs = [];
          for (const o of this.outputs) {
            if (o.key !== key) {
              tmpOutputs.push(o);
            }
          }
          this.saveIO(tmpOutputs);
        }
      });
    }
  }

  public updateOutputView(totalSurface: number, allIoKey: string[]) {
    this.outputMarginTop = 100 * (totalSurface - this.step.step.layout.surfaces.length);
    this.allIoKey = allIoKey;
  }

  private saveIO(newOutputs: StepIoModel[]) {
    const io = StepIoModel.stringify(newOutputs);
    LoadingComponent.showLoading(this.dialog);
    this.playlistService.updateStepIO(this.step.step.slug, io)
      .then(success => {
        LoadingComponent.hideStaticLoading();
        this.step.step.io = io;
        this.outputs = newOutputs;
      })
      .catch(error => {
        LoadingComponent.hideStaticLoading();
        console.log('error');
      });
  }

}
